import React, { Component } from 'react';
import { Table, Divider, Tag, Icon, Button, Card, Row, Col, Modal, Input } from 'antd';

export default class ModalInput extends Component {

    constructor(props) {
        super(props);


        this.state = {

        };

    }


    render() {
        const { loading, inputModalVisible, inputModal, successModal, modalcountDown, message, messageSuccess, setStateByProps, placeholder, inputValue, value } = this.props;
        //const { cancelModalVisible } = this.state;
        const inputStyle = { backgroundColor: 'transparent', border: 'none', borderBottom: '1px solid #9e9e9e', boxShadow: 'none' };
        return (
            <div>
                <style>{`
              

                
                 `}</style>

                <Modal
                    zIndex={1021}
                    wrapClassName="popup-question"
                    closeIcon={false}
                    visible={inputModalVisible}
                    onOk={() => inputModal(true, message, messageSuccess)}
                    onCancel={() => inputModal(false, message, messageSuccess)}
                    width={450}
                    footer={[
                        <Button key="submit" type="gray" loading={loading} onClick={() => inputModal(false, message, messageSuccess)}>
                            CANCEL
                        </Button>,
                        <Button key="submit" type="primary" loading={loading} disabled={value ? false : true} onClick={() => { inputModal(false, message, messageSuccess); successModal(true); modalcountDown(); }}>
                            SAVE
                        </Button>
                    ]}
                >
                    <Row gutter={8} type="flex" justify="center">
                        <Col xs={24} sm={24} md={24} lg={24} xl={24} className="img-label" style={{ marginTop: 24 }}>
                            <label style={{ color: '#000000' }}>{message}</label>
                        </Col>
                    </Row>

                    <Row gutter={8} type="flex" justify="center">
                        <Col xs={24} sm={24} md={24} lg={24} xl={24} className="img-label">
                            <Input placeholder={placeholder} style={inputStyle} className="noBroader" value={value} onChange={(e) => setStateByProps(inputValue, e.target.value)} />
                        </Col>
                    </Row>

                </Modal>
            </div>
        );
    }
}



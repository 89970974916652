import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { token } from '../../config';
import { apiClient } from '../../api';
import NumericLabel from 'react-pretty-numbers';
import {
    Nav,
    NavbarBrand,
    NavbarToggler,
    NavItem,
    NavLink,
} from 'reactstrap';
import HeaderDropdown from './HeaderDropdown';
import { Spin } from 'antd';

const optionnumber = {
    justification: 'L',
    locales: 'en-US',
    percentage: false,
    precision: 0,
    wholenumber: null,
    commafy: true,
    cssClass: ['red'],
    shortFormat: true,
    shortFormatMinValue: 10000000,
    shortFormatPrecision: 1,
    title: true
};

class Header extends Component {

    constructor(props) {
        super(props);
        this.state = {
            intervalID: 0
        }
    }

    componentDidMount() {

        let intervalID = setInterval(() => { this.props.onLoadCredit({ result: true }); }, 300000);
        this.setState({ intervalID: intervalID });
    }
    componentWillUnmount() {
        clearInterval(this.state.intervalID);
    }
    sidebarToggle(e) {
        e.preventDefault();
        document.body.classList.toggle('sidebar-hidden');
    }

    sidebarMinimize(e) {
        e.preventDefault();
        document.body.classList.toggle('sidebar-minimized');
    }

    mobileSidebarToggle(e) {
        e.preventDefault();
        document.body.classList.toggle('sidebar-mobile-show');
    }

    //asideToggle(e) {
    //    e.preventDefault();
    //    document.body.classList.toggle('aside-menu-hidden');
    //}

    render() {
        const text = <span>Title</span>;
        const stylePopover = { width: 250 };
        const { username, admin, createuser, createaccount, balance, type, getCreditbalance, loading } = this.props;
        return (
            <header className="app-header navbar" >
                <NavbarToggler className="d-lg-none" onClick={this.mobileSidebarToggle}>
                    <span className="navbar-toggler-icon"></span>
                </NavbarToggler>
                <NavbarBrand href="/console/home"></NavbarBrand>
                {/* //  Logo  บน Dash board   */}
                <NavbarToggler className="d-md-down-none" onClick={this.sidebarToggle}>
                    <span className="navbar-toggler-icon"></span>
                </NavbarToggler>
                <Nav className="ml-auto" navbar >
                    <div className="m-0" style={{ paddingRight: 15, cursor: 'pointer' }} >
                        <div style={{ marginTop: -5, marginBottom: -10 }}>{username}</div>
                        <small className="wop-gray-low">
                            Credit Balance: {
                                type == 0 && !loading ?
                                    <Spin size="small" />
                                    : loading ?
                                        <Spin size="small" />
                                        : balance == 0 ? "-" :
                                            <NumericLabel params={optionnumber}>
                                                {parseInt(balance)}
                                            </NumericLabel>
                            }
                        </small>
                    </div>

                    <HeaderDropdown accnt {...this.props} checkCreditBalance={getCreditbalance} />

                </Nav>
                {
                    //<Nav className="ml-auto" navbar onClick={this.asideToggle}>
                    //    <HeaderDropdown accnt />
                    //</Nav>
                }
            </header>
        );
    }
}

export default Header;

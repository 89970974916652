/* eslint-disable */
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import {
    Form, Input, DatePicker, TimePicker, Select, Cascader, InputNumber, Drawer, Upload, Modal,
    Icon, Row, Col, Button, Tooltip, Checkbox, Spin, PageHeader, Card, Menu, Dropdown, Popover
} from 'antd';
import { token, getFileExtension, CheckTokenExp,ReplaceUrlTaga } from '../../../config';

import SelectTags from '../../../components/Wop/Select/SelectTags';
import TablePerson from '../../../components/Wop/Table/TablePerson';
import ModalSuccess from '../../../components/Wop/Modal/ModalSuccess';
import ModalWarning from '../../../components/Wop/Modal/ModalWarning';
import { apiClient } from '../../../api';
import{root} from '../../../config';

export default class Person extends Component {
    constructor(props) {
        super(props);
        this.state = {
            warningModalVisible: false,
            successModalVisible: false,
            loading: false,
            message: "",
            messageSuccess: "",
            name: "",
            dest: "",
            tag: null,
            search: {
                name: "",
                dest: "",
                tag: null
            },
            tag: undefined,
            selectedFileList: [],
            action: "",
            key: [],
            popoverhide: false,
            screenwidth: 0,
            exportloading: false,
        };

        this.warningModal = this.warningModal.bind(this);
        this.successModal = this.successModal.bind(this);
        this.modalcountDown = this.modalcountDown.bind(this);
        this.onSearch = this.onSearch.bind(this);
        this.setStateByProps = this.setStateByProps.bind(this);
        this.ExportfileToExcel = this.ExportfileToExcel.bind(this);
        this.showDrawerUpload = this.showDrawerUpload.bind(this);
        this.onCloseUpload = this.onCloseUpload.bind(this);
        this.ExportfileToExcelTemplate = this.ExportfileToExcelTemplate.bind(this);
        this.onUpload = this.onUpload.bind(this);
        this.dummyRequest = this.dummyRequest.bind(this);
        this.UploadFile = this.UploadFile.bind(this);
        this.handleOnDelete = this.handleOnDelete.bind(this);
        this.handleVisibleChange = this.handleVisibleChange.bind(this);
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    }

    componentDidMount() {
        this.updateWindowDimensions();
        window.addEventListener("resize", this.updateWindowDimensions.bind(this));
        ReplaceUrlTaga();
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.updateWindowDimensions.bind(this));
    }

    updateWindowDimensions() {
        setTimeout(() => {
            var root = document.getElementById("root");
            if (root) {
                var e = document.getElementById("main");
                if (e) {
                    var scroll = root.offsetHeight > window.innerHeight;
                    this.setState({ screenwidth: scroll ? e.offsetWidth - 132 : e.offsetWidth - 132 })
                }
            }
        }, 500);
    }

    warningModal(value, message, messageSuccess) {
        this.setState({ warningModalVisible: value, message, messageSuccess });
    }

    successModal(value) {
        if (this.state.action == "delete") {
            this.setState({ action: "" });
            this.handleOnDelete();
        }

        this.setState({ successModalVisible: value });
    }

    modalcountDown() {
        let secondsToGo = 2;
        const timer = setInterval(() => {
        }, 1000);
        setTimeout(() => {
            clearInterval(timer);
            this.setState({ successModalVisible: false });
        }, secondsToGo * 1000);


    }

    onSearch() {
        this.setState(state => {
            return {
                search: {
                    name: state.name,
                    dest: state.dest,
                    tag: (state.tag != null && state.tag != undefined) ? state.tag.toString() : state.tag,
                }
            }
        });
        this.updateWindowDimensions();
    }

    setStateByProps(parameters, value) {
        value ? value : "";
        this.setState({ [parameters]: value });
    }

    ExportfileToExcel() {
        if (!CheckTokenExp()) {
            this.setState({ exportloading: true })
            const { search } = this.state;

            let req = JSON.stringify({
                //"SEARCH_PEOPLE": this.state.name,
                "SEARCH_PEOPLE": search.name,
                "DEST": search.dest,
                "TAG": search.tag,
            });

            apiClient.post('api/Person/EXPORT/', req
                , {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-type': 'application/json; charset=UTF-8'
                    },
                    responseType: 'blob',
                })
                .then(data => {
                    var json = data.data;
                    this.setStateByProps("active", "");
                    const url = window.URL.createObjectURL(new Blob([json]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.download = "Person.xlsx";
                    document.body.appendChild(link);
                    link.click();
                    link.remove();

                    this.setState({ exportloading: false });
                })
                .catch(error => {

                    this.setState({ exportloading: false });
                });
        } else {
            window.location.href = "logout";
        }
        
    }

    ExportfileToExcelTemplate() {
        if (!CheckTokenExp()) {
            this.setState({ exportloading: true })

            apiClient.get('api/Person/EXPORT_TEMPLATE/'
                , {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-type': 'application/json; charset=UTF-8'
                    },
                    responseType: 'blob',
                })
                .then(data => {
                    var json = data.data;

                    const url = window.URL.createObjectURL(new Blob([json]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.download = "Person_Template.xlsx";
                    document.body.appendChild(link);
                    link.click();
                    link.remove();

                    this.setState({ exportloading: false });
                })
                .catch(error => {

                    this.setState({ exportloading: false });
                });
        } else {
            window.location.href = "logout";
        }
        
    }

    showDrawerUpload() {
        this.setState({
            visibleUpload: true,
        });

    };

    onCloseUpload() {
        this.setState({
            visibleUpload: false,
        });
    };

    onUpload(info) {
        if (info.file.size <= 1048576) {
            const nextState = {};
            switch (info.file.status) {
                case "uploading":
                    if (getFileExtension(info.file.name) == "xlsx" || getFileExtension(info.file.name) == "xls") {
                        nextState.selectedFileList = [info.file];
                    } else {
                        this.onCloseUpload();
                        Modal.error({
                            title: 'The file format is incorrect.',
                            className: 'popup-error',
                            content: (
                                <div>
                                    <p>Check and upload the file again.</p>
                                </div>
                            ),
                            onOk() { },
                        });

                        nextState.selectedFile = null;
                        nextState.selectedFileList = [];
                    }
                    break;
                case "done":
                    if (getFileExtension(info.file.name) == "xlsx" || getFileExtension(info.file.name) == "xls") {
                        nextState.selectedFile = info.file;
                        nextState.selectedFileList = [info.file];
                    } else {
                        nextState.selectedFile = null;
                        nextState.selectedFileList = [];
                    }
                    break;
                default:
                    // error or removed
                    nextState.selectedFile = null;
                    nextState.selectedFileList = [];
            }
            this.setState(() => nextState);
        }
    };

    dummyRequest({ file, onSuccess }) {
        if (file.size <= 1048576) {
            var reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = function () {
                this.setState({ filename: file.name, result: reader.result.split(',')[1], filetype: file.type });
            }.bind(this);

            reader.onerror = function (error) {
                console.log('Error: ', error);
            };

            setTimeout(() => {
                onSuccess("ok");
            }, 0);
        }
        else {
            Modal.error({
                className: 'popup-error',
                title: 'Error',
                onOk: () => Modal.destroyAll(),
                content: "File size limit 1 MB",
                icon: <Icon type="exclamation-circle-o" style={{ fontSize: 36 }} />,
                zIndex: 1300
            });
        }

    };

/*     UploadFile() {
        if (!CheckTokenExp()) {
            this.setState({ loading: true, uploading: true })
            let req = JSON.stringify({
                "filebase64": this.state.result,
                "filetype": this.state.filetype
            });

            apiClient.post('api/Person/IMPORT/', req
                , {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-type': 'application/json; charset=UTF-8',
                        'Access-Control-Allow-Origin': '*'
                    }
                })
                .then(data => {
                    var json = data.data;

                    this.setState({
                        loading: false,
                        uploading: false,
                        visibleUpload: false,
                        selectedFileList: [],
                        SEARCH: "",
                        StartDate: null,
                        EndDate: null,
                        RangeDate: null,
                        current: 1,
                        minpage: 1,
                        pagesize: 50,
                    });
                    this.onSearch();
                    Modal.success({
                        className: 'popup-success',
                        title: 'Success',
                        onOk: () => Modal.destroyAll(),
                        content:
                            <div>
                                <p className="ant-margin-p">Insert {json.insert} records</p>
                            </div>,
                        icon: <Icon type="exclamation-circle-o" style={{ fontSize: 36 }} />,
                        zIndex: 1300
                    });
                    this.setStateByProps("action", "");
                    this.setState({ loading: false });
                })
                .catch(error => {
                    this.setState({ loading: false });
                    Modal.error({
                        className: 'popup-error',
                        title: 'Error',
                        onOk: () => Modal.destroyAll(),
                        content: 'Import File Failed No of column incorrect',
                        icon: <Icon type="close-circle" style={{ fontSize: 36 }} />,
                        zIndex: 1300
                    });
                });
        } else {
            window.location.href = "logout";
        }
         */
    

    UploadFile() {
        if (!CheckTokenExp()) {
            this.setState({ loading: true, uploading: true })
            let req = JSON.stringify({
                "filebase64": this.state.result,
                "filetype": this.state.filetype,
                "filename" : this.state.filename
            });

            apiClient.post('api/Person/IMPORTNEW', req
                , {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-type': 'application/json; charset=UTF-8',
                        'Access-Control-Allow-Origin': '*'
                    }
                })
                .then(data => {
                    var json = data.data;

                    if(data.data){
                        this.setState({
                            loading: false,
                            uploading: false,
                            visibleUpload: false,
                            selectedFileList: [],
                            SEARCH: "",
                            StartDate: null,
                            EndDate: null,
                            RangeDate: null,
                            current: 1,
                            minpage: 1,
                            pagesize: 50,
                        });
                        this.onSearch();
                        Modal.success({
                            className: 'popup-success',
                            title: 'Success',
                            onOk: () => Modal.destroyAll(),
                            content:
                                <div>
                                    <p className="ant-margin-p">Insert {json.insert} records</p>
                                </div>,
                            icon: <Icon type="exclamation-circle-o" style={{ fontSize: 36 }} />,
                            zIndex: 1300
                        });
                        this.setStateByProps("action", "");
                        this.setState({ loading: false });
                    }else{
                        throw new error
                    }

                    
                })
                .catch(error => {
                    this.setState({ loading: false });
                    Modal.error({
                        className: 'popup-error',
                        title: 'Error',
                        onOk: () => Modal.destroyAll(),
                        content: 'Import File Failed No of column incorrect',
                        icon: <Icon type="close-circle" style={{ fontSize: 36 }} />,
                        zIndex: 1300
                    });
                });
        } else {
            window.location.href = "logout";
        }
        
    }

    handleOnDelete() {
        if (!CheckTokenExp()) {
            this.setState({ loading: true })

            let req = JSON.stringify({
                PERSON: this.state.key,
            });

            apiClient.post('api/Person/PERSON_DELETE_ALL', req
                , {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-type': 'application/json; charset=UTF-8'
                    }
                })
                .then(data => {
                    var json = data.data;

                    this.onSearch();

                    this.setState({ loading: false, key: [] });
                })
                .catch(error => {

                    this.setState({ loading: false });
                });
        } else {
            window.location.href = "logout";
        }

    }

    handleVisibleChange(value) {
        this.setState({ popoverhide: value });
    };

    render() {
        const { loading, message, messageSuccess, warningModalVisible, successModalVisible, search, name, dest, tag, key, popoverhide, screenwidth } = this.state;
        const routes = [
            {
                path: 'People',
                breadcrumbName: 'People',
            },
        ];
        var styleinput_name = {
            width: (screenwidth / 3),
            marginRight: 8,
            marginBottom: 8,
            minWidth: 200
        };
        var styleinput_destination = {
            width: (screenwidth / 3),
            marginRight: 8,
            marginBottom: 8,
            minWidth: 200
        };
        var styleinput_tag = {
            width: (screenwidth / 3),
            marginRight: 8,
            marginBottom: 8,
            minWidth: 200
        };
        const props = {
            showUploadList: {
                showDownloadIcon: false,
                showRemoveIcon: true,
            },
        };
        return (
            <Spin spinning={this.state.exportloading}>
                <div className="animated fadeIn">
                    <PageHeader breadcrumb={{ routes }}>

                    </PageHeader>

                    <div className="content">
                        <Card style={{ minHeight: 200 }}>

                            <Row gutter={8} type="flex" justify="space-between">
                                <Col xs={12}>
                                    <Button key="submit" style={{ marginRight: 8 }}
                                        disabled={key.length > 0 ? false : true}
                                        onClick={() => {
                                            this.warningModal(true, "Do you want to delete this person? ", "Deleted person successfully."),
                                                this.setStateByProps("action", "delete")
                                        }}>
                                        <span className="fa fa-trash-o"></span> &nbsp;&nbsp;
                                       Delete
                        </Button>
                                </Col>
                                <Col xs={12}>

                                    <div style={{ float: "right" }}>


                                        <Popover placement="bottomRight"
                                            visible={popoverhide} onVisibleChange={(e) => this.handleVisibleChange(e)}
                                            content={
                                                <Menu mode="vertical">
                                                    <Menu.Item style={{ borderBottom: '1px solid #7b7b7b' }}>
                                                        <Link to={'/people/person/detail'} style={{ textDecoration: 'none' }}>
                                                            <span className="fa fa-plus-circle wop-gray size14"></span> &nbsp;&nbsp;
                                                        New
                                                    </Link>
                                                    </Menu.Item>
                                                    <Menu.Item onClick={this.ExportfileToExcel} style={{ borderBottom: '1px solid #7b7b7b' }}>
                                                        <span className="fa fa-cloud-download wop-gray size14"></span> &nbsp;&nbsp;
                                                        Export File
                                            </Menu.Item>
                                                    <Menu.Item onClick={() => { this.handleVisibleChange(false), this.showDrawerUpload() }} style={{ borderBottom: '1px solid #7b7b7b' }}>
                                                        <span className="fa fa-cloud-upload wop-gray size14"></span> &nbsp;&nbsp;
                                                    Import File
                                            </Menu.Item>
                                                    <Menu.Item onClick={this.ExportfileToExcelTemplate} style={{ borderBottom: '1px solid #7b7b7b' }}>
                                                        <span className="fa fa-file-o wop-gray size14"></span> &nbsp;&nbsp;
                                                        Download Template
                                            </Menu.Item>
                                                </Menu>} trigger="click">
                                            <Button> <span className="fa fa-list"></span> &nbsp;&nbsp; Action</Button></Popover>
                                    </div>

                                </Col>
                            </Row>

                            <Row gutter={8} type="flex" justify="start" className="row-nowrap" id="main">
                                <Col xs={24}>
                                    <Input placeholder="Name" value={name} style={styleinput_name} onChange={(e) => this.setStateByProps("name", e.target.value)} />

                                    <Input placeholder="Destination" value={dest} style={styleinput_destination} onChange={(e) => this.setStateByProps("dest", e.target.value)} />


                                    <SelectTags select={tag} setStateByProps={this.setStateByProps} style={styleinput_tag} />

                                    <Button icon="search" onClick={this.onSearch}>
                                        Search
                                </Button>
                                </Col>
                            </Row>

                            <Row gutter={8} type="flex" justify="space-between">
                                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                    <TablePerson
                                        search={search}
                                        warningModal={this.warningModal}
                                        loading={loading}
                                        setStateByProps={this.setStateByProps}
                                        updateWindowDimensions={this.updateWindowDimensions}
                                    />
                                </Col>
                            </Row>
                        </Card>
                    </div>

                    <Drawer
                        className="boy-drawer"
                        style={{ zIndex: 1022 }}
                        title={<h3>Upload File</h3>}
                        placement={this.state.placementUpload}
                        height={'auto'}
                        closable={false}
                        onClose={this.onCloseUpload}
                        visible={this.state.visibleUpload}
                    >
                        <Row>
                            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                <Upload {...props}
                                    id="input"
                                    accept=".xlsx"
                                    fileList={this.state.selectedFileList}
                                    customRequest={this.dummyRequest}
                                    onChange={this.onUpload}
                                    style={{ width: "100%" }}
                                >
                                    <Button style={{ width: "100%" }}><Icon type="import" /> IMPORT FILE</Button>
                                </Upload>
                                <Button
                                    type="primary"
                                    loading={loading}
                                    onClick={this.UploadFile}
                                    style={{ marginTop: 16 }}
                                    disabled={this.state.selectedFileList.length == 0}
                                >
                                    {loading ? 'UPLOADING' : 'START UPLOAD'}
                                </Button>
                                <label class=" control-label require">File type * xlsx maximum upload file size total 1 MB</label>
                            </Col>
                        </Row>
                    </Drawer>

                    <ModalWarning loading={loading}
                        warningModalVisible={warningModalVisible}
                        warningModal={this.warningModal}
                        successModal={this.successModal}
                        modalcountDown={this.modalcountDown}
                        message={message}
                        messageSuccess={messageSuccess}
                    />

                    <ModalSuccess loading={loading}
                        successModalVisible={successModalVisible}
                        successModal={this.successModal}
                        messageSuccess={messageSuccess}
                    />

                </div>
            </Spin>
        );
    }
}



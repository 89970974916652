import React, { Component } from 'react';
import { Form, Input, DatePicker, TimePicker, Select, Cascader, InputNumber, Icon, Row, Col, Button, Drawer, Upload, Modal, Collapse, Card, Table } from 'antd';
import { formatNumber } from '../../../config';

const { Panel } = Collapse;

export default class Card2_Extra extends Component {

    constructor(props) {
        super(props);

        this.state = {

        };

    }

    render() {
        const { img, title, detail, Table, data } = this.props;
        const { columns } = this.state;
        const text = (
            <p>
                A dog is a type of domesticated animal. Known for its loyalty and faithfulness, it can be found
                as a welcome guest in many households across the world.
            </p>
        );

        return (
            <Card className="card2">
                <Row>
                    <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                        <img src={img} />
                    </Col>
                    <Col xs={0} sm={0} md={0} lg={0} xl={1}>
                    </Col>
                    <Col xs={18} sm={18} md={18} lg={18} xl={17}>
                        <h1>{formatNumber(title)}</h1>
                        <label className="wop-gray-low">{detail}</label>
                    </Col>
                </Row>

                <Collapse
                    defaultActiveKey={['1']}
                    style={{ marginTop: -24 }}
                    bordered={false}
                    expandIconPosition={"right"}
                    expandIcon={({ isActive }) => <Icon type="down-circle" style={{ color: '#7b7b7b' }} rotate={isActive ? 180 : 0} />}
                >
                    <Panel key="1">
                        <Table data={data} />
                    </Panel>
                </Collapse>
            </Card>
        );
    }
}



/* eslint-disable */
import React, { Component } from 'react';
import { Table, Divider, Tag, Icon, Button, Card, Row, Col, Modal, Input, Select, Spin, Checkbox } from 'antd';
import { apiClient } from '../../../api';
import{root} from '../../../config';
import { token, CheckTokenExp } from '../../../config';
import TagSender from '../Tag/TagSender';
import SelectSubAccSender from "../Select/SelectSubAccSender";
import SelectPayType from '../Select/SelectPayType';
import ButtonGroupStatusAcc from '../../../components/Wop/Button/ButtonGroupStatusAcc';
import ButtonGroupSubAcc from '../../../components/Wop/Button/ButtonGroupSubAcc';

const { TextArea } = Input;
const { Option } = Select;

export default class ModalAddAccount extends Component {

    constructor(props) {
        super(props);


        this.state = {
            usercheck: false,
            errormessage: "",
            senderlist: [],
            senderError: "",
        };
        this.CheckUserDuplicate = this.CheckUserDuplicate.bind(this);
        this.AddSenderList = this.AddSenderList.bind(this);
        this.RemoveSender = this.RemoveSender.bind(this);
        this.setStateByProps = this.setStateByProps.bind(this);
        this.passwordFilter = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^\da-zA-Z]).{6,}$/
    }

    componentDidMount() {


    }

    componentDidUpdate(prevProps) {
        if (this.props.clearsender !== prevProps.clearsender) {
            this.setState({ senderlist: [], senderError: "" })
            this.props.setStateByProps("clearsender", false);
        }
    }

    CheckUserDuplicate() {
        if (!CheckTokenExp()) {
            this.setState({ loading: true });

            let req = JSON.stringify({
                username: this.props.username
            });

            apiClient.post('api/usersmanage/CheckUserDuplicate', req
                , {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-type': 'application/json; charset=UTF-8'
                    }
                })
                .then(data => {
                    var json = data.data;

                    if (!json && this.state.password === this.state.repassword) {
                        this.props.warningModal(true, "Do you want to add account?", "Add account successfully.");
                        this.props.setStateByProps('action', 'createacc');
                    }

                    this.setState({ usercheck: json });
                    this.setState({ loading: false });
                })
                .catch(error => {
                    this.setState({ loading: false });
                });
        } else {
            window.location.href = "logout";
        }
        
    }

    setStateByProps(parameters, value) {
        value ? value : "";

        this.setState({ [parameters]: value });
        this.setState({ senderError: "" })
        this.props.setStateByProps("MadalAddAccountvisible", false);
    }

    AddSenderList(value) {
        if (!this.state.senderlist.includes(value) === true) {
            this.setState({
                senderlist: [...this.state.senderlist, value],
                senderError: "",
            })
            this.props.setStateByProps("senderlist", [...this.state.senderlist, value]);
        }
        else {
            this.setState({
                senderError: "Duplicate Sender"
            })
        }
        
    }

    RemoveSender(value) {
        var array = [...this.state.senderlist]; // make a separate copy of the array
        var index = array.indexOf(value)
        if (index !== -1) {
            array.splice(index, 1);
            this.setState({ senderlist: array });
            this.props.setStateByProps("senderlist", array);
        }
    }


    render() {
        const { visible, setStateByProps, loading, accname, email, username, password, repassword,
            paytype, statusacc, creactsubeaccount, type, emailError, errorMessage, dsender, senderarr } = this.props;
        const { usercheck, senderlist, senderError } = this.state;
        const styleMarginBottom = { marginBottom: 16 };
        const styleMarginTop = { marginBottom: 16, marginTop: 40 };
        const styleMarginRow = { marginBottom: 16, marginRight: 31, marginLeft: 38 };

        return (
            <Modal
                width={900}
                title={<div>Create account <span className="fa fa-times-circle-o card-close" onClick={() => this.setStateByProps("senderlist", [])}></span></div>}
                visible={visible}
                onOk={this.props.handleOk}
                onCancel={() => cancelModal(false)}
                loading={loading}
                footer={[
                    <Row gutter={16} type="flex" justify="end" style={styleMarginRow} >
                        <Col xs={12} sm={12} md={12} lg={12} xl={12} style={{ textAlign: 'left' }}>
                            <label className="red" style={{ fontWeight: 600 }}>{errorMessage}</label>
                            <label className="red" style={{ marginLeft: 8, display: (!this.passwordFilter.test(password) && password === repassword) ? 'none' : 'initial' }}>{
                                password ?
                                    this.passwordFilter.test(password) ?
                                        password === repassword ? ""
                                            : "Passwords do not match"
                                        : <>
                                            <div>
                                            - The password must be at least 6 characters long
                                            </div>
                                            <div>
                                            - Uppercase and Lowercase is required
                                            </div>
                                            <div>
                                            - Number is required
                                            </div>
                                            <div>
                                            - Special character is required
                                            </div>
                                        </>
                                    : ""
                            }</label>
                        </Col>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12} style={{ textAlign: 'right' }} >
                            <Button type="primary" style={{ marginRight: 8 }} loading={loading}
                                disabled={accname && email && emailError && username && !errorMessage && senderlist.length > 0 && this.passwordFilter.test(password) && password === repassword ? false : true}
                                onClick={this.CheckUserDuplicate}>
                                <span className="fa fa-plus-circle"></span> &nbsp;&nbsp;
                                Save
                            </Button>
                        </Col>
                    </Row>
                ]}
            >
                <Spin spinning={loading}>
                    <Row gutter={16} type="flex" justify="start" >
                        <Col xs={24} sm={24} md={24} lg={24} xl={8} style={styleMarginBottom}>
                            <label className=" control-label require" style={{ fontWeight: 700 }}> Account name</label>
                            <label className="red">*</label>
                            <Input placeholder="Account name" value={accname} onChange={(e) => setStateByProps("accname", e.target.value)} maxLength={100} />
                        </Col>

                        <Col xs={24} sm={24} md={24} lg={24} xl={8} style={styleMarginBottom}>
                            <label className=" control-label require" style={{ fontWeight: 700 }}> Username</label>
                            <label className="red">*</label>
                            <label className="red" style={{ marginLeft: 8 }}>{usercheck ? "User duplicate" : ""}</label>

                            <Input placeholder="Username" value={username} onChange={(e) => { setStateByProps("username", e.target.value), this.setState({ usercheck: "" }) }} maxLength={30} />
                        </Col>

                        <Col xs={24} sm={24} md={24} lg={24} xl={8} style={styleMarginBottom}>
                            <label className=" control-label require" style={{ fontWeight: 700 }}> Payment type</label>
                            <label className="red">*</label>
                            <SelectPayType select={paytype} setStateByProps={setStateByProps} disabled={type == 2 ? false : true} />
                        </Col>

                        <Col xs={24} sm={24} md={24} lg={24} xl={8} style={styleMarginBottom}>
                            <label className=" control-label require" style={{ fontWeight: 700 }}> Email</label>
                            <label className="red" style={{ fontWeight: 600 }}>*  &nbsp;{!emailError && email != "" ? "Invalid email format" : ""}</label>
                            <Input placeholder="Email" value={email} onChange={(e) => setStateByProps("email", e.target.value)} maxLength={100} />
                        </Col>

                        <Col xs={24} sm={24} md={24} lg={24} xl={8} style={styleMarginBottom}>
                            <label className=" control-label require" style={{ fontWeight: 700 }}> Password</label>
                            <label className="red">*</label>
                            <Input.Password id="MAAPassword" name="MAAPassword" placeholder="Password" value={password} onChange={(e) => setStateByProps("password", e.target.value)} maxLength="30" />
                        </Col>

                        <Col xs={24} sm={24} md={24} lg={24} xl={8} style={styleMarginBottom}>
                            <label className=" control-label require" style={{ fontWeight: 700 }}> Re-type password </label>
                            <span className="red">*
                            </span>

                            <Input.Password placeholder="Re-type Password" value={repassword} onChange={(e) => { setStateByProps("repassword", e.target.value) }} maxLength="30" />
                        </Col>

                        <Col xs={24} sm={24} md={24} lg={24} xl={8} style={styleMarginBottom}>
                            <label className=" control-label require" style={{ fontWeight: 700 }}> Create Subaccount</label>
                            <label className="red">*</label>
                            <br />
                            <ButtonGroupSubAcc tocreate={"creactsubeaccount"} radio={creactsubeaccount ? 1 : 0} setStateByProps={setStateByProps} />
                        </Col>

                        <Col xs={24} sm={24} md={24} lg={24} xl={8} style={styleMarginBottom}>
                            <label className=" control-label require" style={{ fontWeight: 700 }}> Status</label>
                            <label className="red">*</label>
                            <br />
                            <ButtonGroupStatusAcc statename={"addstatusacc"} radio={statusacc} setStateByProps={setStateByProps} />
                        </Col>

                        <Col xs={24} sm={24} md={24} lg={24} xl={8} style={styleMarginBottom}>

                        </Col>

                        <Col xs={24} sm={24} md={24} lg={24} xl={8} style={styleMarginBottom}>
                            <label className=" control-label require" style={{ fontWeight: 700 }}> Sender</label>
                            <label className="red" style={{ fontWeight: 600 }}>*  &nbsp;{senderError != "" ? senderError : ""}</label>
                            <SelectSubAccSender select={dsender} placeholder="Select sender" setStateByProps={setStateByProps} />
                        </Col>

                        <Col xs={24} sm={24} md={24} lg={24} xl={16} style={styleMarginTop}>
                            <Button type="primary" style={{ marginRight: 8 }} loading={loading}
                                disabled={dsender != "" && dsender != null && dsender != undefined ? false : true}
                                onClick={() => { this.AddSenderList(dsender), setStateByProps("dsender", undefined) }}>
                                <span className="fa fa-plus-circle"></span> &nbsp;&nbsp;
                                Add
                            </Button>
                        </Col>

                        <Col xs={24} sm={24} md={24} lg={24} xl={12}>
                            <Card style={{ width: "100%", height: 100, overflow: "auto" }}>
                                {
                                    senderlist.map((item, index) =>
                                        item ?
                                            <TagSender
                                                title={item}
                                                RemoveSender={this.RemoveSender}
                                            />
                                            :
                                            null
                                    )
                                }
                            </Card>

                        </Col>

                    </Row>
                </Spin>
            </Modal>
        );
    }
}




/* eslint-disable */
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Table, Divider, Tag, Icon, Button, Row, Col, Modal, Input, Tooltip } from 'antd';

import { apiClient } from '../../../api';
import{root} from '../../../config';
import { token, getUrlVars, validateEmail, CheckTokenExp } from '../../../config';

import TablePersonList from './TablePersonList';

export default class TableTagDetail extends Component {

    constructor(props) {
        super(props);
        this.state = {
            columns: [
                {
                    dataIndex: 'key',
                    key: 'PER_ID',
                    width: '8%',
                    render: (key, item) => (
                        <div>
                            <span className="fa fa-trash-o" style={{ cursor: 'pointer', fontSize: 16 }}
                                onClick={() => {
                                    this.props.warningModal(true, "Do you want to delete this member?", "Deleted member successfully."),
                                        this.props.setStateByProps("action", "delete"),
                                        this.props.setStateByProps("Deletelist", [{ PRS_ID: item.PRS_ID }])
                                }}
                            ></span>
                        </div>
                    ),
                },
                {
                    title: 'Name',
                    dataIndex: 'PER_NAME',
                    key: 'PER_NAME',
                    render: text => (text ? <div className="table-title" title={text}>{text}</div> : "No name")
                },
                {
                    title: 'Destination',
                    dataIndex: 'DES_VALUE_LIST',
                    width: 300,
                    render: (text, item) => (
                        <div>
                            {
                                item.DES_PRIMARY ? item.DES_PRIMARY : "123456789"
                            }
                            {
                                item.DES_VALUE_LIST.length > 0 ?
                                    <Tooltip placement="bottom"
                                        title={
                                            item.DES_VALUE_LIST.map((value) => {
                                                return (
                                                    <p style={{ marginBottom: '1px' }}>{value}</p>
                                                )
                                            })
                                        }>
                                        <label style={{ color: '#36b4a1' }}>{"+" + item.DES_COUNT + " more"}</label>
                                    </Tooltip>
                                    : null
                            }

                        </div>
                    )
                },
                {
                    title: 'Tag',
                    dataIndex: 'PRG_VALUE_LIST',
                    key: 'PRG_VALUE_LIST',
                    render: (value, item) => (
                        <div className="table-content" title={value.map((text, i) => text)}>
                            {
                                value.map((text, i) =>
                                    <span>
                                        {text} {value.length == i + 1 ? "" : " , "}
                                    </span>
                                )
                            }
                        </div>
                    )
                },
                {
                    title: 'Channel',
                    dataIndex: 'channel',
                    key: 'channel',
                    width: 100,
                    align: 'center',
                    render: (text, item) => {
                        { var phone = false; var email = false; }
                        return (
                            <div>
                                {
                                    item.CHANNEL_PRIMARY == "2" ? email = true : phone = true
                                }
                                {
                                    item.CHANNEL_LIST.map((value, index) => {
                                        if (value == "2") {
                                            if (!email) {
                                                email = true;
                                            }
                                        } else {
                                            if (!phone) {
                                                phone = true
                                            }
                                        }
                                    }
                                    )
                                }
                                {
                                    phone ?
                                        <span className="fa fa-mobile" style={{ paddingRight: 10, fontSize: 16 }} /> : ""
                                }
                                {
                                    email ?
                                        <span className="fa fa-envelope-o" style={{ paddingRight: 10, fontSize: 16 }} /> : ""
                                }
                            </div>
                        )
                    }
                },
            ],
            StartDate_Per: null,
            EndDate_Per: null,
            PRG_ID: 0,
            SEARCH_PER: "",
            totalpage: 0,
            Tagpersonlist: [],
            pagesize: 10,
            currentpage: 1,
            minpage: 1,
            maxpage: 10,
            enddata: 10,
            data: [],
        };

        this.RequestInfoTagsTagGroupAssign = this.RequestInfoTagsTagGroupAssign.bind(this);
        this.onChangePagePerson = this.onChangePagePerson.bind(this);
        this.getTagname = this.getTagname.bind(this);

    }

    componentDidMount() {
        this.RequestInfoTagsTagGroupAssign();
        //this.getTagname();
    }

    componentDidUpdate(prevProps) {
        if (this.props.search !== prevProps.search) {
            this.RequestInfoTagsTagGroupAssign();
        }
    }

    RequestInfoTagsTagGroupAssign(currentpage_per) {
        if (!CheckTokenExp()) {
            this.setState({ loading: true })
            const { search } = this.props;

            this.setState(state => {

                let req = JSON.stringify({
                    "START_DATE": this.state.StartDate_Per == "" ? null : this.state.StartDate_Per,
                    "END_DATE": this.state.EndDate_Per == "" ? null : this.state.EndDate_Per,
                    "PRG_ID": getUrlVars()["id"],
                    "SEARCH": search.name,
                    "DEST": search.des,
                    "MINPAGE": state.minpage,
                    "PAGESIZE": state.maxpage
                });

                apiClient.post('api/Tag/TAGGROUPASSIGN_LIST', req
                    , {
                        headers: {
                            'Authorization': `Bearer ${token}`,
                            'Content-type': 'application/json; charset=UTF-8'
                        }
                    })
                    .then(data => {
                        var json = data.data;

                        this.setState({
                            totalpage: json ? json.totalpage : 0,
                            currentpage: state.minpage == 1 ? 1 : state.currentpage,
                            minpage: 1
                        });
                        this.setState({ Tagpersonlist: json ? json.person_list : [], }, () => { this.props.updateWindowDimensions() });
                        this.getTagname();
                        this.setState({ loading: false });
                    })
                    .catch(error => {
                        this.setState({
                            Tagpersonlist: [],
                            totalpage: 0,
                            currentpage: 1,
                            minpage: 1
                        });
                        this.setState({ loading: false });
                    });
            });
        } else {
            window.location.href = "logout";
        }
        
    }

    getTagname() {
        if (!CheckTokenExp()) {
            if (getUrlVars()["id"]) {
                apiClient.get('api/Tag/TAGNAME?id=' + getUrlVars()["id"]
                    , {
                        headers: {
                            'Authorization': `Bearer ${token}`,
                            'Content-type': 'application/json; charset=UTF-8'
                        }
                    })
                    .then(data => {
                        var json = data.data;

                        this.props.setStateByProps("titel", json);
                        this.props.setStateByProps("TAG_NAME", json);

                    })
                    .catch(error => {

                    });
            }
        } else {
            window.location.href = "logout";
        }
        
    }

    onChangePagePerson(currentPage, pageSize) {
        var currentpage = this.state.pagesize == pageSize ? currentPage : 1;
        var maxpage = currentpage * pageSize;
        var minpage = maxpage - pageSize + 1;

        this.setState({
            loading: true,
            currentpage: currentpage,
            pagesize: pageSize,
            minpage: minpage,
            maxpage: maxpage
        });
        this.RequestInfoTagsTagGroupAssign();
    }

    render() {
        const { columns, Tagpersonlist, loading, totalpage } = this.state;
        const rowSelection_person = {
            onChange: (selectedRowKeys, selectedRows) => {
                this.props.setStateByProps("Deletelist", selectedRows);
            },
        };
        return (
            <div>
                <Table
                    loading={loading}
                    rowKey={key => key.ID}
                    pagination={{
                        current: this.state.currentpage,
                        defaultPageSize: "10",
                        pageSizeOptions: ['10', '20', '50', '100'],
                        showSizeChanger: true,
                        total: totalpage,
                        showTotal: (total, range) => `${range[0]}-${range[1]} of ${totalpage} items`,
                        onShowSizeChange: (currentPage, pageSize) => this.onChangePagePerson(currentPage, pageSize),
                        onChange: (currentPage, pageSize) => this.onChangePagePerson(currentPage, pageSize)
                    }}
                    rowSelection={rowSelection_person}
                    dataSource={Tagpersonlist}
                    columns={columns}
                    className="table-wop"
                    tableLayout="fixed"
                />

            </div>

        );
    }
}



import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col, CardGroup, Card, CardBody, InputGroup, Input } from 'reactstrap';
import { Button, Icon } from 'antd';
import {urlgetip } from '../../../api';
import { token, set_cookie, getExpiredDate, Logo_Login} from '../../../config';

import { apiClient } from '../../../api';
import{root} from '../../../config';

// const logo = require('../../../img/logo.png')

export class Login extends Component {
    constructor(props) {
        super(props);

        this.state = {
            btnLoginloading: false,
            username: "",
            password: "",
            errormessage: "",
            showPassword: false,
            ip: "",
            clientInfo: ""
        }

        this.getCookie = this.getCookie.bind(this);
        this.handleLogin = this.handleLogin.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this._handleKeyPress = this._handleKeyPress.bind(this);
        this.handleShowPassword = this.handleShowPassword.bind(this);
        this.utilIp = this.utilIp.bind(this);
    }

    componentDidMount() {
        console.log('V4');
        this.getCookie("__AntiXsrfUerkey");
        this.utilIp();
    }

    getCookie(cname) {
        var name = cname + "=";
        var ca = document.cookie.split(';');
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) == ' ') {
                c = c.substring(1);
            }

            if (c.indexOf(name) == 0) {
                if (name == "__AntiXsrfUerkey=") {
                    this.props.history.push("/dashboard");
                }
            }
        }
        return "";
    }

    handleLogin(e) {
        e.preventDefault();
        this.setState({ btnLoginloading: true });
        this.setState({ errormessage: "" });
        if (this.state.username != "" && this.state.password != "") {
            //let now = new Date();
            //let time = now.getTime();
            //let expireTime = time + 1 * 60 * 1000;
            //now.setTime(expireTime);

            let req = JSON.stringify({
                "username": this.state.username,
                "password": this.state.password,
                "expireMinutes": "60",
                "ip": this.state.ip,
                "device": this.state.clientInfo,
            });

            apiClient.post('api/Token', req
                , { headers: { 'Content-type': 'application/json; charset=UTF-8' } })
                .then(data => {
                    var json = data;

                    // if (json.status == 200) {
                    //     console.log('200')
                    //     let Token = "__AntiXsrfUerkey=" + encodeURIComponent(json.token);
                    //     let ExpiredDate = getExpiredDate(Token).toGMTString();
                    //     let SendToken = Token + ";expires=" + ExpiredDate + ";path=/";
                    //     set_cookie(SendToken);
                    //     this.props.history.push("/dashboard");
                    // } else {
                    //     this.setState({ errormessage: json.description });
                    //     this.setState({ btnLoginloading: false });
                    // }
                    console.log('data', data)
                    let Token = "__AntiXsrfUerkey=" + encodeURIComponent(json.data);
                    let ExpiredDate = getExpiredDate(Token).toGMTString();
                    let SendToken = Token + ";expires=" + ExpiredDate + ";path=/";
                    set_cookie(SendToken);
                    this.props.history.push("/dashboard");
                })
                .catch(error => {
                    console.log('error', error)
                    this.setState({ errormessage: 'Username or Password is incorrect.' });
                    this.setState({ btnLoginloading: false });
                });
        } else {
            this.setState({ errormessage: "Username or Password is incorrect." });
            setTimeout(() => {
                this.setState({ btnLoginloading: false });
            }, 10);
        }

        if (!this.state.password) {
            this.inputpassword.focus();
        }
        if (!this.state.username) {
            this.inputusername.focus();
        }
    }

    handleChange(e) {
        this.setState({ [e.target.name]: e.target.value });
    }

    _handleKeyPress(e) {
        if (e.key === 'Enter') {
            this.handleLogin(e);
        }
    }

    handleShowPassword() {
        this.setState({ showPassword: !this.state.showPassword });

    }

    utilIp() {
        urlgetip.get(''
            , { headers: { 'Content-type': 'application/json; charset=UTF-8' } })
            .then(data => {
                var json = data.data;
                this.setState({ ip: json });
            })
            .catch(error => {
                this.setState({ ip: "" });
            });
    }


    render() {
        const { btnLoginloading, errormessage, showPassword } = this.state;
        return (
            <div className="app flex-row align-items-center login-background">
                <Container className="container-login">
                    <img src={Logo_Login} className="login-logo" />
                      {/* //  Logo  บน tab Login   */}
                    <Row className="justify-content-left">
                        <Col md="6">
                            <CardGroup>
                                <Card className="p-4" className="login-card">
                                    <CardBody>
                                        <h1 className="login-titel">Login to your account</h1>

                                        <h5>Username</h5><p />
                                        <InputGroup className="mb-3">

                                            <input type="text" className="input-login"
                                                ref={(input) => { this.inputusername = input; }}
                                                name="username" placeholder="Please enter you username" onChange={this.handleChange} onKeyPress={this._handleKeyPress} />
                                        </InputGroup>

                                        <h5>Password</h5><p />
                                        <InputGroup className="mb-3">
                                            <input type={showPassword ? "text" : "password"} className="input-login"
                                                ref={(input) => { this.inputpassword = input; }}
                                                name="password" onChange={this.handleChange} onKeyPress={this._handleKeyPress} placeholder="Please enter you password" />
                                            <Icon onClick={this.handleShowPassword} type={showPassword ? "eye" : "eye-invisible"} className="showpassword" />
                                        </InputGroup>

                                        <Row>
                                            <Col xs="12">
                                                <Button type="primary" style={{ marginRight: 8, float: 'right' }} loading={btnLoginloading} onClick={this.handleLogin}>
                                                    Login
                                                 </Button>
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col xs="12" style={{ textAlign: "right" }}>
                                                <label htmlFor={errormessage} style={{ color: "red" }}>{errormessage}</label>
                                            </Col>
                                        </Row>

                                    </CardBody>
                                </Card>
                            </CardGroup>
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }
}

    export default Login;

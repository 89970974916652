/* eslint-disable */
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import {
    Form, Input, DatePicker, TimePicker, Select, Cascader, InputNumber, Icon, Row, Col,
    Button, Tooltip, Checkbox, Spin, PageHeader, Card, Menu, Dropdown, Modal, Popover, Drawer,
    Upload
} from 'antd';
import { token, getFileExtension, CheckTokenExp,ReplaceUrlTaga } from '../../../config';

import TableBacklist from '../../../components/Wop/Table/TableBacklist';
import ModalSuccess from '../../../components/Wop/Modal/ModalSuccess';
import ModalWarning from '../../../components/Wop/Modal/ModalWarning';
import ModalBlacklist from '../../../components/Wop/Modal/ModalBlacklist';
import moment from 'moment';
import 'moment-timezone';
import { apiClient } from '../../../api';
import{root} from '../../../config';

moment.tz.setDefault("Asia/Bangkok");


const { MonthPicker, RangePicker, WeekPicker } = DatePicker;

const dateFormat = 'DD/MM/YYYY';
const { TextArea } = Input;



export default class Blacklist extends Component {
    constructor(props) {
        super(props);

        this.state = {
            visible: false,
            warningModalVisible: false,
            successModalVisible: false,
            loading: false,
            message: "",
            messageSuccess: "",
            msg_errors: "",
            name: "",
            dest: "",
            dateRange: [null, null],
            key: [],
            search: {
                name: "",
                dest: "",
                dateRange: [null, null]
            },
            action: "",
            BLK_DES: "",
            visibleUpload: false,
            selectedFileList: [],
            placementUpload: 'right',
            screenwidth: 0,
            exportloading: false,
        };


        this.showModal = this.showModal.bind(this);
        this.handleOk = this.handleOk.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
        this.warningModal = this.warningModal.bind(this);
        this.successModal = this.successModal.bind(this);
        this.modalcountDown = this.modalcountDown.bind(this);
        this.onSearch = this.onSearch.bind(this);
        this.setStateByProps = this.setStateByProps.bind(this);
        this.handleCheckBlacklist = this.handleCheckBlacklist.bind(this);
        this.handleOnSaveBlacklist = this.handleOnSaveBlacklist.bind(this);
        this.handleOnDeleteBlacklist = this.handleOnDeleteBlacklist.bind(this);
        this.ExportfileToExcel = this.ExportfileToExcel.bind(this);
        this.ExportfileToExcelTemplate = this.ExportfileToExcelTemplate.bind(this);
        this.showDrawerUpload = this.showDrawerUpload.bind(this);
        this.onCloseUpload = this.onCloseUpload.bind(this);
        this.UploadFile = this.UploadFile.bind(this);
        this.dummyRequest = this.dummyRequest.bind(this);
        this.onUpload = this.onUpload.bind(this);
        this.handleVisibleChange = this.handleVisibleChange.bind(this);
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    }

    componentDidMount() {
        this.updateWindowDimensions();
        window.addEventListener("resize", this.updateWindowDimensions.bind(this));
        ReplaceUrlTaga();
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.updateWindowDimensions.bind(this));
    }

    updateWindowDimensions() {
        setTimeout(() => {
            var root = document.getElementById("root");
            if (root) {
                var e = document.getElementById("main");
                if (e) {
                    var scroll = root.offsetHeight > window.innerHeight;
                    this.setState({ screenwidth: scroll ? e.offsetWidth - 132 : e.offsetWidth - 132 })
                }
            }
        }, 500);
    }

    showModal() {
        this.setState({
            visible: true,
        });
    };

    handleOk(e) {
        this.setState({
            visible: false,
            BLK_PER: undefined,
            BLK_DES: "",
            BLK_DESC: "",
            errors: "",
        });
    };

    handleCancel(e) {
        this.setState({
            visible: false,
            BLK_PER: undefined,
            BLK_DES: "",
            BLK_DESC: "",
            errors: "",
        });
    }

    warningModal(value, message, messageSuccess) {
        this.setState({ warningModalVisible: value, message, messageSuccess });
    }

    successModal(value) {
        if (this.state.action == "add") {
            this.handleOnSaveBlacklist();
            this.setState({ action: "" });
            this.setState({ successModalVisible: value, errors: "" });
        }
        if (this.state.action == "delete") {
            this.handleOnDeleteBlacklist();
            this.setState({ action: "" });
            this.setState({ successModalVisible: value });
        }
        if (this.state.action == "uplaod") {
            this.UploadFile();
            this.setState({ action: "" });
            this.setState({ successModalVisible: value });
        }

        if (this.state.action == "error") {
            this.setState({ action: "", errors: this.state.msg_errors });
        }


    }

    modalcountDown() {
        let secondsToGo = 2;
        const timer = setInterval(() => {
        }, 1000);
        setTimeout(() => {
            clearInterval(timer);
            this.setState({ successModalVisible: false });
        }, secondsToGo * 1000);


    }

    onSearch() {
        this.setState(state => {
            return {
                search: {
                    name: state.name,
                    dest: state.dest,
                    dateRange: state.dateRange
                }
            }
        });
        this.updateWindowDimensions();
    }

    setStateByProps(parameters, value) {
        value ? value : "";
        this.setState({ [parameters]: value });
    }

    handleCheckBlacklist() {
        if (!CheckTokenExp()) {
            let req = JSON.stringify({
                "BLK_DES": this.state.BLK_DES,
            });

            apiClient.post('api/Blacklist/CHECK_DESTINATION', req
                , {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-type': 'application/json; charset=UTF-8'
                    }
                })
                .then(data => {
                    this.setState(state => {
                        var json = data.data;
                        this.handleOnSaveBlacklist();
                        this.handleOk();
                    });

                    this.setState({ loading: false });
                })
                .catch(error => {

                    this.setState({ loading: false });
                });
        } else {
            window.location.href = "logout";
        }

    }

    handleOnSaveBlacklist() {
        if (!CheckTokenExp()) {
            this.setState({ loading: true })

            let req = JSON.stringify({
                BLK_PER: this.state.BLK_PER == 0 ? null : this.state.BLK_PER,
                BLK_DES: this.state.BLK_DES,
                BLK_DESC: this.state.BLK_DESC,
            });

            apiClient.post('api/Blacklist/INSERT_BLACKLIST', req
                , {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-type': 'application/json; charset=UTF-8'
                    }
                })
                .then(data => {
                    this.setState(state => {
                        var json = data.data;

                        this.setState({ visible: false, BLK_PER: undefined, BLK_DES: "", BLK_DESC: "" });
                        this.onSearch();
                    });

                    this.setState({ loading: false });
                })
                .catch(error => {

                    this.setState({ loading: false });
                });
        } else {
            window.location.href = "logout";
        }

    }

    handleOnDeleteBlacklist() {
        if (!CheckTokenExp()) {
            this.state.key.map((key) => {
                this.setState({ loading: true })
                apiClient.get('api/Blacklist/DELETE_BLACKLIST/' + key
                    , {
                        headers: {
                            'Authorization': `Bearer ${token}`,
                            'Content-type': 'application/json; charset=UTF-8'
                        }
                    })
                    .then(data => {
                        this.setState(state => {
                            var json = data.data;
                            this.onSearch();
                            this.setState({ BLK_PER: "" });
                        });

                        this.setState({ loading: false, key: [] });
                    })
                    .catch(error => {

                        this.setState({ loading: false });
                    });
            }
            )
        } else {
            window.location.href = "logout";
        }
        
    }

    ExportfileToExcel() {
        if (!CheckTokenExp()) {
            this.setState({ exportloading: true })
            let req = JSON.stringify({
                "START_DATE": this.state.dateRange[0] ? moment(this.state.dateRange[0]).format("DD/MM/YYYY") : null,
                "END_DATE": this.state.dateRange[1] ? moment(this.state.dateRange[1]).format("DD/MM/YYYY") : null,
                "SEARCH_BLACKLIST": this.state.name,
                "DEST": this.state.dest
            });

            apiClient.post('api/Blacklist/EXPORT/', req
                , {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-type': 'application/json; charset=UTF-8'
                    },
                    responseType: 'blob',
                })
                .then(data => {
                    var json = data.data;
                    this.setStateByProps("active", "");
                    const url = window.URL.createObjectURL(new Blob([json]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.download = "Blacklist.xlsx";
                    document.body.appendChild(link);
                    link.click();
                    link.remove();

                    this.setState({ exportloading: false });
                })
                .catch(error => {

                    this.setState({ exportloading: false });
                });
        } else {
            window.location.href = "logout";
        }
        
    }

    ExportfileToExcelTemplate() {
        if (!CheckTokenExp()) {
            this.setState({ exportloading: true })

            apiClient.get('api/Blacklist/EXPORT_TEMPLATE/'
                , {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-type': 'application/json; charset=UTF-8'
                    },
                    responseType: 'blob',
                })
                .then(data => {
                    var json = data.data;

                    const url = window.URL.createObjectURL(new Blob([json]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.download = "Blacklist_Template.xlsx";
                    document.body.appendChild(link);
                    link.click();
                    link.remove();

                    this.setState({ exportloading: false });
                })
                .catch(error => {

                    this.setState({ exportloading: false });
                });
        } else {
            window.location.href = "logout";
        }
    }

    showDrawerUpload() {
        this.setState({
            visibleUpload: true,
        });

    };

    onCloseUpload() {
        this.setState({
            visibleUpload: false,
        });
    };

    onUpload(info) {
        if (info.file.size <= 1048576) {
            const nextState = {};
            switch (info.file.status) {
                case "uploading":
                    if (getFileExtension(info.file.name) == "xlsx" || getFileExtension(info.file.name) == "xls") {
                        nextState.selectedFileList = [info.file];
                    } else {
                        this.onCloseUpload();
                        Modal.error({
                            title: 'The file format is incorrect.',
                            className: 'popup-error',
                            content: (
                                <div>
                                    <p>Check and upload the file again.</p>
                                </div>
                            ),
                            onOk() { },
                        });

                        nextState.selectedFile = null;
                        nextState.selectedFileList = [];
                    }
                    break;
                case "done":
                    if (getFileExtension(info.file.name) == "xlsx" || getFileExtension(info.file.name) == "xls") {
                        nextState.selectedFile = info.file;
                        nextState.selectedFileList = [info.file];
                    } else {
                        nextState.selectedFile = null;
                        nextState.selectedFileList = [];
                    }
                    break;
                default:
                    // error or removed
                    nextState.selectedFile = null;
                    nextState.selectedFileList = [];
            }
            this.setState(() => nextState);
        }
    };

    dummyRequest({ file, onSuccess }) {
        if (file.size <= 1048576) {
            var reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = function () {
                this.setState({ filename: file.name, result: reader.result.split(',')[1], filetype: file.type });
            }.bind(this);

            reader.onerror = function (error) {
                console.log('Error: ', error);
            };

            setTimeout(() => {
                onSuccess("ok");
            }, 0);
        }
        else {
            Modal.error({
                className: 'popup-error',
                title: 'Error',
                onOk: () => Modal.destroyAll(),
                content: "File size limit 1 MB",
                icon: <Icon type="exclamation-circle-o" style={{ fontSize: 36 }} />,
                zIndex: 1300
            });
        }
    };

    UploadFile() {
        if (!CheckTokenExp()) {
            this.setState({ loading: true, uploading: true })
            let req = JSON.stringify({
                "filebase64": this.state.result,
                "filetype": this.state.filetype
            });

            apiClient.post('api/Blacklist/IMPORT/', req
                , {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-type': 'application/json; charset=UTF-8'
                    }
                })
                .then(data => {
                    var json = data.data;

                    this.setState({
                        loading: false,
                        uploading: false,
                        visibleUpload: false,
                        selectedFileList: [],
                        SEARCH: "",
                        StartDate: null,
                        EndDate: null,
                        RangeDate: null,
                        current: 1,
                        minpage: 1,
                        pagesize: 50,
                    });
                    this.onSearch();
                    // if(json.insert == "0" && json.update == "0"){
                    //     Modal.info({
                    //         className: 'popup-question',
                    //         title: 'Infomation',
                    //         onOk: () => Modal.destroyAll(),
                    //         content:
                    //             <div>
                    //                 Duplicate data or invalid format
                    //             </div>,
                    //         icon: <Icon type="exclamation-circle-o" style={{ fontSize: 36 }} />,
                    //         zIndex: 1300
                    //     });
                    // }else{
                        Modal.success({
                            className: 'popup-success',
                            title: 'Success',
                            onOk: () => Modal.destroyAll(),
                            content:
                                <div>
                                    <p className="ant-margin-p">Insert {json.insert} records</p>
                                    <p className="ant-margin-p">Update {json.update} records</p>
                                </div>,
                            icon: <Icon type="exclamation-circle-o" style={{ fontSize: 36 }} />,
                            zIndex: 1300
                        });
                    // }
                    
                    this.setStateByProps("action", "");
                    this.setState({ loading: false });
                })
                .catch(error => {
                    this.setState({ loading: false });
                    Modal.error({
                        className: 'popup-error',
                        title: 'Error',
                        onOk: () => Modal.destroyAll(),
                        content: 'Import File Failed No of column incorrect',
                        icon: <Icon type="close-circle" style={{ fontSize: 36 }} />,
                        zIndex: 1300
                    });
                });
        } else {
            window.location.href = "logout";
        }
        
    }

    handleVisibleChange(value) {
        this.setState({ popoverhide: value });
    };

    render() {
        const { visible, search, dateRange, name, dest, popoverhide } = this.state;
        const { loading, message, messageSuccess, warningModalVisible, successModalVisible, key, screenwidth } = this.state;

        const menu = (
            <Menu>
                <Menu.Item style={{ borderBottom: '1px solid #7b7b7b' }} onClick={this.showModal}>
                    <span className="fa fa-plus-circle size14"></span> &nbsp;&nbsp;  Add
                </Menu.Item>
                <Menu.Item style={{ borderBottom: '1px solid #7b7b7b' }} onClick={this.ExportfileToExcel}>
                    <span className="fa fa-cloud-download size14"></span> &nbsp;&nbsp;  Export File
                </Menu.Item>
                <Menu.Item style={{ borderBottom: '1px solid #7b7b7b' }} onClick={() => { this.handleVisibleChange(false), this.showDrawerUpload() }}>
                    <span className="fa fa-cloud-upload size14"></span> &nbsp;&nbsp;  Import File
                </Menu.Item>
                <Menu.Item style={{ borderBottom: '1px solid #7b7b7b' }} onClick={this.ExportfileToExcelTemplate}>
                    <span className="fa fa-file-o size14"></span> &nbsp;&nbsp;  Download Template
                </Menu.Item>
            </Menu>
        );
        const routes = [
            {
                path: '/people/',
                breadcrumbName: 'People',
            },
            {
                breadcrumbName: 'Blacklist',
            },
        ];
        var styleinput_name = {
            width: (screenwidth / 3),
            marginRight: 8,
            marginBottom: 8,
            minWidth: 200
        };
        var styleinput_destination = {
            width: (screenwidth / 3),
            marginRight: 8,
            marginBottom: 8,
            minWidth: 200
        };
        var styleinput_date = {
            width: (screenwidth / 3),
            marginRight: 8,
            marginBottom: 8,
            minWidth: 200
        };
        const props = {
            showUploadList: {
                showDownloadIcon: false,
                showRemoveIcon: true,
            },
        };
        return (
            <Spin spinning={this.state.exportloading}>
                <div className="animated fadeIn">
                    <PageHeader breadcrumb={{ routes }} >
                    </PageHeader>

                    <div className="content" >
                        <Card style={{ minHeight: 200 }} >

                            <Row gutter={8} type="flex" justify="space-between">
                                <Col xs={12}>
                                    <Button key="submit" style={{ marginRight: 8 }}
                                        disabled={key.length > 0 ? false : true}
                                        onClick={() => {
                                            this.warningModal(true, "Do you want to delete this member from blacklist?", "Deleted blacklist successfully."),
                                                this.setStateByProps("action", "delete")
                                        }
                                        }>
                                        <span className="fa fa-trash-o"></span> &nbsp;&nbsp;
                                       Delete
                        </Button>
                                </Col>
                                <Col xs={12}>
                                    <Row gutter={16} type="flex" justify="end">
                                        <Col xs={24} sm={24} md={24} lg={24} xl={12}>
                                            <div style={{ float: "right" }}>
                                                <Popover placement="bottomRight"
                                                    visible={popoverhide}
                                                    onVisibleChange={(e) => this.handleVisibleChange(e)}
                                                    content={
                                                        menu
                                                    } trigger="click">
                                                    <Button> <span className="fa fa-list"></span> &nbsp; &nbsp; Action</Button>
                                                </Popover>
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>

                            <Row gutter={8} type="flex" justify="start" className="row-nowrap" id="main">
                                <Col xs={24} className="row-nowrap">
                                    <Input placeholder="Name" style={styleinput_name} value={name} onChange={(e) => this.setStateByProps("name", e.target.value)} />

                                    <Input placeholder="Destination" style={styleinput_destination} value={dest} onChange={(e) => this.setStateByProps("dest", e.target.value)} />

                                    <RangePicker
                                        placeholder={["Start Create Date", "End Create Date"]}
                                        style={styleinput_date}
                                        onChange={(value) => this.setStateByProps("dateRange", value)}
                                        value={dateRange}
                                        format={dateFormat} />

                                    <Button icon="search" onClick={this.onSearch}>
                                        Search
                                    </Button>
                                </Col>
                            </Row>

                            <Row gutter={8} type="flex" justify="space-between">
                                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                    <TableBacklist
                                        search={search}
                                        showModal={this.showModal}
                                        warningModal={this.warningModal}
                                        loading={loading}
                                        setStateByProps={this.setStateByProps}
                                        updateWindowDimensions={this.updateWindowDimensions}
                                    />
                                </Col>
                            </Row>
                        </Card>
                    </div >

                    <Drawer
                        className="boy-drawer"
                        style={{ zIndex: 1022 }}
                        title={<h3>Upload File</h3>}
                        placement={this.state.placementUpload}
                        height={'auto'}
                        closable={false}
                        onClose={this.onCloseUpload}
                        visible={this.state.visibleUpload}
                    >
                        <Row>
                            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                <Upload {...props}
                                    id="input"
                                    accept=".xlsx"
                                    fileList={this.state.selectedFileList}
                                    customRequest={this.dummyRequest}
                                    onChange={this.onUpload}
                                    style={{ width: "100%" }}
                                >
                                    <Button style={{ width: "100%" }}><Icon type="import" /> IMPORT FILE</Button>
                                </Upload>
                                <Button
                                    type="primary"
                                    loading={loading}
                                    onClick={this.UploadFile}
                                    style={{ marginTop: 16 }}
                                    disabled={this.state.selectedFileList.length == 0}
                                >
                                    {loading ? 'UPLOADING' : 'START UPLOAD'}
                                </Button>
                                <label class=" control-label require">File type * xlsx maximum upload file size total 1 MB</label>
                            </Col>
                        </Row>
                    </Drawer>


                    <ModalBlacklist visible={visible}
                        warningModal={this.warningModal}
                        handleOk={this.handleOk}
                        handleCancel={this.handleCancel}
                        setStateByProps={this.setStateByProps}
                        handleCheckBlacklist={this.handleCheckBlacklist}
                        BLK_PER={this.state.BLK_PER}
                        BLK_DES={this.state.BLK_DES}
                        BLK_DESC={this.state.BLK_DESC}
                        errors={this.state.errors}
                    />

                    <ModalWarning loading={loading}
                        warningModalVisible={warningModalVisible}
                        warningModal={this.warningModal}
                        successModal={this.successModal}
                        modalcountDown={this.modalcountDown}
                        message={message}
                        messageSuccess={messageSuccess}
                    />

                    <ModalSuccess loading={loading}
                        successModalVisible={successModalVisible}
                        successModal={this.successModal}
                        messageSuccess={messageSuccess}
                    />

                </div >
            </Spin>
        );
    }
}



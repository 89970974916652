/* eslint-disable */
import React, { Component } from 'react';
import {
    Form, Input, DatePicker, TimePicker, Select, Cascader, InputNumber, Icon, Row, Col,
    Button, Tooltip, Checkbox, Spin, PageHeader, Card, Menu, Dropdown, Modal
} from 'antd';
import moment from 'moment';
import 'moment-timezone';
import TableCardLog from '../../components/Wop/Table/TableCardLog';
import SelectAccount from "../../components/Wop/Select/SelectAccount";
import SelectCampaign from "../../components/Wop/Select/SelectCampaign";
import SelectSender from "../../components/Wop/Select/SelectSender";
import SelectUser from "../../components/Wop/Select/SelectUser";
import SelectStatus from "../../components/Wop/Select/SelectStatus";
import SelectSMSType from "../../components/Wop/Select/SelectSMSType";
import { getUrlVars,ReplaceUrlTaga } from '../../config';

moment.tz.setDefault("Asia/Bangkok");

const { MonthPicker, RangePicker, WeekPicker, } = DatePicker;
const dateFormat = 'DD/MM/YYYY';
const dateFormatTime = "DD/MM/YYYY HH:mm:ss";

export default class Log extends Component {
    constructor(props) {
        super(props);

        this.onSearch = this.onSearch.bind(this);
        this.setStateByProps = this.setStateByProps.bind(this);
        this.disabledDate = this.disabledDate.bind(this);
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);

        this.state = {
            warningModalVisible: false,
            successModalVisible: false,
            loading: false,
            message: "",
            messageSuccess: "",
            startrow: 1,
            endrow: 50,
            campaign: sessionStorage.getItem("campaignName") ? [sessionStorage.getItem("campaignName")] : [],
            status: [],
            mobile: [],
            sender: [],
            users: [],
            account: [],
            dateRange: sessionStorage.getItem("startDate") ? [moment(sessionStorage.getItem("startDate")), moment(sessionStorage.getItem("startDate")).add(48, 'hours')] : [moment().subtract(6, "hours"), moment()],
            smstype: [],
            search: {
                account: [],
                startrow: 1,
                endrow: 50,
                campaign: getUrlVars()["campaignid"] ? [parseInt(getUrlVars()["campaignid"])] : [],
                status: [],
                mobile: [],
                sender: [],
                users: [],
                smstype: [],
                dateRange: []
            },
            screenwidth: 0,
            screenwidth2: 0,
        };
    }

    componentDidMount() {
        ReplaceUrlTaga();
        this.updateWindowDimensions();
        window.addEventListener("resize", this.updateWindowDimensions.bind(this));

        if (sessionStorage.getItem("campaignId")) {
            this.onSearch();
        } else {
            sessionStorage.clear();
        }

    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.updateWindowDimensions.bind(this));
        sessionStorage.clear();
    }

    updateWindowDimensions() {
        setTimeout(() => {
            var root = document.getElementById("root");
            if (root) {
                var e = document.getElementById("main");
                if (e) {
                    var scroll = root.offsetHeight > window.innerHeight;
                    this.setState({ screenwidth: scroll ? e.offsetWidth - 32 : e.offsetWidth - 32 })
                    this.setState({ screenwidth2: scroll ? e.offsetWidth - 462 : e.offsetWidth - 462 })
                }
            }
        }, 500);
    }

    onSearch() {
        this.setState(state => {
            return {
                search: {
                    account: state.account,
                    startrow: state.startrow,
                    endrow: state.endrow,
                    campaign: sessionStorage.getItem("campaignId") ? [sessionStorage.getItem("campaignId")] : state.campaign,
                    status: state.status,
                    mobile: state.mobile,
                    sender: state.sender,
                    users: state.users,
                    smstype: state.smstype,
                    dateRange: state.dateRange[0] ? [moment(state.dateRange[0]).format(dateFormatTime), moment(state.dateRange[1]).format(dateFormatTime)] : [null, null],
                    history : this.props.history
                }
            }
        });
        this.updateWindowDimensions();
    }

    setStateByProps(parameters, value) {
        value ? value : "";
        this.setState({ [parameters]: value });

        if (parameters == "campaign") {
            const index = value.indexOf(sessionStorage.getItem("campaignName"));
            if (index > -1) {
                value.splice(index, 1);
                this.setState({ [parameters]: value });
                this.setState({ dateRange: [moment().subtract(6, "hours"), moment().add(24, 'hours')] });
            }

            sessionStorage.clear();
        }

        if (parameters == "account") {
            this.setState({ users: [] });
        }
    }

    disabledDate(current) {
        // Can not select days before today and today
        return current && current < moment().subtract(3, "days");
    }

    render() {
        const { account, campaign, dateRange, sender, users, status, mobile, search, screenwidth, screenwidth2, smstype,showBox } = this.state;
        const styleMarginBottom = { marginBottom: 16 };
        const routes = [
            {
                path: '/report/',
                breadcrumbName: 'Report',
            },
            {
                path: '/report/logs/',
                breadcrumbName: 'Logs',
            },
        ];
        var styleinput_account = {
            width: "100%",
            marginRight: 8,
            marginBottom: 8,
            minWidth: 180
        };
        var styleinput_campaign = {
            width: "100%",
            marginRight: 8,
            marginBottom: 8,
            minWidth: 180
        };
        var styleinput_sender = {
            width: "100%",
            marginRight: 8,
            marginBottom: 8,
            minWidth: 180
        };
        var styleinput_date = {
            width: "100%",
            marginRight: 8,
            marginBottom: 8,
            minWidth: 180
        };

        var styleinput_user = {
            width: "100%",
            marginRight: 8,
            marginBottom: 8,
            minWidth: 180
        };
        var styleinput_status = {
            width: "100%",
            marginRight: 8,
            marginBottom: 8,
            minWidth: 180
        };
        var styleinput_mobile = {
            width: "100%",
            marginRight: 8,
            marginBottom: 8,
            minWidth: 180
        };

        var styleinput_smstype = {
            width: "100%",
            marginRight: 8,
            marginBottom: 8,
            minWidth: 100
        };

        return (
            <div className="animated fadeIn">
                <PageHeader breadcrumb={{ routes }} >
                </PageHeader>

                <div className="content" >
                                <Card >
                                    <div className="ant-row">
                                        <div className="ant-col-md-6">
                                            <div className="control-label require">
                                                Campaign
                                            </div>
                                            <div className="control-input">
                                                <SelectCampaign select={campaign} setStateByProps={this.setStateByProps} style={styleinput_campaign} />
                                            </div>
                                                </div>
                                        <div className="ant-col-md-6">
                                            <div className="control-label require">
                                                Mobile
                                            </div>
                                            <div className="control-input">
                                                <Select
                                                    style={styleinput_mobile}
                                                    value={mobile}
                                                    mode="tags"
                                                    onChange={(e) => this.setStateByProps("mobile", e)}
                                                    placeholder="Select a mobile"
                                                    tokenSeparators={[',', ' ']}
                                                    open={false}
                                                >
                                                </Select>


                                            </div>
                                                </div>
                                        <div className="ant-col-md-6">
                                            <div className="control-label require">
                                                Sender
                                            </div>
                                            <div className="control-input">
                                                <SelectSender mode={"multiple"} select={sender} placeholder="Select sender" setStateByProps={this.setStateByProps} style={styleinput_sender} />
                                            </div>
                                                </div>
                                        <div className="ant-col-md-6">
                                            <div className="control-label require">
                                                Date Range
                                                <span style={{ color: 'red' }}>* </span>
                                            </div>
                                            <div className="control-input">
                                                <RangePicker
                                                    placeholder={["Start Date", "End Date"]}
                                                    style={styleinput_date}
                                                    ranges={{
                                                        Today: [moment().startOf('day'), moment().endOf('day')],
                                                        'Last 48 Hrs': [moment().subtract(48, "hours"), moment()],
                                                    }}
                                                    showTime={{
                                                        hideDisabledOptions: true,
                                                        defaultValue: [moment('00:00:00', 'HH:mm:ss'), moment('23:59:59', 'HH:mm:ss')],
                                                    }}
                                                    onChange={(value) => this.setStateByProps("dateRange", value)}
                                                    value={dateRange}
                                                    format={dateFormatTime} />
                                            </div>
                                                </div>
                                    </div>
                                    <div className="ant-row">
                                        <div className="ant-col-md-6">
                                            <div className="control-label require">
                                                Account
                                            </div>
                                            <div className="control-input">
                                                <SelectAccount select={account} setStateByProps={this.setStateByProps} style={styleinput_account} />
                                            </div>
                                                </div>
                                        <div className="ant-col-md-6">
                                            <div className="control-label require">
                                                User
                                            </div>
                                            <div className="control-input">
                                                <SelectUser select={users} accounts={account} setStateByProps={this.setStateByProps} style={styleinput_user} />
                                            </div>
                                                </div>
                                        <div className="ant-col-md-6">
                                            <div className="control-label require">
                                                Status
                                            </div>
                                            <div className="control-input">

                                                <SelectStatus mode={"multiple"} select={status} setStateByProps={this.setStateByProps} style={styleinput_status} />

                                            </div>
                                                </div>
                                        <div className="ant-col-md-3">
                                            <div className="control-label require">
                                                SMS Type
                                            </div>
                                            <div className="control-input">
                                                        <SelectSMSType select={smstype} setStateByProps={this.setStateByProps} style={styleinput_smstype} />
                                            </div>
                                        </div> 
                            <div className="ant-col-md-3">
                                <div className="control-label require" style={{color:"#ffffff"}}>
                                    .
                                            </div>
                                                    <div className="control-input">
                                                            <Button style={{ width: "100%" }} icon="search" onClick={this.onSearch} disabled={dateRange[0] ? false : true}>
                                                                Search
                                                           </Button>
                                                    </div>
                                        </div>
                                    </div>
                                </Card>
                             <TableCardLog search={search} setStateByProps={this.setStateByProps} updateWindowDimensions={this.updateWindowDimensions} />
                </div>

            </div >
        );
    }
}



/* eslint-disable */
import React, { Component } from "react";
import { Link } from 'react-router-dom';
import { Form, Input, DatePicker, TimePicker, Select, Cascader, InputNumber, Icon, Row, Col, Button, Tooltip, Checkbox, Spin, PageHeader, Card } from 'antd';
import { token, CheckTokenExp,ReplaceUrlTaga } from '../../../config';

import InputTo from '../../../components/Wop/Input/InputTo';
import SelectSender from '../../../components/Wop/Select/SelectSender';
import SelectParameters from '../../../components/Wop/Select/SelectParameters';
import ButtonUpload from '../../../components/Wop/Button/ButtonUpload';
import MultipleTrigger from '../../../components/Wop/Mentions/MultipleTrigger';
import { templateCreateText, senderText } from '../../../components/Wop/TooltipText/Text';
import ModalSuccess from '../../../components/Wop/Modal/ModalSuccess';
import ModalWarning from '../../../components/Wop/Modal/ModalWarning';
import { apiClient } from '../../../api';
import{root} from '../../../config';
import ModalServerError from "../../../components/Wop/Modal/ModalServerError";

const { Option } = Select;

const dateFormat = 'DD/MM/YYYY';
const timeFormat = 'HH:mm';

export default class New extends Component {
    constructor(props) {
        super(props);
        this.indexcontent = this.indexcontent.bind(this);
        this.countMessage = this.countMessage.bind(this);
        this.uploadfile_showDrawer = this.uploadfile_showDrawer.bind(this);
        this.uploadfile_onClose = this.uploadfile_onClose.bind(this);
        this.handleChangevariable = this.handleChangevariable.bind(this);
        this.warningModal = this.warningModal.bind(this);
        this.successModal = this.successModal.bind(this);
        this.modalcountDown = this.modalcountDown.bind(this);
        this.getData = this.getData.bind(this);
        this.setStateByProps = this.setStateByProps.bind(this);
        this.handlesave = this.handlesave.bind(this);
        //this.handleUpload = this.handleUpload.bind(this);
        this.handleColumnsMulti = this.handleColumnsMulti.bind(this);


        this.state = {
            id: 0,
            indexcontent: 0,
            content: "",
            content_old: "",
            MessageCount: 1,
            CharacterCount: 160,
            variable: ["firstName", "lastName", "address",
                "city", "country", "gender", "birthDate"],
            uploadfile_visible: false,
            clearstate: false,
            warningModalVisible: false,
            successModalVisible: false,
            loading: false,
            message: "",
            messageSuccess: "",
            templatename: "",
            sender: undefined,
            cb_shortedurl: false,
            to: [],
            fileList: [],
            file_size: 0,
            page: "",
            users: [],
            modalVisible: false,
        };
    }

    componentDidMount() {
        var params = {};
        if (this.props.location.search) {
            var parts = this.props.location.search.substring(1).split('&');
            for (var i = 0; i < parts.length; i++) {
                var nv = parts[i].split('=');
                if (!nv[0]) continue;
                params[nv[0]] = nv[1] || true;
            }
            this.setState({ id: params.id });
            params.id ? this.getData(params.id) : null;
        }
        ReplaceUrlTaga();
    }

    indexcontent(e) {
        var start = this.state.content.substring(0, e.target.selectionEnd);
        var c = (start.split("{").length - 1) * 2;
        this.setState({ indexcontent: e.target.selectionEnd + c });
    }

    countMessage(content) {
        //1 Message / Used with 5 remaining 165
        var contentlength = content == undefined ? 0 : content.length;
        var msgCount = 0;
        var messremaining = 160;
        if (contentlength) {
            if (!content.match(/^([a-zA-Z0-9\|!#$%&/()*^=?»«@£§€{}.-;'"<>_[\]\\,\-\+ \n\r])+$/i)) {
                msgCount = contentlength <= 70 ? 1 : contentlength <= 134 ? 2 : parseInt(Math.ceil(parseFloat(contentlength) / 67));
                messremaining = contentlength <= 70 ? 70 - contentlength : contentlength <= 134 ? 134 - contentlength : (134 + (67 * (msgCount - 2))) - contentlength;
            } else {
                msgCount = contentlength <= 160 ? 1 : contentlength <= 306 ? 2 : parseInt(Math.ceil(parseFloat(contentlength) / 153));
                messremaining = contentlength <= 160 ? 160 - contentlength : contentlength <= 306 ? 306 - contentlength : (306 + (153 * (msgCount - 2))) - contentlength;
            }
        }
        this.setState({
            content: content,
            content_require: false,
        });
        this.setState({ MessageCount: msgCount });
        this.setState({ contentlength: contentlength });
        this.setState({ CharacterCount: messremaining });

    }

    uploadfile_showDrawer() {
        this.setState({
            uploadfile_visible: true,
        });
    }

    uploadfile_onClose() {
        this.setState({
            uploadfile_visible: false,
        });

    }

    handleChangevariable(e) {
        var start = this.state.content.substring(0, this.state.indexcontent);
        var end = this.state.content.substring(this.state.indexcontent);
        this.setState({ content: start + "{" + e + "}" + end },
            function () {
                this.countMessage(this.state.content);
                this.setState({ content_old: this.state.content });
                //this.shortedurl(this.state.cb_shortedurl);
                this.setState({ indexcontent: this.state.indexcontent + e.length + 2 });
            }.bind(this));
    }

    warningModal(value, message, messageSuccess) {
        this.setState({ warningModalVisible: value, message, messageSuccess });
    }

    successModal(value) {
        if (this.state.page == "confirm") {
            this.handlesave()
            this.setState({ page: "" });
        }
        if (this.state.page == "cancel") {
            this.setState({ page: "" });
            value = false;
            this.props.history.push('/communication/template/list/')

        }
        this.setState({ successModalVisible: value });
    }

    modalcountDown() {
        this.setState({ clearstate: true });
        let secondsToGo = 2;
        this.setState(state => {
            if (state.successModalVisible) {
                setTimeout(() => {
                    this.setState({ successModalVisible: false });
                    this.props.history.push('/communication/template/list/')
                }, secondsToGo * 1000);
            }
        });
    }

    getData(value) {
        if (!CheckTokenExp()) {
            this.setState({ loading: true });

            let req = JSON.stringify({
                id: value,
                startdata: 1,
                enddata: 1,
                users: [],
                date: [null, null],

            });

            apiClient.post("api/template/get", req,
                {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-type': 'application/json; charset=UTF-8'
                    }
                })
                .then(data => {
                    var data = data.data.data[0];

                    data.fileoid ? this.handleColumnsMulti(data.fileoid) : null;

                    this.setState({
                        key: data.key,
                        templatename: data.name,
                        sender: data.sender == 0 ? undefined : data.sender,
                        content: data.content,
                        content_old: data.content,
                        fileoid: data.fileoid,
                        filename: data.filename,
                        cb_shortedurl: data.shortedurl,
                        fileList: data.fileoid ? [{
                            uid: data.key,
                            name: data.filename,
                            status: 'done',
                            url: data.fileoid,
                            type: "oldfile",
                        }] : [],
                        indexcontent: data.content.length,
                    }), this.countMessage(data.content);


                    this.setState({ loading: false });
                })
                .catch(error => {
                    this.setState({ loading: false });
                });
        } else {
            window.location.href = "logout";
        }
        
    }

    handlesave() {
        if (!CheckTokenExp()) {
            this.setState({ loading: true });

            const { fileList } = this.state;
            const formData = new FormData();
            fileList.forEach(file => {
                formData.append("files[]", file);
            });

            let req = JSON.stringify({
                key: this.state.id,
                templatename: this.state.templatename,
                contents: this.state.content,
                fileoid: this.state.fileList.length > 0 ? this.state.fileoid : null,
                filename: this.state.fileList.length > 0 ? this.state.filename : null,
                sender: this.state.sender,
                shortedurl: this.state.cb_shortedurl,
            });

            formData.append('req', req);

            apiClient.post("api/template/addupdate", formData,
                {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-type': 'application/json; charset=UTF-8'
                    }
                })
                .then(data => {
                    // var data = data.data.data[0];
                    this.setState({ loading: false });
                })
                .catch(error => {
                    this.setState({ modalVisible: true });
                    this.setState({ loading: false });
                });
        } else {
            window.location.href = "logout";
        }
    }

    setStateByProps(parameters, value) {
        this.setState({ [parameters]: value });
    }

    handleColumnsMulti(value) {
        if (!CheckTokenExp()) {
            this.setState({ loading: true });

            apiClient.get('api/GetColumns/ecm/' + value
                , {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-type': 'application/json; charset=UTF-8',
                    }
                })
                .then(data => {
                    var json = data.data;
                    let variable = [...new Set(this.state.variable)];
                    this.setState({ variable });
                    const arr = json.columns.filter((item) => !variable.includes(item));
                    this.setState(state => ({
                        variable: [...arr],
                        sp_loading: false,
                        file_size: state.file_size + json.size,
                    }));

                    this.setState({
                        uploading: false
                    });
                })
                .catch(error => {
                    this.setState({
                        uploading: false
                    });

                });
        } else {
            window.location.href = "logout";
        }
    }

    render() {
        const marginbottomrow = { marginBottom: 16 };
        const { uploadfile_visible, loading, warningModalVisible, message, 
            messageSuccess, successModalVisible, clearstate, modalVisible } = this.state;
        const { templatename, sender, cb_shortedurl, variable, to, id, fileList, file_size, content } = this.state;
        const routes = [
            {
                path: 'communication',
                breadcrumbName: 'Communication',
            },
            {
                path: 'template/list',
                breadcrumbName: 'Template',
            },
            {
                breadcrumbName: id > 0 ? "Edit" : "NEW",
            },
        ];
        return (
            <div className="animated fadeIn">
                <PageHeader breadcrumb={{ routes }}>
                </PageHeader>


                <div className="content">
                    <Card>

                        <Spin spinning={clearstate}>
                            <Row gutter={16} style={marginbottomrow}>
                                <Col xs={24} sm={24} md={24} lg={24} xl={1}>
                                    &nbsp;
                            </Col>
                                <Col xs={24} sm={24} md={24} lg={24} xl={11}>
                                    <Row gutter={16}>
                                        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                            <label className=" control-label require" style={{ fontWeight: 700 }}>Template Name
                                </label>
                                            <label className="red">*</label>
                                            &nbsp; &nbsp;
                                <Tooltip placement="rightTop" title={templateCreateText}>
                                                <span className="fa fa-info-circle wop-gray-low size14"></span>
                                            </Tooltip>

                                            <Input placeholder="Enter your template name here" value={templatename}
                                                name="to" onChange={(e) => this.setStateByProps("templatename", e.target.value)} />
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>

                            <Row gutter={16} style={marginbottomrow}>
                                <Col xs={24} sm={24} md={24} lg={24} xl={1}>
                                    &nbsp;
                            </Col>
                                <Col xs={24} sm={24} md={24} lg={24} xl={11}>
                                    <label className=" control-label require" style={{ fontWeight: 700 }}>Sender Name
                                </label>
                                    <label className="red">*</label>
                                    &nbsp; &nbsp;
                                <Tooltip placement="rightTop" title={senderText}>
                                        <span className="fa fa-info-circle wop-gray-low size14"></span>
                                    </Tooltip>
                                    <SelectSender select={sender} setStateByProps={this.setStateByProps} />
                                </Col>
                            </Row>

                            <Row gutter={24}>
                                <Col xs={24} sm={24} md={24} lg={24} xl={1}>
                                    &nbsp;
                                </Col>
                                <Col xs={24} sm={24} md={24} lg={24} xl={11}>
                                    <label className=" control-label require" style={{ fontWeight: 700 }}>Content </label>
                                    <label className="red">*</label>
                                    <div style={{ float: "right", }}>
                                        <label style={{ color: '#bfbfbf' }}>  {this.state.MessageCount}Message, {this.state.content.length}/{this.state.CharacterCount} characters</label>
                                    </div>
                                    <MultipleTrigger
                                        placeholder={"Enter your message or upload file here."}
                                        data={this.state.variable} contents={this.state.content}
                                        onmouseup={this.indexcontent}
                                        useonchange={true}
                                        onchange={(contents) => {
                                            this.setState({
                                                content: contents,
                                                content_old: contents,
                                                indexcontent: this.state.indexcontent + 1
                                            }), this.countMessage(contents)
                                        }} />
                                </Col>
                                <Col xs={24} sm={24} md={24} lg={24} xl={12}>
                                    <Row gutter={24}>
                                        <Col xs={24} sm={24} md={24} lg={24} xl={7}>

                                            <label className=" control-label require" style={{ fontWeight: 700 }}> &nbsp; &nbsp;</label>
                                            <ButtonUpload uploadfile_visible={uploadfile_visible}
                                                uploadfile_showDrawer={this.uploadfile_showDrawer}
                                                uploadfile_onClose={this.uploadfile_onClose}
                                                setStateByProps={this.setStateByProps}
                                                to={to}
                                                fileList={fileList}
                                                file_size={file_size}
                                                filemax={1}
                                            />

                                        </Col>

                                    </Row>

                                    <Row gutter={24} style={marginbottomrow}>
                                        <Col xs={24} sm={24} md={24} lg={24} xl={7} xxl={4}>

                                            <SelectParameters
                                                setStateByProps={this.setStateByProps}
                                                handleChangevariable={this.handleChangevariable}
                                                list={variable}
                                            />

                                            <Checkbox name="shortenURL" checked={cb_shortedurl} onChange={e => this.setStateByProps("cb_shortedurl", e.target.checked)} style={{ marginTop: 25, fontWeight: 700 }}>Shorten URL</Checkbox>

                                        </Col>
                                    </Row>

                                </Col>
                            </Row>

                            <Row gutter={16} style={marginbottomrow}>
                                <Col xs={24} sm={24} md={24} lg={24} xl={1}>
                                    &nbsp;
                                </Col>
                                <Col xs={24} sm={24} md={24} lg={24} xl={11} style={{ textAlign: "right" }}>
                                    <Button style={{ marginRight: 8 }}
                                        onClick={() => { this.setState({ page: "cancel" }), this.warningModal(true, "Do you want to cancel?", "Canceled successfully.") }} >
                                        <span className="fa fa-trash-o"></span> &nbsp;&nbsp;
                                               Discard
                                             </Button>

                                    <Button type="primary"
                                        disabled={templatename && sender && content.trim() ? false : true}
                                        onClick={() => { this.setState({ page: "confirm" }), this.warningModal(true, "Do you want to save this template?", "Saved template successfully.") }}>
                                        <span className="fa fa-plus-circle"></span> &nbsp;&nbsp;
                                               Save
                                            </Button>
                                </Col>
                            </Row>
                        </Spin>

                    </Card>
                </div>

                <ModalWarning loading={loading}
                    warningModalVisible={warningModalVisible}
                    warningModal={this.warningModal}
                    successModal={this.successModal}
                    modalcountDown={this.modalcountDown}
                    message={message}
                    messageSuccess={messageSuccess}
                />

                <ModalSuccess loading={loading}
                    successModalVisible={successModalVisible}
                    successModal={this.successModal}
                    messageSuccess={messageSuccess}
                />

                <ModalServerError modalVisible={modalVisible} />
            </div>
        );
    }
}


/* eslint-disable */
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Table, Divider, Tag, Icon, Button, Row, Col, Popover, Menu, Radio } from 'antd';
import { token, colorGreen, colorRed, CheckTokenExp } from '../../../config';
import moment from 'moment';
import 'moment-timezone';

import ModalSuccess from '../../../components/Wop/Modal/ModalSuccess';
import ModalWarning from '../../../components/Wop/Modal/ModalWarning';
import { apiClient } from '../../../api';
import { root } from '../../../config';

moment.tz.setDefault("Asia/Bangkok");

const dateFormat = 'DD/MM/YYYY';
const dateFormatDateTime = 'DD/MM/YYYY HH:mm';

export default class TableSubAccSender extends Component {

    constructor(props) {
        super(props);

        this.state = {
            columns: [
                {
                    title: 'Sender Name',
                    dataIndex: 'name',
                    key: 'name',
                    width: 200,
                    render: text => (<div className="table-title" title={text}>{text}</div>)
                },
                {
                    title: 'Create Date',
                    dataIndex: 'createdate',
                    key: 'createdate',
                    width: 200,
                    render: text => (<div className="table-title" title={text}>{text}</div>)
                },
                {
                    title: 'Status',
                    dataIndex: 'status',
                    key: 'status',
                    width: 130,
                    render: (text, item) => {
                        const colorEn = item.status == false ? colorGreen : "";
                        const colorDis = item.status == true ? colorRed : "";
                        return (
                            <Radio.Group value={item.status}
                                onChange={() => { this.setStateByProps('sendId', item.key), this.setStateByProps('status', item.status), this.warningModal(true, "Do you want to update status?", "Update status successfully."), this.setStateByProps('action', 'updatestatus') }}
                                buttonStyle="solid" size="small">
                                <Radio.Button value={true} style={{ backgroundColor: colorDis, borderColor: colorDis }} >Disabled</Radio.Button>
                                <Radio.Button value={false} style={{ backgroundColor: colorEn, borderColor: colorEn }} >Enabled</Radio.Button>
                            </Radio.Group>
                        )
                    }
                },
            ],
            pagesize: 10,
            currentpage: 1,
            startrow: 1,
            endrow: 10,
            totalpage: 0,
            clickId: null,
            owner: false,
            admin: false,
            createuser: false,
            warningModalVisible: false,
            successModalVisible: false,
            loading: false,
            message: "",
            messageSuccess: "",
        };

        this.warningModal = this.warningModal.bind(this);
        this.successModal = this.successModal.bind(this);
        this.modalcountDown = this.modalcountDown.bind(this);
        this.getData = this.getData.bind(this);
        this.onChangePage = this.onChangePage.bind(this);
        this.setStateByProps = this.setStateByProps.bind(this);

    }


    componentDidUpdate(prevProps) {
        if (this.props.search !== prevProps.search) {
            this.getData();
        }
        if (this.props.owner !== prevProps.owner) {
            this.setState({ owner: this.props.owner });
        }
        if (this.props.admin !== prevProps.admin) {
            this.setState({ admin: this.props.admin });
        }
        if (this.props.createuser !== prevProps.createuser) {
            this.setState({ createuser: this.props.createuser });
        }
        if (this.props.addsender !== prevProps.addsender) {
            this.props.setStateByProps("addsender", false)
            this.getData();
        }
        if (this.props.accdetailId !== prevProps.accdetailId) {
            this.getData();
        }
    }

    componentDidMount() {
        this.getData();
    }

    warningModal(value, message, messageSuccess) {
        this.setState({ warningModalVisible: value, message, messageSuccess });
    }

    successModal(value) {
        if (this.state.action == "updatestatus") {
            this.setState({ action: "" });
            this.updateStatus();
        }

        this.setState({ successModalVisible: value });
    }

    modalcountDown() {
        let secondsToGo = 2;
        const timer = setInterval(() => {
        }, 1000);
        setTimeout(() => {
            clearInterval(timer);
            this.setState({ successModalVisible: false });
        }, secondsToGo * 1000);


    }

    getData() {
        if (!CheckTokenExp()) {
            this.setState({ loading: true });

            this.setState(state => {
                const { search } = this.props;

                let req = JSON.stringify({
                    "id": this.props.accdetailId.toString(),
                    "search": search.name,
                    "startdata": state.startrow,
                    "enddata": state.endrow
                });

                apiClient.post('api/template/sendersubacc/', req
                    , {
                        headers: {
                            'Authorization': `Bearer ${token}`,
                            'Content-type': 'application/json; charset=UTF-8'
                        }
                    })
                    .then(data => {
                        var json = data.data;
                        this.setState(state => {
                            return {
                                data: json ? json.data : [],
                                totalpage: json ? json.totalpage : 0,
                                currentpage: state.minpage == 1 ? 1 : state.currentpage,
                                minpage: 1
                            };
                        }, () => { this.props.updateWindowDimensions() });

                        this.setState({ loading: false });
                    })
                    .catch(error => {
                        this.setState({
                            data: [],
                            totalpage: 0,
                            currentpage: 1,
                            minpage: 1
                        });
                        this.setState({ loading: false });
                    });
            });
        } else {
            window.location.href = "logout";
        }

    }

    updateStatus() {
        if (!CheckTokenExp()) {
            this.setState({ loading: true });

            this.setState(state => {

                let req = JSON.stringify({
                    "sendId": this.state.sendId,
                    "status": !this.state.status,
                });

                apiClient.post('api/template/updatesender/', req
                    , {
                        headers: {
                            'Authorization': `Bearer ${token}`,
                            'Content-type': 'application/json; charset=UTF-8'
                        }
                    })
                    .then(data => {
                        this.setState({ loading: false });
                        this.getData();
                    })
                    .catch(error => {
                        this.setState({ loading: false });
                    });
            });
        } else {
            window.location.href = "logout";
        }

    }

    setStateByProps(parameters, value) {
        value ? value : "";

        if (parameters == "username") {
            if (value.match(/^[A-Za-z0-9 /./+/*/@/\\_-]+$/i) || value == "") {
                this.setState({ [parameters]: value });
            }
        } else {
            this.setState({ [parameters]: value });

            if (parameters == "email") {
                this.setState({ emailError: validateEmail(value) });
            } else if (parameters == "uemail") {
                this.setState({ uemailError: validateEmail(value) });
            }
        }
        this.setState({ errorMessage: "" });
    }

    onChangePage(currentPage, pageSize) {
        var endrow = currentPage * pageSize;
        var startrow = endrow - pageSize + 1;

        this.setState(state => {
            return {
                loading: true,
                currentpage: state.pagesize == pageSize ? currentPage : 1,
                pagesize: pageSize,
                startrow: startrow,
                endrow: endrow,
                minpage: startrow,
            };
        });
        this.getData();

    }

    render() {
        const { loading, message, messageSuccess, warningModalVisible, successModalVisible, columns, data, totalpage } = this.state;
        const { setStateByProps, footer, templateItem, addsender, accdetailId } = this.props;
        return (
            <div>
                <Table
                    style={{ cursor: 'pointer' }}
                    loading={loading}
                    pagination={{
                        defaultPageSize: "10",
                        pageSizeOptions: ['10', '20', '50', '100'],
                        showSizeChanger: true,
                        total: totalpage,
                        showTotal: (total, range) => `${range[0]}-${range[1]} of ${totalpage} items`,
                        onShowSizeChange: this.onChangePage,
                        onChange: this.onChangePage,
                        current: this.state.currentpage
                    }
                    }
                    columns={columns}
                    dataSource={data}
                    className="table-wop"
                    footer={() => footer}
                    tableLayout="fixed"
                />

                <ModalWarning loading={loading}
                    warningModalVisible={warningModalVisible}
                    warningModal={this.warningModal}
                    successModal={this.successModal}
                    modalcountDown={this.modalcountDown}
                    message={message}
                    messageSuccess={messageSuccess}
                />

                <ModalSuccess loading={loading}
                    successModalVisible={successModalVisible}
                    successModal={this.successModal}
                    messageSuccess={messageSuccess}
                />
            </div>

        );
    }
}

/* eslint-disable */
import React, { Component } from 'react';
import { Link } from "react-router-dom";
import {
    Form, Input, DatePicker, TimePicker, Select, Cascader, InputNumber, Icon, Row, Col, Image,
    Button, Tooltip, Checkbox, Spin, PageHeader, Card, Menu, Dropdown, Modal, Collapse, Radio, Switch, Divider
} from 'antd';

import { apiClient } from '../../../api';
import { root } from '../../../config';
import moment from 'moment';
import 'moment-timezone';
import { token, getUrlVars, validateEmail, CheckTokenExp } from '../../../config';

import SelectCampaign from "../../../components/Wop/Select/SelectCampaign_Reportpage";
import SelectUser from "../../../components/Wop/Select/SelectUser";
import SelectAccount from "../../../components/Wop/Select/SelectAccount";
import SelectStatus from "../../../components/Wop/Select/SelectStatus";
import SelectSender from "../../../components/Wop/Select/SelectSender_Reportpage";
import ModalServerError from '../../../components/Wop/Modal/ModalServerError';

moment.tz.setDefault("Asia/Bangkok");

const { RangePicker, } = DatePicker;
const dateFormat = 'DD/MM/YYYY';
const timeFormat = 'HH:mm';

const { Panel } = Collapse;
const Option = Select.Option;

const now = new Date(Date.now());

const Day = new Date();
const tootipreportname = "File names cannot contain the following characters < > : " + " / \ | ? * ";

const todaystart = new Date(now.setHours(0, 0, 0, 0));
const todayend = new Date(now.setHours(23, 59, 59, 99));

const yesterdaystart = new Date(todaystart - 3600 * 24000);
const yesterdayend = new Date(todayend - 3600 * 24000);

const lastweekstart = new Date(now.setDate((now.getDate() - now.getDay() - 7), now.setHours(0, 0, 0, 0)));
const lastweekdayend = new Date(now.setDate((now.getDate() - now.getDay() + 6), now.setHours(23, 59, 59, 99)));

const lastmouthstart = new Date(Day.getFullYear(), (parseInt(Day.getMonth()) - 1), 1, 0, 0, 0, 0);
const lastmouthend = new Date(Day.getFullYear(), (parseInt(Day.getMonth())), 0, 23, 59, 59, 99);

const thismouthstart = new Date(Day.getFullYear(), (parseInt(Day.getMonth())), 1, 0, 0, 0, 0);
const thismouthend = new Date(Day.getFullYear(), (parseInt(Day.getMonth()) + 1), 0, 23, 59, 59, 99);

// function disabledDate(current) {
//     // Can not select days before today and today
//     return current && (current < moment().subtract(1, "y") || current > moment());
// }

export default class
    extends Component {
    constructor(props) {
        super(props);


        this.state = {
            disableCreateButton: false,

            checkemail: true,
            checkftp: true,

            alert_flag: false,
            ftp_flag: false,

            ftp_list: [],
            modalVisible: false,

        };
        this.setStateByProps = this.setStateByProps.bind(this);
        this.covertformatdate = this.covertformatdate.bind(this);
        this.getDisabledStartHours = this.getDisabledStartHours.bind(this);
        this.getDisabledStartMinutes = this.getDisabledStartMinutes.bind(this);
        this.getDisabledEndHours = this.getDisabledEndHours.bind(this);
        this.getDisabledEndMinutes = this.getDisabledEndMinutes.bind(this);
        this.disabledStartDate = this.disabledStartDate.bind(this);
        this.disabledEndDate = this.disabledEndDate.bind(this);
        this.cleardata = this.cleardata.bind(this);
        this.cleardataftp = this.cleardataftp.bind(this);
        this.testftp = this.testftp.bind(this);
        this.handleShowPassword = this.handleShowPassword.bind(this);

    }

    componentDidMount() {

        this.covertformatdate(yesterdaystart, yesterdayend);
        this.getFtpList();
        this.setStateByProps("report_Template", 4);
        this.setStateByProps("ftp_teststatus", 0);
        this.setStateByProps("onetimereport_type", 4);
        this.cleardata();


    }

    covertformatdate(start, end) {
        return this.props.covertformatdate(start, end);
    }


    setStateByProps(parameters, value) {
        this.props.setStateByProps(parameters, value);
    }

    setreportrange(typereport, valuereport) {
        return this.props.setreportrange(typereport, valuereport);
    }

    getFtpList() {
        if (!CheckTokenExp()) {
            this.state.ftp_list.length > 0 ? "" : this.setState({ loading: true });
            let req = JSON.stringify({

            });
            apiClient.post('api/report/FtpList', req
                , {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-type': 'application/json; charset=UTF-8'
                    }
                })
                .then(data => {
                    if (data.status === 200 && data.data) {
                        this.setState({ ftp_list: data.data });
                    } else {
                        this.setState({ modalVisible: true });
                        this.setState({ ftp_list: [] });
                    }
                    this.setState({ loading: false });
                })
                .catch(error => {
                    this.setState({ ftp_list: [] });
                    this.setState({ loading: false });
                });
        } else {
            window.location.href = "logout";
        }
    }
    getDataFtp(id) {
        if (!CheckTokenExp()) {
            this.state.ftp_list.length > 0 ? "" : this.setState({ loading: true });

            apiClient.get('api/report/GetFtpdataformid/' + id
                , {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-type': 'application/json; charset=UTF-8'
                    }
                })
                .then(data => {
                    var json = data.data;

                    this.setStateByProps("ftp_protocal", json.FTP_PROTOCAL)
                    this.setStateByProps("ftp_host", json.FTP_HOSTNAME)
                    this.setStateByProps("ftp_port", json.FTP_PORT)
                    this.setStateByProps("ftp_username", json.FTP_USERNAME)
                    this.setStateByProps("ftp_password", json.FTP_PASSWORD)
                    this.setStateByProps("ftp_path", json.FTP_PATH)

                    this.setState({ loading: false });
                })
                .catch(error => {
                    this.setState({ loading: false });
                });
        } else {
            window.location.href = "logout";
        }
    }


    getDisabledStartHours() {
        const startdate = this.props.startdate;
        var hours = [];
        var currentDate = moment().format("YYYY-MM-DD")
        var startDate_ = startdate ? moment(startdate, "YYYY/MM/DD").format("YYYY-MM-DD") : null;
        if(moment(startDate_).isSame(currentDate)){
            for (var i = 0; i < 24; i++) {
                if (i > moment().hour()) {
                    hours.push(i);
                }
            }
        }
        return hours;
        // return this.props.getDisabledStartHours();
    }

    getDisabledStartMinutes() {
        const { startdate, timestart } = this.props;
        var minute = [];
        if (!timestart) {
            for (var i = 0; i < 60; i++) {
                minute.push(i);
            }
        }
        var current = moment().format("YYYY-MM-DD")
        var startDate_ = startdate ? moment(startdate, "YYYY/MM/DD").format("YYYY-MM-DD") : null;
        if (moment(startDate_).isSame(current) && moment(timestart, "HH:mm:00").hour() === moment().hour()) {
            for (var i = 0; i < 60; i++) {
                if (i > moment().minute()) {
                    minute.push(i);
                }
            }
        }
        return minute;
        // return this.props.getDisabledStartMinutes();
    }

    getDisabledEndHours() {
        const { startdate, enddate, timestart } = this.props;
        var hours = [];
        var currentDate = moment().format("YYYY-MM-DD")
        var startDate_ = startdate ? moment(startdate, "YYYY/MM/DD").format("YYYY-MM-DD") : null;
        var endDate_ = enddate ? moment(enddate, "YYYY/MM/DD").format("YYYY-MM-DD") : null;

        if(moment(endDate_).isSame(currentDate)){
            for (var i = 0; i < 24; i++) {
                if (i > moment().hour()) {
                    hours.push(i);
                }
                if (i < moment(timestart, "HH:mm:00").hour() && moment(startDate_).isSame(endDate_)) {
                    hours.push(i);
                }
            }
        }
        if (moment(startDate_).isSame(endDate_)) {
            for (var i = 0; i < 24; i++) {
                if (i < moment(timestart, "HH:mm:00").hour()) {
                    hours.push(i);
                }
            }
        }
        return hours;
        // return this.props.getDisabledEndHours();
    }

    getDisabledEndMinutes() {
        const { startdate, enddate, timestart, timeend } = this.props;
        var minute = [];
        var currentDate = moment().format("YYYY-MM-DD")
        var startDate_ = startdate ? moment(startdate, "YYYY/MM/DD").format("YYYY-MM-DD") : null;
        var endDate_ = enddate ? moment(enddate, "YYYY/MM/DD").format("YYYY-MM-DD") : null;

        if (!timeend) {
            for (var i = 0; i < 60; i++) {
                minute.push(i);
            }
        }
        if (moment(endDate_).isSame(currentDate) && moment(timeend, "HH:mm:00").hour() === moment().hour()) {
            for (var i = 0; i < 60; i++) {
                if (i > moment().minute()) {
                    minute.push(i);
                }
            }
        }
        if (moment(startDate_).isSame(endDate_) && moment(timestart, "HH:mm:00").hour() === moment(timeend, "HH:mm:00").hour()) {
            for (var i = 0; i < 60; i++) {
                if (i < moment(timestart, "HH:mm:00").minute() && i < moment().minute()) {
                    minute.push(i);
                } 
            }
        }
        return minute;
        // return this.props.getDisabledEndMinutes();
    }

    disabledStartDate = (startValue) => {
        // return startValue > moment();
        return startValue && (startValue < moment().subtract(1, "y") || startValue > moment());
    };

    disabledEndDate = (endValue) => {
        const startdate = this.props.startdate;
        return endValue.valueOf() <= moment(startdate, "YYYY/MM/DD").valueOf() || endValue > moment();
    };

    // disabledEndDate(current) {
    //     return this.props.disabledEndDate(current);
    // }
    warningModal(value, message, messageSuccess) {
        this.props.warningModal(value, message, messageSuccess);
    }
    getdataornewftp(value) {
        return this.props.getdataornewftp(value);
    }
    cleardata() {
        return this.props.cleardata();
    }
    cleardataftp() {
        return this.props.cleardataftp();
    }
    testftp() {
        return this.props.testftp();
    }
    handleShowPassword() {
        this.setState({ showPassword: !this.state.showPassword });

    }

    render() {
        const { campaign, users, status, sender, account, report_Template, email, startdate, enddate, reportname, successModalVisible, loading,loadingtestftp,
            onetimereport_type, recuring_type, recuring_value, timestart, timeend, alert_flag, emailError, reportrecurrencetype, ftp_teststatus,
            ftp_flag, ftp_id, ftp_protocal, ftp_host, ftp_port, ftp_username, ftp_password, ftp_path, ftpraedonly } = this.props;
        const styleMarginBottom = { marginBottom: 20 };
        const { modalVisible } = this.state;

        var styleheadinput = {
            fontWeight: 700,
            fontSize: 27
        };
        var styletitleinput = {
            fontWeight: 700,
            marginBottom: -10
        };
        var stylechanneltypeinput = {
            fontWeight: 700,
            paddingLeft: 20
        };
        const routes = [
            {
                path: '/report/',
                breadcrumbName: 'Report',
            },
            {
                breadcrumbName: 'Create',
            },
        ];
        return (
            <>
                <ModalServerError modalVisible={modalVisible} />
                <div className="animated fadeIn reportpage">

                    <Row gutter={8, 8} type="flex" ustify="start" align='middle' >
                        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                            <label className=" control-label require" style={{ fontWeight: 700, fontSize: 27, marginBottom: -10 }}> Detailed Report Content</label>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                            <label style={{ textAlign: 'left' }}>{'Select the channel type of content you would like to include'}</label>
                        </Col>
                    </Row>
                    <Row gutter={16} type="flex" ustify="start" >
                        <Col xs={24} sm={24} md={24} lg={24} xl={6}>
                            <div >
                                <Card
                                    onClick={(e) => { this.setStateByProps("report_Template", 4); this.setStateByProps("onetimereport_type", 4); this.setStateByProps("reportrecurrencetype", 1); this.cleardata() }}
                                    className={report_Template == 4 ? 'Cardfixfocus' : 'Cardfix'}
                                >
                                    <Row type="flex" >
                                        <Col xs={2} sm={2} md={2} lg={2} xl={2}>
                                            <img src={require('../../../img/vendors/icon_40px/icon/campaignSummary.png')} />
                                        </Col>
                                        <Col xs={22} sm={22} md={22} lg={22} xl={22}>
                                            <label className=" control-label require" style={stylechanneltypeinput}> Communication Summary </label>
                                        </Col>
                                    </Row>
                                </Card>
                            </div>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={24} xl={6}>
                            <div >
                                <Card
                                    onClick={(e) => { this.setStateByProps("report_Template", 5); this.setStateByProps("onetimereport_type", 4); this.setStateByProps("reportrecurrencetype", 1); this.cleardata() }}
                                    className={report_Template == 5 ? 'Cardfixfocus' : 'Cardfix'}
                                >
                                    <Row type="flex"  >
                                        <Col xs={2} sm={2} md={2} lg={2} xl={2}>
                                            <img src={require('../../../img/vendors/icon_40px/icon/SummaryReportByAccount.png')} />
                                        </Col>
                                        <Col xs={22} sm={22} md={22} lg={22} xl={22}>
                                            <label className=" control-label require" style={stylechanneltypeinput}> Summary Report by Account</label>
                                        </Col>
                                    </Row>
                                </Card>
                            </div>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={24} xl={6}>
                            <div >
                                <Card
                                    onClick={(e) => { this.covertformatdate(thismouthstart, thismouthend); this.setStateByProps("report_Template", 6); this.setStateByProps("onetimereport_type", 7); this.setStateByProps("reportrecurrencetype", 1); this.cleardata() }}
                                    className={report_Template == 6 ? 'Cardfixfocus' : 'Cardfix'}
                                >
                                    <Row type="flex" >
                                        <Col xs={2} sm={2} md={2} lg={2} xl={2}>
                                            <img src={require('../../../img/vendors/icon_40px/icon/SummaryReportByDate.png')} />
                                        </Col>
                                        <Col xs={22} sm={22} md={22} lg={22} xl={22}>
                                            <label className=" control-label require" style={stylechanneltypeinput}> Summary Report by Date</label>
                                        </Col>
                                    </Row>
                                </Card>
                            </div>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={24} xl={6}>
                            <div >
                                <Card
                                    onClick={(e) => { this.setStateByProps("report_Template", 7); this.setStateByProps("onetimereport_type", 4); this.setStateByProps("reportrecurrencetype", 1); this.cleardata() }}
                                    className={report_Template == 7 ? 'Cardfixfocus' : 'Cardfix'}
                                >
                                    <Row type="flex" >
                                        <Col xs={2} sm={2} md={2} lg={2} xl={2}>
                                            <img src={require('../../../img/vendors/icon_40px/icon/SummaryReportBySender.png')} />
                                        </Col>
                                        <Col xs={22} sm={22} md={22} lg={22} xl={22}>
                                            <label className=" control-label require" style={stylechanneltypeinput}> Summary Report by Sender</label>
                                        </Col>
                                    </Row>
                                </Card>
                            </div>
                        </Col>
                    </Row>
                    <Row gutter={16} type="flex" justify="center" style={{ marginBottom: 10 }} >

                        <Col xs={24} sm={24} md={24} lg={24} xl={8}>
                            <label className=" control-label require" style={styletitleinput}> REPORT NAME </label>
                            <label style={{ marginBottom: -10 }} className="red">*</label>
                            <Tooltip placement="rightTop" title={tootipreportname}>
                                <Icon className='centericon' type="info-circle" style={{ fontSize: '25px', color: '#ea7326', verticalAlign: 8 }} theme="outlined" />
                            </Tooltip>
                            <Input placeholder="Enter name your report"
                                value={reportname}
                                maxLength={150}
                                onChange={(e) => this.setStateByProps("reportname", e.target.value)} />
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={24} xl={8}>
                            <label className=" control-label require" style={styletitleinput}> ACCOUNTS</label>
                            <SelectAccount placeholder={"Select Account"} account={account} select={account} setStateByProps={this.setStateByProps} />
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={24} xl={8}>
                            <label className=" control-label require" style={styletitleinput}> USERS</label>
                            <SelectUser
                                placeholder={"Select User"}
                                select={users}
                                accounts={account}
                                setStateByProps={this.setStateByProps} />
                        </Col>
                    </Row>
                    <Row gutter={16} type="flex" justify="center" style={styleMarginBottom} >
                        <Col xs={24} sm={24} md={24} lg={24} xl={8}>
                            <label className=" control-label require" style={styletitleinput}> COMMUNICATION</label>
                            <SelectCampaign
                                placeholder={"Select Campaign name"}
                                disablestatus={report_Template == 5 || report_Template == 7 ? true : false}
                                select={campaign}
                                accounts={account} report_Template={report_Template} sender={sender} users={users}
                                setStateByProps={this.setStateByProps} />
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={24} xl={8}>
                            <label className=" control-label require" style={styletitleinput}> SENDERS</label>
                            <SelectSender
                                placeholder={"Select Sender"}
                                mode={"multiple"}
                                select={sender}
                                campaign={campaign} accounts={account} report_Template={report_Template} users={users}
                                disablestatus={report_Template == 5 || report_Template == 4 ? true : false}
                                setStateByProps={this.setStateByProps} />
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={24} xl={8}>
                            <label className=" control-label require" style={styletitleinput}> STATUS</label>
                            <SelectStatus
                                mode={"multiple"}
                                select={status}
                                disablestatus={report_Template == 5 || report_Template == 4 ? true : false}
                                setStateByProps={this.setStateByProps}
                                selectall={true} />
                        </Col>
                    </Row>
                    <Row gutter={16} type="flex" justify="start" >
                        <Col xs={24} sm={24} md={24} lg={24} xl={8}>
                            <label className=" control-label require" style={styleheadinput}> Recurrence and Date Range</label>
                        </Col>
                    </Row>
                    <Row gutter={16, 16} type="flex" justify="start" >
                        <Col xs={24} sm={24} md={24} lg={24} xl={8}>
                            <Radio.Group onChange={(e) => { this.setStateByProps("reportrecurrencetype", e.target.value) }} value={reportrecurrencetype} style={{ marginBottom: 10 }} >
                                <Radio value={1} className=" control-label require" style={{ fontWeight: 700 }}
                                    onChange={(e) => {
                                        this.setStateByProps("recuring_flag", false),
                                            report_Template != 6 ? this.setStateByProps("onetimereport_type", 4) : this.setStateByProps("onetimereport_type", 6)
                                        this.setStateByProps("recuring_type", 0),
                                            this.covertformatdate(yesterdaystart, yesterdayend);
                                    }}>One-Time Report</Radio>
                                <Radio value={2} className=" control-label require" style={{ fontWeight: 700 }}
                                    onChange={(e) => {
                                        this.setStateByProps("recuring_flag", true),
                                            this.setStateByProps("recuring_type", 3),
                                            this.setStateByProps("onetimereport_type", 0),
                                            this.covertformatdate(thismouthstart, thismouthend),
                                            this.setreportrange(3);
                                    }}>Recurring Report</Radio>
                            </Radio.Group>
                        </Col>
                    </Row>
                    {reportrecurrencetype == 2 ?
                        <div>
                            <Row gutter={16} type="flex" justify="start"  >
                                <Col xs={24} sm={24} md={24} lg={24} xl={8}>
                                    <label className=" control-label require" style={styletitleinput}> Report Frequency</label>
                                    {report_Template != 6 ?
                                        <Select
                                            defaultValue={3}
                                            showSearch
                                            onChange={(e) => {
                                                this.setStateByProps("recuring_type", e), this.setStateByProps("recuring_flag", true), this.setStateByProps("recuring_value", 0)
                                                { e == 1 ? this.setreportrange(e) : null }
                                                { e == 2 ? this.setreportrange(2, "Monday") : null }
                                                { e == 2 ? this.setStateByProps("recuring_value", 1) : null }
                                                { e == 3 ? this.setreportrange(e) : null }
                                            }}>
                                            <Option value={1}>Daily</Option>
                                            <Option value={2}>Weekly</Option>
                                            <Option value={3}>Monthly</Option>
                                            <Option value={4}>Custom</Option>
                                        </Select> :
                                        <Select
                                            defaultValue={3}
                                            showSearch
                                            onChange={(e) => { this.setStateByProps("recuring_type", e) }}
                                        >
                                            <Option value={3}>Monthly</Option>
                                        </Select>}
                                </Col>

                                {recuring_type == 1 ?
                                    <div>
                                        <Col xs={24} sm={24} md={24} lg={24} xl={12}>
                                            <label className=" control-label require" style={styletitleinput}> Select Time </label>
                                            <Input defaultValue="00:00 - 23:59" disabled={true} />
                                        </Col>
                                        <Col xs={24} sm={24} md={24} lg={24} xl={12}>
                                            <label className=" control-label require" style={styletitleinput}> Report Range </label>
                                            <Input defaultValue="Yesterday" disabled={true} />
                                        </Col>
                                    </div> : null
                                }
                                {recuring_type == 2 ?
                                    <div>
                                        <Col xs={24} sm={24} md={24} lg={24} xl={12}>
                                            <label className=" control-label require" style={styletitleinput}> Select Day </label>
                                            <Select
                                                defaultValue={1}
                                                showSearch
                                                onChange={(e) => {
                                                    this.setStateByProps("recuring_value", e)
                                                    { e == 0 ? this.setreportrange(2, "Sunday") : null }
                                                    { e == 1 ? this.setreportrange(2, "Monday") : null }
                                                    { e == 2 ? this.setreportrange(2, "Tuesday") : null }
                                                    { e == 3 ? this.setreportrange(2, "Wednesday") : null }
                                                    { e == 4 ? this.setreportrange(2, "Thursday") : null }
                                                    { e == 5 ? this.setreportrange(2, "Friday") : null }
                                                    { e == 6 ? this.setreportrange(2, "Saturday") : null }

                                                }} >
                                                <Option value={0} >Sunday</Option>
                                                <Option value={1} >Monday</Option>
                                                <Option value={2} >Tuesday</Option>
                                                <Option value={3} >Wednesday</Option>
                                                <Option value={4} >Thursday</Option>
                                                <Option value={5} >Friday</Option>
                                                <Option value={6} >Saturday</Option>
                                            </Select>

                                        </Col>
                                        <Col xs={24} sm={24} md={24} lg={24} xl={12}>
                                            <label className=" control-label require" style={styletitleinput}> Report Range </label>
                                            <Input defaultValue="Previous 7 days" disabled={true} />
                                        </Col>
                                    </div> : null
                                }
                                {recuring_type == 3 ?
                                    <div>
                                        <Col xs={24} sm={24} md={24} lg={24} xl={12}>
                                            <label className=" control-label require" style={styletitleinput}> Select Day </label>
                                            <Input defaultValue="Last day of month e.g.31" disabled={true} />
                                        </Col>
                                        <Col xs={24} sm={24} md={24} lg={24} xl={12}>
                                            <label className=" control-label require" style={styletitleinput}> Report Range </label>
                                            <Input defaultValue="Previous month" disabled={true} />
                                        </Col>
                                    </div> : null
                                }
                                {recuring_type == 4 ?
                                    <div>
                                        <Col xs={24} sm={24} md={24} lg={24} xl={12}>
                                            <label className=" control-label require" style={styletitleinput}> Number of days</label>
                                            <InputNumber type="number" placeholder="input date" onChange={(e) => { this.setreportrange(4, e), this.setStateByProps("recuring_value", e) }} />
                                        </Col>
                                        <Col xs={24} sm={24} md={24} lg={24} xl={12}>
                                            <label className=" control-label require" style={styletitleinput}> Report Range </label>
                                            <Input defaultValue="Every previous N days" disabled={true} />
                                        </Col>
                                    </div> : null
                                }
                            </Row>

                            <Row gutter={16} type="flex" justify="start" >
                                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                    {recuring_type == 1 ?
                                        <label className=" control-label require" style={{ fontWeight: 400 }}> Note : Daily report is the report of yesterday from 00:00 - 23:59 </label>
                                        : null}
                                    {recuring_type == 2 ?
                                        <label className=" control-label require" style={{ fontWeight: 400 }}> Note : Weekly Report is the 7 days of day that is selected. </label>
                                        : null}
                                    {recuring_type == 3 ?
                                        <label className=" control-label require" style={{ fontWeight: 400 }}> Note : If there are less than 31 days in a month, then report will be generated on the last day of month. You will receive the report within 5 days of the next month. </label>
                                        : null}
                                    {recuring_type == 4 ?
                                        <label className=" control-label require" style={{ fontWeight: 400 }}> Note : You will receive the report in every N days onward.</label>
                                        : null}
                                </Col>
                            </Row>
                        </div> :
                        <Row gutter={16, 16} type="flex" justify="start" >
                            <Col xs={24} sm={24} md={24} lg={24} xl={8}>
                                {report_Template != 6 ?
                                    <Select
                                        defaultValue={4}
                                        showSearch
                                        value={onetimereport_type}
                                        onChange={(e) => {
                                            this.setStateByProps("onetimereport_type", e);
                                            this.setStateByProps("startcustomdate", '');
                                            const getnewtime = new Date(Date.now());
                                            const last6Hour = new Date(getnewtime.getTime() - 3600 * 6000);
                                            const last24Hour = new Date(getnewtime.getTime() - 3600 * 24000);

                                            { e == 1 ? this.covertformatdate(last6Hour, getnewtime) : null }
                                            { e == 2 ? this.covertformatdate(last24Hour, getnewtime) : null }
                                            { e == 3 ? this.covertformatdate(todaystart, todayend) : null }
                                            { e == 4 ? this.covertformatdate(yesterdaystart, yesterdayend) : null }
                                            { e == 5 ? this.covertformatdate(lastweekstart, lastweekdayend) : null }
                                            { e == 6 ? this.covertformatdate(lastmouthstart, lastmouthend) : null }
                                            { e == 7 ? this.covertformatdate(thismouthstart, thismouthend) : null }
                                        }}

                                    >
                                        <Option value={1}>Last 6 hours</Option>
                                        <Option value={2}>Last 24 hours</Option>
                                        <Option value={3}>Today</Option>
                                        <Option value={4}>Yesterday</Option>
                                        <Option value={5}>Last week</Option>
                                        <Option value={6}>Last month</Option>
                                        <Option value={7}>This month</Option>
                                        <Option value={8}>Custom</Option>
                                    </Select> :
                                    <Select
                                        allowClear
                                        defaultValue={7}
                                        showSearch
                                        value={onetimereport_type}
                                        key="byDate"
                                        setselect={null}
                                        onChange={(e) => {
                                            this.setStateByProps("onetimereport_type", e);
                                            { e == 6 ? this.covertformatdate(lastmouthstart, lastmouthend) : null }
                                            { e == 7 ? this.covertformatdate(thismouthstart, thismouthend) : null }
                                        }}
                                    >
                                        <Option value={6}>Last month</Option>
                                        <Option value={7}>This month</Option>
                                        <Option value={8}>Custom</Option>
                                    </Select>}
                            </Col>
                            {onetimereport_type != 8 ?
                                <Col xs={24} sm={24} md={24} lg={24} xl={16}>
                                    {onetimereport_type == 7 ?
                                        <label className=" control-label require" style={{ fontWeight: 400 }}>   Note: your report is from {moment(startdate).format('DD/MM/YYYY HH:mm').toString()} and will be ready after {moment(enddate).format('DD/MM/YYYY HH:mm').toString()} </label>
                                        : <label className=" control-label require" style={{ fontWeight: 400 }}>   Note: the report is from {moment(startdate).format('DD/MM/YYYY HH:mm').toString()} - {moment(enddate).format('DD/MM/YYYY HH:mm').toString()} </label>}
                                </Col> : null}
                        </Row>
                    }

                    {onetimereport_type == 8 && reportrecurrencetype == 1 ?
                        <Row gutter={8} type="flex" justify="start" style={styleMarginBottom} >
                            <Col xs={24} sm={24} md={24} lg={24} xl={3}>
                                <label className=" control-label require" style={styletitleinput}> Start time</label>
                                <DatePicker
                                    placeholder="Start Date"
                                    disabledDate={this.disabledStartDate}
                                    value={startdate ? moment(startdate, "YYYY/MM/DD") : null}
                                    onChange={(e) => this.props.setStateDateTime("startdate", e)}
                                    style={{ width: "100%" }}
                                    format={dateFormat} 
                                />
                            </Col>
                            <Col xs={24} sm={24} md={24} lg={24} xl={2}>
                                <label className=" control-label require" style={styletitleinput}>&nbsp;</label>
                                <TimePicker disabledHours={this.getDisabledStartHours} disabledMinutes={this.getDisabledStartMinutes}
                                    format={timeFormat}
                                    value={timestart ? moment(timestart, timeFormat) : null } 
                                    onChange={(e) => this.setStateByProps("timestart", e)}
                                    disabled={startdate ? false : true}
                                    placeholder="Select Time"
                                />
                            </Col>
                            <Col xs={24} sm={24} md={24} lg={24} xl={3}>
                                <label className=" control-label require" style={styletitleinput}> End time</label>
                                <DatePicker
                                    disabledDate={this.disabledEndDate}
                                    disabled={startdate && timestart ? false : true}
                                    value={enddate ? moment(enddate, "YYYY/MM/DD") : null}
                                    onChange={(e) => this.props.setStateDateTime("enddate", e)}
                                    style={{ width: "100%" }}
                                    format={dateFormat} 
                                    placeholder="End Date"
                                />
                            </Col>
                            <Col xs={24} sm={24} md={24} lg={24} xl={2}>
                                <label className=" control-label require" style={styletitleinput}> &nbsp;</label>
                                <TimePicker disabledHours={this.getDisabledEndHours} disabledMinutes={this.getDisabledEndMinutes}
                                    format={timeFormat}
                                    value={timeend ? moment(timeend, timeFormat) : null } 
                                    onChange={(e) => this.setStateByProps("timeend", e)}
                                    disabled={startdate && timestart && enddate ? false : true}
                                    placeholder="Select Time"
                                />
                            </Col>
                        </Row> : null
                    }
                    <Row gutter={16} type="flex" /* style={{display : "none"}} className="invisible" */>
                        <Col xs={12} sm={12} md={12} lg={12} xl={8}>
                            <label className=" control-label require centericon" style={styleheadinput}> Recipients &nbsp; </label>
                            <Tooltip placement="rightTop" title="You will receive notification to the defined email; once the report is completed">
                                <Icon className='centericon' type="info-circle" style={{ fontSize: '25px', color: '#ea7326', verticalAlign: 8 }} theme="outlined" />
                            </Tooltip>
                            {email != "" ? <label className="red" style={{ fontWeight: 600 }}> &nbsp;&nbsp;&nbsp;{!emailError ? " * Invalid email format" : ""}</label>
                                : null}

                        </Col>
                        <Col xs={12} sm={12} md={12} lg={12} xl={16}>
                            <div style={{ textAlign: "right" }}>
                                <Switch checked={alert_flag} onChange={(e) => { this.setStateByProps("alert_flag", e); this.setStateByProps("email", []) }} />
                            </div>
                        </Col>
                    </Row>


                    <Row gutter={16} type="flex" style={styleMarginBottom} /* style={{display : "none"}} */ >
                        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                            <Select
                                disabled={alert_flag == true ? false : true}
                                name="email"
                                mode="tags"
                                style={{ width: "100%" }}
                                placeholder="Add Recipients emails"
                                value={email}
                                onChange={(e) => {
                                    this.setStateByProps("email", e)
                                }} >
                            </Select>
                        </Col>
                    </Row>


                    <Row gutter={48} type="flex" justify="start" >
                        <Col xs={12} sm={12} md={12} lg={12} xl={8}>
                            <label className=" control-label require centericon" style={styleheadinput}> Export Report &nbsp;</label>
                            <Tooltip placement="rightTop" title="You can enable this function to export generated reports to your configured FTP server. We will save your connection on create report ">
                                <Icon type="info-circle" style={{ fontSize: '25px', color: '#ea7326', verticalAlign: 8 }} theme="outlined" />
                            </Tooltip>
                        </Col>
                        <Col xs={12} sm={12} md={12} lg={12} xl={16}>
                            <div style={{ textAlign: "right" }}>
                                <Switch defaultChecked={false} checked={ftp_flag} onChange={(e) => { this.setStateByProps("ftp_flag", e); this.cleardataftp(); }} />
                            </div>
                        </Col>
                    </Row>

                    <Row >
                        <Row gutter={16, 16} type="flex" justify="start">
                            <Col xs={24} sm={24} md={24} lg={24} xl={8}>
                                <Select
                                    allowClear
                                    loading={loading}
                                    disabled={ftp_flag == true ? false : true}
                                    onChange={(e) => {
                                        this.cleardataftp();
                                        this.setStateByProps("ftp_id", e);
                                        this.getdataornewftp(e)
                                    }}
                                    style={{ marginBottom: 10 }}
                                    value={ftp_flag == true ? ftp_id : undefined}
                                    suffixIcon={loading ? "" : <span className="fa fa-angle-down" />}
                                    placeholder={"Select Export Report"}
                                    filterOption={(input, option) =>
                                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {
                                        <Option value="New">New Export Report</Option>
                                    }

                                    {
                                        this.state.ftp_list.map((item) =>
                                            <Option key={item.ID} value={item.ID}>{item.TEXT}</Option>
                                        )
                                    }
                                </Select>
                            </Col>
                            <Col xs={24} sm={24} md={24} lg={24} xl={8}>
                                <div >
                                    <label className=" control-label require" style={styletitleinput}>&nbsp;</label>
                                    <Button disabled={ftp_flag == true ? false : true} loading={loadingtestftp} className={'btn-warning'} style={{ marginRight: 8 }} onClick={(e) => { this.testftp(); }}> Test FTP Connection</Button>
                                    {ftp_teststatus == 1 ? <label style={{ marginBottom: -10, fontWeight: 700 }} className="green">SUCCESS!</label> : null}
                                    {ftp_teststatus == 2 ? <label style={{ marginBottom: -10, fontWeight: 700 }} className="red">ERROR!</label> : null}
                                </div>
                            </Col>
                        </Row>
                        <Row gutter={16, 16} type="flex" justify="start" >
                            <Col xs={24} sm={24} md={24} lg={24} xl={8}>
                                <label className=" control-label require" style={styletitleinput}> Protocol</label>
                                <label style={{ marginBottom: -10 }} className="red">*</label>
                                <Select
                                    disabled={ftp_flag == true ? false : true}
                                    disabled={ftpraedonly == true ? true : false}
                                    onChange={(e) => { this.setStateByProps("ftp_protocal", e) }}
                                    value={ftp_protocal}
                                    readOnly={ftpraedonly}  >
                                    <Option value={1}>FTP</Option>
                                    <Option value={2}>SFTP</Option>
                                </Select>
                            </Col>
                            <Col xs={24} sm={24} md={24} lg={24} xl={8}>
                                <label className=" control-label require" style={styletitleinput}> Hostname or IP Address</label>
                                <label style={{ marginBottom: -10 }} className="red">*</label>

                                <Input disabled={ftp_flag == true ? false : true}
                                    placeholder="Enter Hostname or IP Addres"
                                    value={ftp_host}
                                    readOnly={ftpraedonly}
                                    onChange={(e) => {
                                        this.setStateByProps("ftp_host", e.target.value);
                                    }} />
                            </Col>
                            <Col xs={24} sm={24} md={24} lg={24} xl={8}>
                                <label className=" control-label require" style={styletitleinput}> Port</label>
                                <label style={{ marginBottom: -10 }} className="red">*</label>
                                <Input disabled={ftp_flag == true ? false : true}
                                    placeholder="Enter Port"
                                    value={ftp_port}
                                    readOnly={ftpraedonly}
                                    onChange={(e) => {
                                        this.setStateByProps("ftp_port", e.target.value);
                                    }} />
                            </Col>
                        </Row>

                        <Row gutter={16} type="flex" justify="start" style={styleMarginBottom} >
                            <Col xs={24} sm={24} md={24} lg={24} xl={8}>
                                <label className=" control-label require" style={styletitleinput}> Username</label>
                                <label style={{ marginBottom: -10 }} className="red">*</label>
                                <Input disabled={ftp_flag == true ? false : true}
                                    placeholder="Enter Username"
                                    value={ftp_username}
                                    readOnly={ftpraedonly}
                                    onChange={(e) => {
                                        this.setStateByProps("ftp_username", e.target.value);
                                    }} />
                            </Col>
                            <Col xs={24} sm={24} md={24} lg={24} xl={8}>
                                <label className=" control-label require" style={styletitleinput}> Password</label>
                                <Input disabled={ftp_flag == true ? false : true}
                                    type={this.state.showPassword ? "text" : "password"}
                                    placeholder="Enter Password"
                                    value={ftp_password}
                                    readOnly={ftpraedonly}
                                    onChange={(e) => {
                                        this.setStateByProps("ftp_password", e.target.value);
                                    }} />

                                <Icon onClick={this.handleShowPassword}
                                    disabled={ftp_flag == true ? false : true}
                                    type={this.state.showPassword ? "eye" : "eye-invisible"}
                                    className="showpasswordcreatereport" />
                            </Col>
                            <Col xs={24} sm={24} md={24} lg={24} xl={8}>
                                <label className=" control-label require" style={styletitleinput}> Remote path</label>
                                <Input disabled={ftp_flag == true ? false : true}
                                    placeholder="Enter Remote path"
                                    value={ftp_path}
                                    readOnly={ftpraedonly}
                                    onChange={(e) => {
                                        this.setStateByProps("ftp_path", e.target.value);
                                    }} />
                            </Col>
                        </Row>
                    </Row>

                    <Row gutter={16} type="flex" justify="center" style={styleMarginBottom} >
                        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                            <div style={{ textAlign: "right" }}>

                                <Button key="submit" style={{ marginRight: 10 }}
                                    onClick={(e) => { this.setStateByProps("action", "discard"), this.warningModal(true, "Do you want to discard change?", "Discarded change successfully.") }}>
                                    <span className="fa fa-trash-o"></span> &nbsp;&nbsp;
                                    Discard
                                </Button>

                                <Button
                                    type="primary"
                                    disabled={
                                        (report_Template && reportname.trim() ? false : true) ||
                                        (recuring_type == 4 ? recuring_value ? false : true : false) ||
                                        (onetimereport_type == 8 ? startdate && timestart && enddate && timeend ? false : true : false) ||
                                        (alert_flag ? email.length > 0 && emailError == true ? false : true : false) ||
                                        (ftp_flag ? ftp_protocal && ftp_host && ftp_port && ftp_username ? false : true : false)
                                    }
                                    onClick={() => {
                                        this.setStateByProps("action", "create"),
                                            this.warningModal(true, "Do you want to create this report?", "Created report successfully.")
                                    }} >
                                    <span className="fa fa-plus-circle"></span> &nbsp;&nbsp;
                                    Create
                                </Button>
                            </div>
                        </Col>
                    </Row>
                </div>
            </>
        );
    }
}


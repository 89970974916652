import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Table, Divider, Tag, Icon, Button, Row, Col, Popover, Menu } from 'antd';
import { apiClient } from '../../../api';
import{root} from '../../../config';
import { token, formatNumber, colorGreen, colorRed, CheckTokenExp } from '../../../config';
import moment from 'moment';
import 'moment-timezone';

moment.tz.setDefault("Asia/Bangkok");

const dateFormat = 'DD/MM/YYYY';
const dateFormatDateTime = 'DD/MM/YYYY HH:mm';

export default class TableAccounts extends Component {

    constructor(props) {
        super(props);

        this.state = {
            columns: [
                {
                    title: 'Account Name',
                    dataIndex: 'name',
                    key: 'name',
                    width: 200,
                    render: text => (<div className="table-title" title={text}>{text}</div>)
                },
                {
                    title: 'Owner',
                    dataIndex: 'parent',
                    key: 'parent',
                    width: 200,
                },
                {
                    title: 'Balance',
                    dataIndex: 'balance',
                    key: 'balance',
                    width: 200,
                    render: text => (text ? formatNumber(text) : 0) + " CREDITS"
                },
                {
                    title: 'Payment Type',
                    dataIndex: 'pay_type',
                    key: 'pay_type',
                    width: 100,
                    render: text => <Tag style={{ textAlign: "center" }}>{text == 1 ? "Prepaid" : "Postpaid"}</Tag>
                },
                {
                    title: 'Create Date',
                    dataIndex: 'createdate',
                    key: 'createdate',
                    width: 130,
                    render: text => (moment(text).format(dateFormatDateTime))
                },
                {
                    title: 'Create Account',
                    dataIndex: 'creactsubeaccount',
                    key: 'creactsubeaccount',
                    width: 130,
                    render: text => {
                        let colors = text == true ? colorGreen : colorRed;
                        return (<Tag color={colors}>{text == true ? "Yes" : "No"}</Tag>)
                    }
                },
                {
                    title: 'Status',
                    dataIndex: 'status',
                    key: 'status',
                    width: 130,
                    render: text => {
                        let colors = text == true ? colorGreen : colorRed;
                        return (<Tag color={colors}>{text == true ? "Enabled" : "Disabled"}</Tag>)
                    }
                },
                {
                    dataIndex: 'key',
                    key: 'key',
                    width: 50,
                    align: 'right',
                    render: (key, item) => (
                        <div>
                            <Popover placement="bottomRight" key={key}
                                visible={this.state.visible && this.state.popoverId === key ? true : false}
                                onVisibleChange={(value) => this.handleVisibleChange(value, key)}
                                content={
                                    <Menu mode="vertical" >
                                        <Menu.Item key="0" onClick={() => this.setClickId(item)} >
                                            <span className="fa fa-pencil-square-o wop-gray size14" ></span>&nbsp;&nbsp;  Edit
                                    </Menu.Item>
                                        {
                                            item.pay_type == 1 ?
                                                <hr className="mx-3 my-0" />
                                                :
                                                null
                                        }
                                        {
                                            item.pay_type == 1 ?
                                                <Menu.Item key="1" onClick={() => this.setCreditAccount(item)} >
                                                    <span className="fa fa-pencil-square-o wop-gray size14" ></span>&nbsp;&nbsp;  Credits Movement
                                            </Menu.Item>
                                                :
                                                null
                                        }
                                    </Menu>
                                } trigger="click">
                                <span className="fa fa-ellipsis-v" style={{ cursor: 'pointer', width: 30, textAlign: 'center' }}></span>
                            </Popover>
                        </div>
                    ),
                },
            ],
            pagesize: 10,
            currentpage: 1,
            startrow: 1,
            endrow: 10,
            totalpage: 0,
            clickId: null,
            visible: false,
            popoverId: 0,
        };

        this.getData = this.getData.bind(this);
        this.onChangePage = this.onChangePage.bind(this);
        this.setClickId = this.setClickId.bind(this);
        this.setCreditAccount = this.setCreditAccount.bind(this);
        this.handleVisibleChange = this.handleVisibleChange.bind(this);
    }

    componentDidUpdate(prevProps) {
        if (this.props.search !== prevProps.search) {
            this.getData();
        }
        if (this.props.subaccount !== prevProps.subaccount) {
            this.getData();
        }
    }

    componentDidMount() {
        this.getData();
    }

    getData() {
        if (!CheckTokenExp()) {
            this.setState({ loading: true });

            const { search } = this.props;

            this.setState(state => {

                let req = JSON.stringify({
                    name: search.name,
                    date: [search.dateRange[0] ? search.dateRange[0].format(dateFormat) : null, search.dateRange[1] ? search.dateRange[1].format(dateFormat) : null],
                    startrow: state.startrow,
                    endrow: state.endrow,
                });

                apiClient.post('api/usersmanage/AccountList', req
                    , {
                        headers: {
                            'Authorization': `Bearer ${token}`,
                            'Content-type': 'application/json; charset=UTF-8'
                        }
                    })
                    .then(data => {
                        var json = data.data;
                        this.setState(state => {
                            return {
                                data: json,
                                totalpage: json?.length > 0 ? json[0].totalpage : 0,
                                currentpage: state.startrow == 1 ? 1 : state.currentpage,
                                startrow: 1,
                                endrow: state.pagesize,
                            };
                        }, () => { this.props.updateWindowDimensions() });

                        this.setState({ loading: false });
                    })
                    .catch(error => {
                        this.setState({
                            data: [],
                            totalpage: 0,
                            currentpage: 1,
                            startrow: 1,
                            endrow:10
                        });
                        this.setState({ loading: false });
                    });
            });
        } else {
            window.location.href = "logout";
        }
        
    }

    onChangePage(currentPage, pageSize) {
        var endrow = currentPage * pageSize;
        var startrow = endrow - pageSize + 1;

        this.setState(state => {
            return {
                loading: true,
                currentpage: state.pagesize == pageSize ? currentPage : 1,
                pagesize: pageSize,
                startrow: startrow,
                endrow: endrow,
            };
        });
        this.getData();

    }

    setClickId(item) {
        this.setState({
            visible: false,
        });
        this.props.setViewChangePage(item.key);

    }

    setCreditAccount(item) {
        this.setState({
            visible: false,
        });
        this.props.setCreditAccId(item.key, item.name);
    }

    handleVisibleChange(visible, key) {
        this.setState({ visible, popoverId: key });
    };

    render() {
        const { columns, loading, data, totalpage } = this.state;
        const { setStateByProps, footer, templateItem } = this.props;
        return (
            <Table
                style={{ cursor: 'pointer' }}
                loading={loading}
                pagination={{
                    defaultPageSize: 10,
                    pageSizeOptions: ['10', '20', '50', '100'],
                    showSizeChanger: false,
                    total: totalpage,
                    showTotal: (total, range) => `${range[0]}-${range[1]} of ${totalpage} items`,
                    onShowSizeChange: this.onChangePage,
                    onChange: this.onChangePage,
                    current: this.state.currentpage
                }
                }
                onRow={(item, index) => ({
                    onDoubleClick: () => this.setClickId(item)
                })}
                columns={columns}
                dataSource={data}
                className="table-wop"
                footer={() => footer}
                tableLayout="fixed"
            />
        );
    }
}



/* eslint-disable */
import React, { Component } from 'react';
import moment from 'moment';
import 'moment-timezone';
import {
    PageHeader, Button, Descriptions, Select, Row, Col, DatePicker, Icon, Form, Input, Card,
    Tooltip, Progress, Table, Divider, Tag, Modal, Checkbox, Spin
} from 'antd';
import SelectSubAccSender from "../../../components/Wop/Select/SelectSubAccSender";
import TableSubAccSender from '../../../components/Wop/Table/TableSubAccSender';

import ModalSuccess from '../../../components/Wop/Modal/ModalSuccess';
import ModalWarning from '../../../components/Wop/Modal/ModalWarning';

import { token, CheckTokenExp } from '../../../config';
import { apiClient } from '../../../api';
import{root} from '../../../config';


moment.tz.setDefault("Asia/Bangkok");

const dateFormat = 'DD/MM/YYYY';
const { MonthPicker, RangePicker, WeekPicker, } = DatePicker;

export default class Sender extends Component {
    constructor(props) {
        super(props);

        this.state = {
            warningModalVisible: false,
            successModalVisible: false,
            loading: false,
            message: "",
            messageSuccess: "",
            screenwidth: 0,
            name: "",
            search: {
                name: "",
            },
            dsender: undefined,
            addsender: false,
        };

        this.warningModal = this.warningModal.bind(this);
        this.successModal = this.successModal.bind(this);
        this.modalcountDown = this.modalcountDown.bind(this);
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
        this.setStateByProps = this.setStateByProps.bind(this);
        this.onSearch = this.onSearch.bind(this);
        this.CheckSenderDuplicate = this.CheckSenderDuplicate.bind(this);
        this.addSender = this.addSender.bind(this);
    }

    componentDidMount() {
        this.updateWindowDimensions();
        window.addEventListener("resize", this.updateWindowDimensions.bind(this));
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.updateWindowDimensions.bind(this));
    }

    componentDidUpdate(prevProps) {
        if (this.props.subaccount !== prevProps.subaccount) {
            this.setStateByProps("sendercheck", false);
            this.setStateByProps("dsender", undefined);
        }
        if (this.props.setsubtabs !== prevProps.setsubtabs) {
            this.setStateByProps("sendercheck", false);
            this.setStateByProps("dsender", undefined);
        }
    }

    updateWindowDimensions() {
        setTimeout(() => {
            var root = document.getElementById("root");
            if (root) {
                var e = document.getElementById("main");
                if (e) {
                    var scroll = root.offsetHeight > window.innerHeight;
                    this.setState({ screenwidth: scroll ? e.offsetWidth - 124 : e.offsetWidth - 124 })
                }
            }
        }, 500);
    }

    warningModal(value, message, messageSuccess) {
        this.setState({ warningModalVisible: value, message, messageSuccess });
    }

    successModal(value) {
        if (this.state.action == "addsender") {
            this.setState({ action: "" });
            this.addSender();
        }

        this.setState({ successModalVisible: value });
    }

    modalcountDown() {
        let secondsToGo = 2;
        const timer = setInterval(() => {
        }, 1000);
        setTimeout(() => {
            clearInterval(timer);
            this.setState({ successModalVisible: false });
        }, secondsToGo * 1000);


    }

    setStateByProps(parameters, value) {
        value ? value : "";

        if (parameters == "username") {
            if (value.match(/^[A-Za-z0-9 /./+/*/@/\\_-]+$/i) || value == "") {
                this.setState({ [parameters]: value });
            }
        } else {
            this.setState({ [parameters]: value });

            if (parameters == "email") {
                this.setState({ emailError: validateEmail(value) });
            } else if (parameters == "uemail") {
                this.setState({ uemailError: validateEmail(value) });
            }
        }
        this.setState({ errorMessage: "" });
    }

    onSearch() {
        this.setState(state => {
            return {
                search: {
                    name: state.name,
                }
            }
        });
        this.updateWindowDimensions();
    }

    CheckSenderDuplicate() {
        if (!CheckTokenExp()) {
            this.setState({ loading: true });

            let req = JSON.stringify({
                id: this.props.accdetailId,
                senderName: this.state.dsender,
            });

            apiClient.post('api/template/CheckSenderDuplicate', req
                , {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-type': 'application/json; charset=UTF-8'
                    }
                })
                .then(data => {
                    var json = data.data;

                    if (!json) {
                        this.warningModal(true, "Do you want to add sender?", "Add sender successfully.");
                        this.setStateByProps('action', 'addsender');
                    }

                    this.setState({ sendercheck: json });
                    this.setState({ loading: false });
                })
                .catch(error => {
                    this.setState({ loading: false });
                });
        } else {
            window.location.href = "logout";
        }
        
    }

    addSender() {
        if (!CheckTokenExp()) {
            this.setState({ loading: true });

            let req = JSON.stringify({
                id: this.props.accdetailId,
                senderName: this.state.dsender,
            });

            apiClient.post('api/template/addSender', req
                , {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-type': 'application/json; charset=UTF-8'
                    }
                })
                .then(data => {
                    //var json = data.data;

                    this.setState({ addsender: true });
                    this.setState({ loading: false });
                })
                .catch(error => {
                    this.setState({ loading: false });
                });
        } else {
            window.location.href = "logout";
        }
        
    }

    render() {
        const { loading, message, messageSuccess, warningModalVisible, successModalVisible, search,
            templateItem, action, dsender, sendercheck, addsender } = this.state;
        const { accdetailId } = this.props;
        return (
            <div className="content" id="main">
                <Row gutter={8} className="row-nowrap" id="main">
                    <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                        <SelectSubAccSender select={dsender} placeholder="Select sender" setStateByProps={this.setStateByProps} />
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={10} xl={10}>
                        <Button type="primary" icon="plus-circle"
                            onClick={this.CheckSenderDuplicate}
                        >
                            Add
                        </Button>
                        <label className="red" style={{ marginLeft: 8 }}>{sendercheck ? "Sender duplicate" : ""}</label>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                        <Input placeholder="Search by Sender" onChange={(e) => this.setStateByProps("name", e.target.value)} />
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={2} xl={2}>
                        <Button icon="search" onClick={this.onSearch}>
                            Search
                        </Button>
                    </Col>
                </Row>

                <Row gutter={8} type="flex" justify="center">
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                        <TableSubAccSender
                            accdetailId={accdetailId}
                            search={search}
                            templateItem={templateItem}
                            addsender={addsender}
                            action={action}
                            warningModal={this.warningModal}
                            setStateByProps={this.setStateByProps}
                            updateWindowDimensions={this.updateWindowDimensions}
                        />

                    </Col>
                </Row>

                <ModalWarning loading={loading}
                    warningModalVisible={warningModalVisible}
                    warningModal={this.warningModal}
                    successModal={this.successModal}
                    modalcountDown={this.modalcountDown}
                    message={message}
                    messageSuccess={messageSuccess}
                />

                <ModalSuccess loading={loading}
                    successModalVisible={successModalVisible}
                    successModal={this.successModal}
                    messageSuccess={messageSuccess}
                />
            </div>

        );
    }
}

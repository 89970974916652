import axios from 'axios';

export const urlgetip = axios.create({
    mode: 'no-cors',
    baseURL: `https://antsnotifyapi-develop.azurewebsites.net/util/ip`,
   // baseURL: window.Configs.urlgetip,
});

export const apiAnalyze = axios.create({
    mode: 'no-cors',
   // baseURL: window.Configs.apiAnalyze,
   baseURL: "https://analyze.ants.co.th/AnalystData/Analyst" ,//"https://analys-preprod.azurewebsites.net/AnalystData/Analyst",  // https://analyst2.ants.co.th/AnalystData/Analyst     https://analys-preprod.azurewebsites.net/AnalystData/Analyst
    //timeout: 60000,
});


/* export const apiAnalyze = axios.create({
    mode: 'no-cors',
   // baseURL: window.Configs.apiAnalyze,
   baseURL: "https://analyst2.ants.co.th/AnalystData/Analyst",
    //timeout: 60000,
});
 */
/* export const apiClient = axios.create({
   // baseURL: window.Configs.apiClient,
   //baseURL: "https://antsserviceprivapi-pre.azurewebsites.net/",
  // baseURL: "https://dev.ants.co.th/private-sms/",
   baseURL: "https://antsserviceprivapi.azurewebsites.net/",
    //timeout: 60000,
}); */

export const urlClientInfo = axios.create({
    mode: 'no-cors',
    baseURL: `https://api.bigdatacloud.net/data/client-info`,
    //baseURL: window.Configs.urlClientInfo,
});

export const apiClient = axios.create({
    // baseURL: window.Configs.apiClient,
    //baseURL: "https://antsserviceprivapi-pre.azurewebsites.net/",
    // baseURL: "/private-sms", 
    baseURL: "", 
    
     //timeout: 60000,
 });
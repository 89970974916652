import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Table, Divider, Tag, Icon, Button, Row, Col,} from 'antd';

//import { data } from '../DataFake/CreditAnalyze';

export default class TableCredit extends Component {

    constructor(props) {
        super(props);
        
        this.state = {
            columns: [
                {
                    title: 'Name',
                    dataIndex: 'name',
                    key: 'name'
                },
                {
                    title: 'Recipients',
                    dataIndex: 'characters',
                    key: 'characters',
                },
            ],
        };

    }

    render() {
        const { columns } = this.state;
        const { data } = this.props;
        return (
            <div>
               
                <Table
                    pagination={false}
                    size="middle"
                    columns={columns}
                    dataSource={data}
                    className="table-wop"
                    showHeader={false}
                />
                </div>
           
        );
    }
}



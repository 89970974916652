import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Table, Divider, Tag, Icon, Button, Row, Col, } from 'antd';
import { token, formatNumber } from '../../../config';
import moment from 'moment';
import 'moment-timezone';

moment.tz.setDefault("Asia/Bangkok");

const dateTimeFormat = 'YYYYMMDDHHmmss';

export default class TableAnalyzeRecipients extends Component {

    constructor(props) {
        super(props);

        this.state = {
            columns: [
                {
                    title: 'Status',
                    dataIndex: 'status',
                    key: 'status',
                    render: (text, item) =>
                        <div style={{
                            cursor: 'context-menu'
                        }}>
                            {
                                text == "Unique" ? <span className="fa fa-star-o or" style={{ color: '#0ad1a3' }}> &nbsp;&nbsp;</span> :
                                    text == "Duplicate" ? <span className="fa fa-files-o" style={{ color: '#2db5ee' }}> &nbsp;&nbsp;</span> :
                                        text == "Blacklist" ? <span className="fa fa-ban" style={{ color: '#808184' }}> &nbsp;&nbsp;</span> :
                                            text == "Invalid" ? <span className="fa fa-times-circle-o" style={{ color: '#ff717a' }}> &nbsp;&nbsp;</span> :
                                                ""

                            }{" "}
                            {text}
                        </div>
                },
                {
                    title: 'Recipients',
                    dataIndex: 'recipients',
                    key: 'recipients',
                    render: (text, item) =>
                        <div style={{ cursor: 'context-menu' }}

                        >
                            {
                                item.status == "Unique" ? <span style={{ color: '#0ad1a3' }}> {formatNumber(text)}</span> :
                                    item.status == "Duplicate" ? text == 0 ? formatNumber(text) : <span style={{ color: '#2db5ee' }}> {formatNumber(text)}</span> :
                                        item.status == "Blacklist" ? text == 0 ? formatNumber(text) : <span style={{ color: '#808184' }}> {formatNumber(text)}</span> :
                                            item.status == "Invalid" ? text == 0 ? formatNumber(text) : <span style={{ color: '#ff717a' }}> {formatNumber(text)}</span> :
                                                ""
                            }
                        </div>
                },
            ],
        };

    }

    render() {
        const { columns } = this.state;
        const { data } = this.props;
        return (
            <div>
                <Table
                    pagination={false}
                    size="middle"
                    columns={columns}
                    dataSource={data}
                    className="table-wop time-table-row-select"
                    showHeader={false}
                />
            </div>
        );
    }
}



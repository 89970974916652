/* eslint-disable */
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Table, Divider, Tag, Icon, Button, Row, Col, Tooltip, Popover, Menu } from 'antd';
import moment from 'moment';
import 'moment-timezone';
import { apiClient } from '../../../api';
import{root} from '../../../config';
import { token, CheckTokenExp } from '../../../config';

moment.tz.setDefault("Asia/Bangkok");

const dateTimeFormat = 'DD/MM/YYYY HH:mm:ss';

export default class TableLog extends Component {

    constructor(props) {
        super(props);

        this.state = {
            columns: [
                {
                    title: 'Tag Name',
                    dataIndex: 'PRG_NAME',
                    key: 'PRG_NAME',
                    width: 200,
                    render: text => (<div className="table-title" title={text}>{text}</div>)
                },
                {
                    title: 'Member',
                    dataIndex: 'MEMBER',
                    key: 'MEMBER',
                    width: 100,
                },
                {
                    title: 'Create Date',
                    dataIndex: 'CREATE_DATE',
                    key: 'CREATE_DATE',
                    width: 150,
                    
                },
                {
                    dataIndex: 'PRG_ID',
                    key: 'PRG_ID',
                    width: 50,
                    align: 'right',
                    render: (key, item) => (
                        <div>
                            <Popover placement="bottomRight" content={
                                <Menu mode="vertical" >
                                    <Menu.Item key="0" style={{ borderBottom: '1px solid #7b7b7b' }}>
                                        <Link to={'/people/tag/detail?id=' + key} style={{ textDecoration: 'none' }} >
                                            <span className="fa fa-pencil-square-o wop-gray size14" ></span>&nbsp;&nbsp;  Edit
                                        </Link>
                                    </Menu.Item>

                                    <Menu.Item key="1" style={{ borderBottom: '1px solid #7b7b7b' }} onClick={() => {
                                        this.props.warningModal(true, "Do you want to delete this tag?", "Deleted tag successfully."),
                                            this.props.setStateByProps("action", "delete"),
                                            this.props.setStateByProps("Tagdeletelist", [{ 'PRG_ID': key }])
                                    }}>
                                        <span className="fa fa-trash-o wop-gray size14" ></span>&nbsp;&nbsp;  Delete
                                        </Menu.Item>
                                </Menu>
                            } trigger="click">
                                <span className="fa fa-ellipsis-v" style={{ cursor: 'pointer', width: 30, textAlign: 'center' }}></span>
                            </Popover>
                        </div>
                    ),
                },

            ],
            pagesize: 10,
            currentpage: 1,
            minpage: 1,
            maxpage: 10,
            enddata: 10,
            totalpage: 0,
            data: [],
            selectedRowKeys: [],
            clickId: null,
        };

        this.getData = this.getData.bind(this);
        this.onChangePage = this.onChangePage.bind(this);
        this.setClickId = this.setClickId.bind(this);
    }

    componentDidUpdate(prevProps) {
        if (this.props.search !== prevProps.search) {
            this.getData(1, 10, "");
        }
    }

    componentDidMount() {
        this.getData(1, 10, "");
    }

    getData(minpage, pagesize, value, currentpage) {
        if (!CheckTokenExp()) {
            this.setState({ loading: true });

            const { search } = this.props;

            this.state.data.length > 0 ? "" : this.setState({ loading: true });

            this.setState(state => {
                let req = JSON.stringify({
                    "SEARCH_TAG": search.value,
                    "MINPAGE": state.minpage,
                    "PAGESIZE": state.maxpage
                });

                apiClient.post('api/Tag/TAG_LIST', req
                    , {
                        headers: {
                            'Authorization': `Bearer ${token}`,
                            'Content-type': 'application/json; charset=UTF-8'
                        }
                    })
                    .then(data => {
                        var json = data.data;

                        this.setState({ totalpage: json ? json.totalpage : 0, current: currentpage == null ? this.state.current : currentpage });
                        this.setState({
                            data: json ? json.tag_list : [],
                            Tagdeletelist: [],
                            currentpage: state.minpage == 1 ? 1 : state.currentpage,
                            minpage: 1
                        }, () => { this.props.updateWindowDimensions() });

                        this.setState({ loading: false });
                    })
                    .catch(error => {
                        this.setState({
                            data: [],
                            totalpage: 0,
                            currentpage: 1,
                            minpage: 1
                        });
                        this.setState({ loading: false });
                    });
                this.setState({ selectedRowKeys: [] });
            });
        } else {
            window.location.href = "logout";
        }
        
    }

    onChangePage(currentPage, pageSize) {
        var currentpage = this.state.pagesize == pageSize ? currentPage : 1;
        var maxpage = currentpage * pageSize;
        var minpage = maxpage - pageSize + 1;

        this.setState({
            loading: true,
            currentpage: currentpage,
            pagesize: pageSize,
            minpage: minpage,
            maxpage: maxpage
        });
        this.getData(minpage);
    }

    setClickId(item) {
        this.setState(state => {
            if (item) {
                window.location.href = 'people/tag/detail?id=' + item.PRG_ID
            }
            return {
                clickId: item ? item.PRG_ID : item,
            }
        })

    }

    render() {
        const { columns, loading, data, totalpage, current, selectedRowKeys } = this.state;
        const rowSelection = {
            columnWidth: 30,
            selectedRowKeys,
            onChange: (selectedRowKeys, selectedRows) => {
                const rows = []
                var data = selectedRowKeys.map((item) =>
                    rows.push({ 'PRG_ID': item })
                );
                this.setState({ selectedRowKeys: selectedRowKeys })
                this.props.setStateByProps("Tagdeletelist", rows)
            },
        };
        return (
            <div>

                <Table
                    style={{ cursor: 'pointer' }}
                    loading={loading}
                    rowKey={key => key.PRG_ID}
                    pagination={{
                        defaultPageSize: 10,
                        pageSizeOptions: ['10', '20', '50', '100'],
                        showSizeChanger: true,
                        total: totalpage,
                        showTotal: (total, range) => `${range[0]}-${range[1]} of ${totalpage} items`,
                        onShowSizeChange: this.onChangePage,
                        onChange: this.onChangePage,
                        current: this.state.currentpage
                    }
                    }
                    onRow={(item, index) => ({
                        onDoubleClick: () => this.setClickId(item)
                    })}
                    rowSelection={rowSelection}
                    columns={columns}
                    dataSource={data}
                    className="table-wop"
                    tableLayout="fixed"
                />
            </div >

        );
    }
}



/* eslint-disable */
import React, { Component } from "react";
import { Redirect } from 'react-router-dom';
import { Form, Input, DatePicker, TimePicker, Select, Cascader, InputNumber, Icon, Row, Col, Button, Tooltip, Spin, Checkbox  } from 'antd';
import moment from 'moment';
import 'moment-timezone';
import { apiAnalyze, apiClient } from '../../../../api';
import { token, getUrlVars, formatNumber, getAccId, CheckTokenExp, ReplaceUrlTaga, getLinkbacklist } from '../../../../config';
import InputTo from '../../../../components/Wop/Input/InputToBasic';
import SelectSender from '../../../../components/Wop/Select/SelectSender';
import ButtonGroupBroadcast from '../../../../components/Wop/Button/ButtonGroupBroadcast';
import MultipleTrigger from '../../../../components/Wop/Mentions/MultipleTrigger';
import { toText, fromText } from '../../../../components/Wop/TooltipText/Text';
import ModalServerError from "../../../../components/Wop/Modal/ModalServerError";

moment.tz.setDefault("Asia/Bangkok");

const { Option } = Select;

export default class QuickSend extends Component {
    constructor(props) {
        super(props);
        this.indexcontent = this.indexcontent.bind(this);
        this.countMessage = this.countMessage.bind(this);
        this.analyze_showDrawer = this.analyze_showDrawer.bind(this);
        this.analyze_onClose = this.analyze_onClose.bind(this);
        this.onClickPreview = this.onClickPreview.bind(this);
        this.onDraft = this.onDraft.bind(this);
        this.onDiscard = this.onDiscard.bind(this);

        this.setStateByProps = this.setStateByProps.bind(this);
        this.GetPeopleList = this.GetPeopleList.bind(this);
        this.GetTagList = this.GetTagList.bind(this);
        this.checkinputnull = this.checkinputnull.bind(this);
        this.SaveCampaign = this.SaveCampaign.bind(this);
        this.AnalyzeFunc = this.AnalyzeFunc.bind(this);
        this.analyze_onSend = this.analyze_onSend.bind(this);

        this.AnalyzeFunc2 = this.AnalyzeFunc2.bind(this);

        this.child = React.createRef();
        this.state = {
            indexcontent: 0,
            content: "",
            content_old: "",
            MessageCount: 1,
            CharacterCount: 160,
            contentlength: 0,
            variable: ["firstName", "lastName", "address",
                "city", "country", "gender", "birthDate"],
            uploadfile_visible: false,
            analyze_visible: false,
            preview: false,
            clearstate: false,
            to: [],
            fileList: [],
            file_size: 0,
            sender: undefined,
            schedule_commu_date: "",
            schedule_commu_time: "",
            cb_shortedurl: false,
            createTemplate: false,
            PeopleLists: [],
            TagsLists: [],
            SMS_DRAFT: false,
            selectedFileList: [],
            senderlist: [],
            total_credit: [],
            total_recipients: [],
            btnsend_loading: false,
            errorMessage: "",
            anl_to: "",
            anl_shorturlflag: false,
            anl_message: "",
            anl_total: 0,
            anl_credit: 0,
            anl_invalid: 0,
            anl_blacklist: 0,
            anl_duplicate: 0,
            anl_shortest: 0,
            anl_longest: 0,
            anl_resultCode: "",
            anl_description: "",
            anl_errorMessage: "",
            smstoCount: 0,
            smsMaxStable: 1000,
            errorSmsto: "",
            Linkbacklist: getLinkbacklist(),
            modalVisible: false,
        };
    }
    componentDidMount() {
        this.GetPeopleList()
        this.GetTagList();
        ReplaceUrlTaga();
    }

    indexcontent(e) {
        var start = this.state.content.substring(0, e.target.selectionEnd);
        var c = (start.split("{").length - 1) * 2;
        this.setState({ indexcontent: e.target.selectionEnd + c });
    }

    countMessage(content) {
        //1 Message / Used with 5 remaining 165
        var contentlength = content == undefined ? 0 : JSON.stringify(content.trim()).length == 0 ? 0 : (JSON.stringify(content.trim()).length - 2);
        var msgCount = 0;
        var messremaining = 160;
        if (contentlength) {
            if (!content.match(/^([a-zA-Z0-9\|!#$%&/()*^=?»«@£§€{}.-;'"<>_[\]\\,\-\+ \n\r])+$/i)) {
                msgCount = contentlength <= 70 ? 1 : contentlength <= 134 ? 2 : parseInt(Math.ceil(parseFloat(contentlength) / 67));
                messremaining = contentlength <= 70 ? 70 - contentlength : contentlength <= 134 ? 134 - contentlength : (134 + (67 * (msgCount - 2))) - contentlength;
            } else {
                msgCount = contentlength <= 160 ? 1 : contentlength <= 306 ? 2 : parseInt(Math.ceil(parseFloat(contentlength) / 153));
                messremaining = contentlength <= 160 ? 160 - contentlength : contentlength <= 306 ? 306 - contentlength : (306 + (153 * (msgCount - 2))) - contentlength;
            }
        }
        this.setState({
            content: content,
            content_require: false,
        });
        this.setState({ MessageCount: msgCount });
        this.setState({ contentlength: contentlength });
        this.setState({ CharacterCount: messremaining });

    }

    analyze_showDrawer() {
        this.AnalyzeFunc();
        this.setState({ analyze_visible: true });

    }

    analyze_onClose() {
        this.setState({
            analyze_visible: false,
        });
    }

    onDraft() {

    }

    onDiscard() {
        this.setState({ clearstate: true });
        this.props.clearType();
        //window.location.reload();
    }

    onClickPreview(value) {
        var to_require = this.state.to.length > 0 ? false : true
        var sender_require = this.state.sender ? false : true
        var content_require = this.state.content ? false : true

        if (!to_require && !sender_require && !content_require) {
            this.setState({
                preview: value
            });
        }

        if (value) {
            setTimeout(() => {
                let modalcontent = document.querySelectorAll('.ant-modal-content');
                for (var i = 0; i < modalcontent.length; i++) {
                    modalcontent[i].className += " ant-modal-content-hide";
                }
            }, 10);
        } else {
            setTimeout(() => {
                let modalcontent = document.querySelectorAll('.ant-modal-content-hide');
                for (var i = 0; i < modalcontent.length; i++) {
                    modalcontent[i].className = " ant-modal-content";
                }
            }, 10);
        }
    }

    setStateByProps(parameters, value) {
        value ? value : "";
        this.setState({ [parameters]: value });
        if (parameters == "to") {
            if (value.toString().length <= this.state.smsMaxStable) {
                this.setState({
                    errorSmsto: "",
                });
            }
            this.setState({
                smstoCount: value.toString().length
            });
        }
        if (parameters == "errorLength") {
            this.setState({
                errorSmsto: value,
            });
        }
    }

    GetPeopleList(value) {
        if (!CheckTokenExp()) {
            this.state.PeopleLists.length > 0 ? "" : this.setState({ loading: true });
            var search = value ? value : "null";
            const encodedString = new Buffer(search).toString('base64');
            apiClient.get('api/Campaign/PeopleLists/' + encodedString
                , {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-type': 'application/json; charset=UTF-8'
                    }
                })
                .then(data => {
                    if (data.status === 200 && data.data) {
                        if (search.search("person_") == 0 || search != null) {
                            var list = [...data.data, ...this.state.PeopleLists];
                            const dataList = Array.from(new Set(list.map(a => a.ID)))
                                .map(id => {
                                    return list.find(a => a.ID === id)
                                });
                            this.setState({ PeopleLists: dataList });
                        } else {
                            this.setState({ PeopleLists: data.data });
                        }
                    } else {
                        this.setState({ modalVisible: true });
                        this.setState({ PeopleLists: [] });
                    }
                    this.setState({ loading: false });
                })
                .catch(error => {
                    this.setState({ modalVisible: true });
                    this.setState({ loading: false });
                });
        } else {
            window.location.href = "logout";
        }

    }

    GetTagList(value) {
        if (!CheckTokenExp()) {
            this.state.TagsLists.length > 0 ? "" : this.setState({ loading: true });
            var search = value ? value : "null";
            const encodedString = new Buffer(search).toString('base64');
            apiClient.get('api/Campaign/TagsLists/' + encodedString
                , {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-type': 'application/json; charset=UTF-8'
                    }
                })
                .then(data => {
                    var json = data.data;
                    if (search.search("tag_") == 0 || search != null) {
                        var d = [...json, ...this.state.TagsLists];
                        const data = Array.from(new Set(d.map(a => a.ID)))
                            .map(id => {
                                return d.find(a => a.ID === id)
                            });
                        this.setState({ TagsLists: data });
                        this.setState({ loading: false });
                    } else {
                        this.setState({ TagsLists: json ? json : [] });
                        this.setState({ loading: false });
                    }

                })
                .catch(error => {
                    this.setState({ loading: false });
                });
        } else {
            window.location.href = "logout";
        }

    }

    AnalyzeFunc2() {
        const { fileList } = this.state;
        const formData = new FormData();

        fileList.forEach(file => {
            //    formData.append("Files[]", file.File);
            formData.append("Files[]", new Blob([file], { type: file.type }));
        });

        formData.append("accountId", getAccId());
        formData.append("message", this.state.content);
        formData.append("shortUrlFlag", this.state.cb_shortedurl);
        formData.append("TO", this.state.to);
        formData.append("RequestType", 3);

        apiClient.post('api/Analyze', formData
            , {
                headers: {

                }
            })
            .then(data => {
                var json = data.data;
                if (json.resultCode !== "000") {
                    this.setState({
                        errorMessage: json.resultCode == "500" ? "Analyze Error" : json.description,
                        btnsend_loading: false,
                        loading: false
                    });
                    return false;
                }


                var total_credit = [
                    {
                        key: '1',
                        name: 'Shortest',
                        characters: formatNumber(json.shortest),
                    },
                    {
                        key: '2',
                        name: 'Longest',
                        characters: formatNumber(json.longest),
                    },
                ];

                var total_recipients = [
                    {
                        key: '1',
                        status: 'Unique',
                        recipients: json.total,
                    },
                    {
                        key: '2',
                        status: 'Duplicate',
                        recipients: json.duplicate,
                    },
                    {
                        key: '3',
                        status: 'Blacklist',
                        recipients: json.blackList,
                    },
                    {
                        key: '4',
                        status: 'Invalid',
                        recipients: json.invalid,
                    },
                ];

                var analyzeTable = [
                    {
                        key: '1',
                        name: this.state.sender,
                        schedule: this.state.schedule_commu_date ? moment(moment(moment(this.state.schedule_commu_date).format("DD/MM/YYYY") + " " + moment(this.state.schedule_commu_time).format("HH:mm"), "DD/MM/YYYY HH:mm")).format('DD/MM/YYYY HH:mm')
                            : moment().format("DD/MM/YYYY HH:mm")
                    }
                ];

                this.setState({
                    total_credit: total_credit,
                    sum_credit: formatNumber(json.credit),
                    SUM_RECIPTENTS: formatNumber(json.total),
                    total_recipients: total_recipients,
                    analyzeTable: analyzeTable,
                    sum_message: json.total,

                    anl_to: this.state.to,
                    anl_shorturlflag: this.state.cb_shortedurl,
                    anl_message: this.state.content,
                    anl_total: json.total,
                    anl_credit: json.credit,
                    anl_invalid: json.invalid,
                    anl_blacklist: json.blackList,
                    anl_duplicate: json.duplicate,
                    anl_shortest: json.shortest,
                    anl_longest: json.longest,
                    anl_resultCode: json.resultCode,
                    anl_description: json.description,
                    anl_errorMessage: json.description,

                    Analyz: false,
                    show_summary: true,
                    loading: false,
                    btnsend_loading: false,
                    errorMessage: ""
                });

                // if (json.resultCode !== "000") {
                //     this.setState({ errorMessage: json.description, loading: false, btnsend_loading: false });
                // }
            })
            .catch(error => {
                this.setState({ btnsend_loading: false });
                this.setState({ loading: false });
            });
    }

    AnalyzeFunc() {
        var isnull = this.checkinputnull();
        if (!isnull) {
            this.setState({ btnsend_loading: true, loading: true });
            //uploadfile formdata
            this.AnalyzeFunc2();

        }
    }

    checkinputnull() {
        var to_require = this.state.to.length > 0 ? false : true
        var sender_require = this.state.sender ? false : true
        var content_require = this.state.content ? false : true

        this.setState({ to_require, sender_require, content_require });

        if (to_require || sender_require || content_require) {
            this.setState({ isnull: true })
            return true;
        } else {
            this.setState({ isnull: false })
            return false;
        }

    }

    SaveCampaign() {
        if (!CheckTokenExp()) {
            const formData = new FormData();

            this.checkinputnull();
            if (!this.state.isnull) {
                let req = JSON.stringify({
                    "SMS_FROM": this.state.sender,
                    "SMS_TO": this.state.to,
                    "SMS_TEXT": this.state.content.trim(),
                    "SEND_TYPE": 1,
                    "ANL_TO": this.state.anl_to,
                    "ANL_SHORTURLFLAG": this.state.anl_shorturlflag,
                    "ANL_MESSAGE": this.state.anl_message,
                    "ANL_TOTAL": this.state.anl_total,
                    "ANL_CREDIT": this.state.anl_credit,
                    "ANL_BLACKLIST": this.state.anl_blacklist,
                    "ANL_DUPLICATE": this.state.anl_duplicate,
                    "ANL_INVALID": this.state.anl_invalid,
                    "ANL_SHORTEST": this.state.anl_shortest,
                    "ANL_LONGEST": this.state.anl_longest,
                    "ANL_DESCRIPTION": this.state.anl_description,
                    "ANL_RESULTCODE": this.state.anl_resultCode,
                    "ANL_ERRORMESSAGE": this.state.anl_errorMessage,
                }).replace(/\\r/g, '\r').replace(/\\n/g, '\n');

                formData.append('req', req);

                apiClient.post('api/Campaign/QuickSend', formData
                    , {
                        headers: {
                            'Authorization': `Bearer ${token}`,
                            'Content-type': 'application/json; charset=UTF-8'
                        }
                    })
                    .then(data => {
                        var json = data.data;
                        if (json.Code != "200") {
                            this.child.current.modalResult(false, json.Description);
                            return false;
                        }
                        this.setState({
                            clearstate: false
                        });
                        this.child.current.modalResult(true);
                    })
                    .catch(error => {
                        this.child.current.modalResult(false);
                        this.setState({
                            clearstate: false
                        });
                        var acs = error;
                    });
            }
        } else {
            window.location.href = "logout";
        }

    }

    analyze_onSend() {
        this.setState({
            loading: false,
            SMS_DRAFT: false,
            to: [...this.state.to],
            content: this.state.content,
            sender: this.state.sender,
            SEND_TYPE: (this.state.selectedFileList.length > 0 ? 2 : 1),
        }, function () {
            this.SaveCampaign();
        });

        this.setState({
            analyze_visible: false,
        });
    }

    render() {
        const marginbottomrow = { marginBottom: 16 };
        const { uploadfile_visible, analyze_visible, preview, variable, to,
            fileList, file_size, sender, content_old, schedule_commu_date, schedule_commu_time, cb_shortedurl,
            broadcastName, createTemplate, PeopleLists, TagsLists, loading, total_credit, sum_credit, SUM_RECIPTENTS,
            total_recipients, analyzeTable, content, btnsend_loading, errorMessage, errorSmsto, 
            smstoCount, smsMaxStable, Linkbacklist, modalVisible } = this.state;
        return (
            <div>
                <Spin spinning={this.state.clearstate}>
                    <Row gutter={16} style={marginbottomrow}>
                        <Col xs={24} sm={24} md={24} lg={24} xl={1}>
                            &nbsp;
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={24} xl={14}>
                            <label className=" control-label require" style={{ fontWeight: 700 }}>To
                            </label>
                            <label className="red">*</label>
                            &nbsp; &nbsp;
                            <Tooltip placement="rightTop" title={toText}>
                                <span className="fa fa-info-circle wop-gray-low size14"></span>
                            </Tooltip>
                            <div style={{ float: 'right' }}>
                                {
                                    errorSmsto == ""
                                        ?
                                        <label > {smstoCount} / {smsMaxStable}</label>
                                        :
                                        <label className="red"> {smstoCount} / {smsMaxStable} {errorSmsto}</label>
                                }
                            </div>
                            <InputTo
                                loading={loading}
                                to={to}
                                fileList={fileList}
                                file_size={file_size}
                                PeopleLists={PeopleLists}
                                TagsLists={TagsLists}
                                setStateByProps={this.setStateByProps}
                                GetTagList={this.GetTagList}
                                GetPeopleList={this.GetPeopleList}
                                maxLength={smsMaxStable}
                            />
                        </Col>
                    </Row>

                    <Row gutter={16} style={marginbottomrow}>
                        <Col xs={24} sm={24} md={24} lg={24} xl={1}>
                            &nbsp;
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={24} xl={14}>
                            <label className=" control-label require" style={{ fontWeight: 700 }}>From
                            </label>
                            <label className="red">*</label>
                            &nbsp; &nbsp;
                            <Tooltip placement="rightTop" title={fromText}>
                                <span className="fa fa-info-circle wop-gray-low size14"></span>
                            </Tooltip>
                            <SelectSender setStateByProps={this.setStateByProps} select={sender} />
                        </Col>
                    </Row>

                    <Row gutter={16} style={marginbottomrow}>
                        <Col xs={24} sm={24} md={24} lg={24} xl={1}>
                            &nbsp;
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={24} xl={14}>
                            <label className=" control-label require" style={{ fontWeight: 700 }}>Content </label>
                            <label className="red">*</label>
                            <div style={{ float: "right", }}>
                                <label style={{ color: '#bfbfbf' }}>  {this.state.MessageCount} {this.state.MessageCount > 1 ? "Credits" : "Credit"}, {this.state.contentlength}/{this.state.CharacterCount} characters</label>
                            </div>
                            <MultipleTrigger

                                data={this.state.variable} contents={this.state.content}
                                onmouseup={this.indexcontent}
                                useonchange={true}
                                onchange={(contents) => {
                                    this.setState({
                                        content: contents,
                                        content_old: contents,
                                        indexcontent: this.state.indexcontent + 1
                                    }), this.countMessage(contents)
                                }} />
                            {Linkbacklist == "True" ?
                                <Checkbox checked={true} disabled={true} style={{ color: '#bfbfbf', marginTop: 10, marginBottom: 10 }}>Receivers will be able to unsubscribe messages from this account </Checkbox> : null
                            }
                        </Col>
                    </Row>

                    <Row gutter={16} style={marginbottomrow}>
                        <Col xs={24} sm={24} md={24} lg={24} xl={1}>
                            &nbsp;
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={24} xl={14}>
                            <Row gutter={16}>
                                <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: "right" }}>
                                    <ButtonGroupBroadcast analyze_visible={analyze_visible}
                                        btnsend_loading={btnsend_loading}
                                        mode={"qs"}
                                        analyze_showDrawer={this.analyze_showDrawer}
                                        analyze_onClose={this.analyze_onClose}
                                        analyze_onSend={this.analyze_onSend}
                                        onClickPreview={this.onClickPreview}
                                        preview={preview}
                                        onDraft={this.onDraft}
                                        onDiscard={this.onDiscard}
                                        sender={sender}
                                        content={content_old}
                                        schedule_commu_date={schedule_commu_date}
                                        schedule_commu_time={schedule_commu_time}
                                        total_credit={total_credit}
                                        sum_credit={sum_credit}
                                        total_recipients={total_recipients}
                                        SUM_RECIPTENTS={SUM_RECIPTENTS}
                                        analyzeTable={analyzeTable}
                                        disabled={to && sender && content.trim() && errorSmsto == "" ? false : true}
                                        disabledPreview={to && sender && content.trim() ? false : true}
                                        errorMessage={errorMessage}
                                        ref={this.child}
                                    />

                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Spin>
                <ModalServerError modalVisible={modalVisible} />
            </div>
        );
    }
}


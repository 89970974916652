/* eslint-disable */
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { PageHeader, Button, Descriptions, Select, Row, Col, DatePicker, Icon, Form, Input, Card, Tooltip, Progress, Table, Divider, Tag, Modal } from 'antd';
import SelectUser from '../../../components/Wop/Select/SelectUser';
import TableSender from '../../../components/Wop/Table/TableSender';
import { token,ReplaceUrlTaga } from '../../../config';
import moment from 'moment';
import 'moment-timezone';
import ModalSuccess from '../../../components/Wop/Modal/ModalSuccess';
import ModalWarning from '../../../components/Wop/Modal/ModalWarning';

moment.tz.setDefault("Asia/Bangkok");

const dateFormat = 'DD/MM/YYYY';
const { MonthPicker, RangePicker, WeekPicker, } = DatePicker;

export default class List extends Component {
    constructor(props) {
        super(props);

        this.state = {
            warningModalVisible: false,
            successModalVisible: false,
            loading: false,
            message: "",
            messageSuccess: "",
            name: "",
            search: {
                name: "",
            },
            page: "",
            templateItem: [],
            action: "",
            screenwidth: 0,
        };

        this.warningModal = this.warningModal.bind(this);
        this.successModal = this.successModal.bind(this);
        this.modalcountDown = this.modalcountDown.bind(this);
        this.setStateByProps = this.setStateByProps.bind(this);
        this.onSearch = this.onSearch.bind(this);
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    }

    componentDidMount() {
        this.updateWindowDimensions();
        window.addEventListener("resize", this.updateWindowDimensions.bind(this));
        ReplaceUrlTaga();
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.updateWindowDimensions.bind(this));
    }

    updateWindowDimensions() {
        setTimeout(() => {
            var root = document.getElementById("root");
            if (root) {
                var e = document.getElementById("main");
                if (e) {
                    var scroll = root.offsetHeight > window.innerHeight;
                    this.setState({ screenwidth: scroll ? e.offsetWidth - 120 : e.offsetWidth - 116 })
                }
            }
        }, 500);
    }

    warningModal(value, message, messageSuccess) {
        this.setState({ warningModalVisible: value, message, messageSuccess });
    }

    successModal(value) {
        this.setState({ successModalVisible: value });

        let modalcontent = document.querySelectorAll('.ant-modal-contentant-modal-content-hide');
        for (var i = 0; i < modalcontent.length; i++) {
            modalcontent[i].className = "ant-modal-content";
        }
    }

    modalcountDown() {
        let secondsToGo = 2;
        const timer = setInterval(() => {
        }, 1000);
        setTimeout(() => {
            clearInterval(timer);
            this.setState({ successModalVisible: false });
        }, secondsToGo * 1000);


    }

    onSearch() {
        this.setState(state => {
            return {
                search: {
                    name: state.name
                }
            }
        });
        this.updateWindowDimensions();
    }

    setStateByProps(parameters, value) {
        value ? value : "";
        this.setState({ [parameters]: value });

    }

    render() {
        const { loading, message, messageSuccess, warningModalVisible, successModalVisible, users, search, templateItem, action, dateRange, screenwidth } = this.state;
        const routes = [
            {
                path: 'communication',
                breadcrumbName: 'Communication',
            },
            {
                path: 'sender/list',
                breadcrumbName: 'Sender List',
            },
        ];
        var styleinput_name = {
            width: (screenwidth),
            marginRight: 8,
            marginBottom: 8,
            minWidth: 200
        };

        return (
            <div className="animated fadeIn">
                <PageHeader breadcrumb={{ routes }}>
                </PageHeader>

                <div className="content">
                    <Card>
                        <Row gutter={8} className="row-nowrap" id="main">
                            <Col xs={24}>
                                <Input placeholder="Sender name" style={styleinput_name} onChange={(e) => this.setStateByProps("name", e.target.value)} />

                                <Button icon="search" onClick={this.onSearch}>
                                    Search
                                </Button>
                            </Col>
                        </Row>

                        <Row gutter={8} type="flex" justify="center">
                            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                <TableSender
                                    search={search}
                                    templateItem={templateItem}
                                    action={action}
                                    warningModal={this.warningModal}
                                    setStateByProps={this.setStateByProps}
                                    updateWindowDimensions={this.updateWindowDimensions}
                                />

                            </Col>
                        </Row>



                    </Card>

                </div>

            </div>
        );
    }
}



/* eslint-disable */
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import {
    PageHeader, Button, Descriptions, Select, Row, Col, DatePicker, Icon, Form, Input, Card,
    Tooltip, Progress, Table, Divider, Tag, Modal, Checkbox, Spin, Radio
} from 'antd';
import ButtonGroupStatusAcc from '../../../components/Wop/Button/ButtonGroupStatusAcc';
import ButtonGroupSubAcc from '../../../components/Wop/Button/ButtonGroupSubAcc';

import { apiClient } from '../../../api';
import{root} from '../../../config';
import { token, validateEmail, CheckTokenExp } from '../../../config';
import moment from 'moment';
import 'moment-timezone';
import ModalSuccess from '../../../components/Wop/Modal/ModalSuccess';
import ModalWarning from '../../../components/Wop/Modal/ModalWarning';

moment.tz.setDefault("Asia/Bangkok");

const dateFormat = 'DD/MM/YYYY';
const { MonthPicker, RangePicker, WeekPicker, } = DatePicker;

export default class SubAccountDetail extends Component {
    constructor(props) {
        super(props);

        this.state = {
            warningModalVisible: false,
            successModalVisible: false,
            loading: false,
            message: "",
            messageSuccess: "",
            name: "",
            users: [],
            search: {
                name: "",
                users: [],
                dateRange: [null, null]
            },
            page: "",
            templateItem: [],
            action: "",
            dateRange: [null, null],
            screenwidth: 0,
            screenwidth2: 0,
            MadalAddUservisible: false,
            id: null,
            addusername: "",
            addpassword: "",
            addstatusacc: 1,
            tocreate: false,
            creactsubeaccount: false,
            level: 0,
            admin: false,
            createuser: false,
            owner: false,
            password: "",
            repassword: "",
            addrepassword: "",
            uemail: "",
            emailError: false,
            uemailError: false,
        };

        this.warningModal = this.warningModal.bind(this);
        this.successModal = this.successModal.bind(this);
        this.modalcountDown = this.modalcountDown.bind(this);
        this.getAccountDetailData = this.getAccountDetailData.bind(this);
        this.accountUpdate = this.accountUpdate.bind(this);
        this.setStateByProps = this.setStateByProps.bind(this);
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
        this.passwordFilter = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^\da-zA-Z]).{6,}$/
    }

    componentDidMount() {
        this.updateWindowDimensions();
        window.addEventListener("resize", this.updateWindowDimensions.bind(this));
    }

    componentDidUpdate(prevProps) {
        if (this.props.accdetailId !== prevProps.accdetailId) {
            this.getAccountDetailData(this.props.accdetailId);
        }
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.updateWindowDimensions.bind(this));
    }

    updateWindowDimensions() {
        setTimeout(() => {
            var root = document.getElementById("root");
            if (root) {
                var e = document.getElementById("main");
                if (e) {
                    var scroll = root.offsetHeight > window.innerHeight;
                    this.setState({ screenwidth: scroll ? e.offsetWidth - 124 : e.offsetWidth - 124 })
                }
            }
        }, 500);
    }

    warningModal(value, message, messageSuccess) {
        this.setState({ warningModalVisible: value, message, messageSuccess });
    }

    successModal(value) {
        if (this.state.action == "accountcreate") {
            this.setState({ action: "" });
            this.accountUpdate();
            this.setState({ successModalVisible: value });
        }

        if (this.state.action == "discard") {
            this.setState({ action: "" });
            this.props.discard("subaccount")
        }

        let modalcontent = document.querySelectorAll('.ant-modal-contentant-modal-content-hide');
        for (var i = 0; i < modalcontent.length; i++) {
            modalcontent[i].className = "ant-modal-content";
        }
    }

    modalcountDown() {
        let secondsToGo = 2;
        const timer = setInterval(() => {
        }, 1000);
        setTimeout(() => {
            clearInterval(timer);
            this.setState({ successModalVisible: false });
        }, secondsToGo * 1000);


    }

    getAccountDetailData(value) {
        if (!CheckTokenExp()) {
            if (value != "") {
                this.setState({ loading: true, accountId: value });

                this.setState(state => {
                    let req = JSON.stringify({
                        accountId: value,
                    });

                    apiClient.post('api/usersmanage/AccountDetail', req
                        , {
                            headers: {
                                'Authorization': `Bearer ${token}`,
                                'Content-type': 'application/json; charset=UTF-8'
                            }
                        })
                        .then(data => {
                            var json = data.data;
                            if (json) {
                                this.setState(state => {
                                    return {
                                        accname: json.name,
                                        email: json.email,
                                        statusacc: json.status,
                                        username: json.username,
                                        currentpage: state.currentpage,
                                        paytype: json.pay_type,
                                        creactsubeaccount: json.creactsubeaccount,
                                        level: json.level,
                                        emailError: validateEmail(json.email)
                                    };
                                });
                            }
                            this.setState({ loading: false });
                        })
                        .catch(error => {
                            this.setState({ loading: false });
                        });
                });
            }
        } else {
            window.location.href = "logout";
        }

    }

    setStateByProps(parameters, value) {
        value ? value : "";

        if (parameters == "username") {
            if (value.match(/^[A-Za-z0-9 /./+/*/@/\\_-]+$/i) || value == "") {
                this.setState({ [parameters]: value });
            }
        } else {
            this.setState({ [parameters]: value });

            if (parameters == "email") {
                this.setState({ emailError: validateEmail(value) });
            } else if (parameters == "uemail") {
                this.setState({ uemailError: validateEmail(value) });
            }
        }
        this.setState({ errorMessage: "" });
    }

    accountUpdate() {
        if (!CheckTokenExp()) {
            this.setState({ loading: true });

            let req = JSON.stringify({
                accountId: this.state.accountId,
                name: this.state.accname,
                email: this.state.email,
                username: this.state.username,
                password: this.state.password,
                paytype: this.state.paytype,
                status: this.state.statusacc,
                creactsubeaccount: this.state.creactsubeaccount == 0?false:(this.state.creactsubeaccount==1?true:this.state.creactsubeaccount) ,
            });

            apiClient.post('api/usersmanage/AccountUpdate', req
                , {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-type': 'application/json; charset=UTF-8'
                    }
                })
                .then(data => {
                    var json = data.data;

                    this.setState({ loading: false });
                })
                .catch(error => {
                    this.setState({ loading: false });
                });
        } else {
            window.location.href = "logout";
        }

    }

    render() {
        const { loading, message, messageSuccess, warningModalVisible, successModalVisible, users, search, templateItem,
            action, dateRange, screenwidth, screenwidth2, accname, email, statusacc, username, paytype, MadalAddUservisible,
            id, addusername, addpassword, repassword, password, addstatusacc, tocreate, creactsubeaccount, level, admin, createuser,
            owner, addrepassword, uemail, emailError, uemailError } = this.state;
        const paytypetext = paytype == 1 ? "Prepaid" : "Postpaid";

        return (
            <div className="content" id="main">
                <Row gutter={[16, 16]}>
                    <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                        <label className=" control-label require" style={{ fontWeight: 700, marginBottom: "0rem" }}>Account name</label>
                        <br />
                        &nbsp; &nbsp; <label className=" control-label ant-fontsize-acc" style={{ fontWeight: 600 }}>{accname}</label>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                        <label className=" control-label require" style={{ fontWeight: 700, marginBottom: "0rem" }}>Username</label>
                        <br />
                        &nbsp; &nbsp; <label className=" control-label ant-fontsize-acc" style={{ fontWeight: 600 }}>{username}</label>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                        <label className=" control-label require" style={{ fontWeight: 700, marginBottom: "0rem" }}>Payment type</label>
                        <br />
                        &nbsp; &nbsp; <label className=" control-label ant-fontsize-acc" style={{ fontWeight: 600 }}>{paytypetext}</label>
                    </Col>
                </Row>
                <Row gutter={[16, 16]}>
                    <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                        <label className=" control-label require" style={{ fontWeight: 700, marginBottom: "0rem" }}>Email</label>
                        <Input placeholder="Email" value={email} onChange={(e) => this.setStateByProps("email", e.target.value)} maxLength={100} />
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                        <label className=" control-label require" style={{ fontWeight: 700, marginBottom: "0rem" }}>Password</label>
                        <Input.Password id="SADPassword" name="SADPassword" placeholder="Password" value={password} onChange={(e) => this.setStateByProps("password", e.target.value)} maxLength={30} />
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                        <label className=" control-label require" style={{ fontWeight: 700, marginBottom: "0rem" }}>Re-type password</label>
                        <Input.Password placeholder="Re-type Password" value={repassword} onChange={(e) => { this.setStateByProps("repassword", e.target.value) }} maxLength={30} />
                    </Col>
                </Row>
                <Row gutter={[16, 16]}>
                    <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                        <label className=" control-label require" style={{ fontWeight: 700, marginBottom: "0rem" }}>Create Subaccount</label>
                        <br />
                        <ButtonGroupSubAcc tocreate={"creactsubeaccount"} radio={creactsubeaccount ? 1 : 0} setStateByProps={this.setStateByProps} disabled={level > 3 ? true : false} />
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                        <label className=" control-label require" style={{ fontWeight: 700, marginBottom: "0rem" }}>Status</label>
                        <br />
                        <ButtonGroupStatusAcc radio={statusacc} setStateByProps={this.setStateByProps} />
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={8} xl={8}>

                    </Col>
                </Row>
                <Row gutter={[16, 16]} style={{ textAlign: "end", marginTop: 80 }}>
                    <Row justify="end">
                        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                            <div>
                            <label className="red" style={{ marginLeft: 8, display: (!this.passwordFilter.test(password) && password === repassword) ? 'none' : '' }}>{
                                password ?
                                    this.passwordFilter.test(password) ?
                                        password === repassword ? ""
                                            : "Passwords do not match"
                                        : <div className='text-left'>
                                            <div>
                                                - The password must be at least 6 characters long
                                            </div>
                                            <div>
                                                - Uppercase and Lowercase is required
                                            </div>
                                            <div>
                                                - Number is required
                                            </div>
                                            <div>
                                                - Special character is required
                                            </div>
                                        </div>
                                    : ""
                            }</label>
                            </div>
                            <Button key="submit" style={{ marginRight: 8 }}
                                onClick={() => { this.warningModal(true, "Do you want to discard change?", "Discarded change successfully."), this.setState({ action: "discard" }) }}>
                                <span className="fa fa-trash-o"></span> &nbsp;&nbsp;
                                Discard
                            </Button>
                            <Button type="primary" style={{ marginRight: 8 }}
                                disabled={(password === repassword) && accname && email && username && emailError && this.passwordFilter.test(password) ? false : true}
                                onClick={() => { this.warningModal(true, "Do you want to save this account?", "Save account successfully."), this.setStateByProps('action', 'accountcreate') }}>
                                <span className="fa fa-plus-circle"></span> &nbsp;&nbsp;
                                Save
                            </Button>
                        </Col>
                    </Row>
                </Row>


                <ModalWarning loading={loading}
                    warningModalVisible={warningModalVisible}
                    warningModal={this.warningModal}
                    successModal={this.successModal}
                    modalcountDown={this.modalcountDown}
                    message={message}
                    messageSuccess={messageSuccess}
                />

                <ModalSuccess loading={loading}
                    successModalVisible={successModalVisible}
                    successModal={this.successModal}
                    messageSuccess={messageSuccess}
                />
            </div>

        );
    }
}
/* eslint-disable */
import React, { Component } from 'react';
import {
    Form, Input, DatePicker, TimePicker, Select, Cascader, InputNumber, Icon, Row, Col,
    Button, Tooltip, Checkbox, Spin, PageHeader, Card, Menu, Dropdown, Modal, Upload, message
} from 'antd';

import { token, CheckTokenExp } from '../../config';

import CardIssueReport from '../../components/Wop/Card/CardIssueReport';
import { apiClient } from '../../api';
import ModalSuccess from '../../components/Wop/Modal/ModalSuccess';
import ModalWarning from '../../components/Wop/Modal/ModalWarning';
import { UNMOUNTED } from 'react-transition-group/Transition';

import TableContactUs from '../../components/Wop/Table/TableContactUs';

const { Option } = Select;
const { TextArea } = Input;

function getBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
}

export default class IssueReport extends Component {
    constructor(props) {
        super(props);

        this.state = {
            visible: false,
            loading: false,
            data: [],
            subject: undefined,
            fileList: [],
            warningModalVisible: false,
            successModalVisible: false,
            textmessage: "",
            value: "",
            startdata: "",
            enddata: "",
            saveData: "",
            search: {
                value: "",
                saveData: "",
            }
        };

        this.showModal = this.showModal.bind(this);
        this.handleOk = this.handleOk.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
        this.getData = this.getData.bind(this);
        this.saveData = this.saveData.bind(this);
        this.setStateByProps = this.setStateByProps.bind(this);
        this.beforeUpload = this.beforeUpload.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.warningModal = this.warningModal.bind(this);
        this.successModal = this.successModal.bind(this);
        this.modalcountDown = this.modalcountDown.bind(this);
        this.onRemove = this.onRemove.bind(this);
    }

    componentDidMount() {
        this.getData();
    }

    showModal() {
        this.setState({
            visible: true,
        });
    };

    handleOk(e) {
        this.setState({
            visible: false,
        });
    };

    handleCancel(e) {
        this.setState({
            visible: false,
        });

        this.setState({ formData: [], fileList: [], question: "", subject: undefined, textmessage: "" });
        this.setState({ subject: undefined, message: "", imageUrl: null, fileList: [] });
    };

    getData() {
        this.setState(state => {
            return {
                search: {
                    value: state.value,
                    saveData: state.saveData
                }
            }
        });
    }

    saveData() {
        if (!CheckTokenExp()) {
            this.setState({ loading: true });

            this.setState(state => {
                const { fileList } = this.state;
                const formData = new FormData();
                fileList.forEach(file => {
                    formData.append('files[]', file);
                });

                let req = JSON.stringify({
                    "subject": state.subject,
                    "question": state.textmessage
                });

                formData.append('req', req);
                apiClient.post('api/issuereport/save', formData
                    , {
                        headers: {
                            'Authorization': `Bearer ${token}`,
                            'Content-type': 'application/json; charset=UTF-8'
                        }
                    })
                    .then(data => {
                        var json = data.data;

                        if (json) {
                            this.setState({ subject: undefined, textmessage: "", imageUrl: null, fileList: [], formData: [], saveData: "ok" });

                            this.getData();

                            this.handleCancel();
                        } else {
                            this.setState({ modal_visible: false, });
                        }

                        this.setState({ loading: false });
                    })
                    .catch(error => {
                        this.setState({ subject: undefined, message: "", imageUrl: null, fileList: [] });
                        this.setState({ loading: false });
                    });
            });
        } else {
            window.location.href = "logout";
        }

    }

    setStateByProps(parameters, value) {
        value ? value : "";
        this.setState({ [parameters]: value });
    }

    handleChange(info) {
        if (info.file.status === 'uploading') {
            this.setState({ loading: true });
            return;
        }
        if (info.file.status === 'done') {
            // Get this url from response in real world.
            getBase64(info.file.originFileObj, imageUrl =>
                this.setState({
                    imageUrl,
                    loading: false,
                }),
            );
        }
    };

    beforeUpload(file) {
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isLt2M) {
            message.error('Image must smaller than 2MB!');
        }

        this.setState({ fileList: [file] });
        return false;
    }

    warningModal(value, message, messageSuccess) {
        this.setState({ warningModalVisible: value, message, messageSuccess });
    }

    successModal(value) {
        if (this.state.action == "discard") {
            this.handleCancel();
            this.setState({ action: "" });
            value = false;
        }
        if (this.state.action == "save") {
            this.saveData();
            this.setState({ action: "" });
        }

        this.setState({ successModalVisible: value });
    }

    modalcountDown() {
        let secondsToGo = 2;
        this.setState(state => {
            if (state.successModalVisible) {
                setTimeout(() => {
                    this.setState({ successModalVisible: false });
                }, secondsToGo * 1000);
            }
        });
    }

    onRemove(file, fileList) {
        var fileremove = this.state.fileList.filter(f => f.name != file.name);

        this.setState({ fileList: fileremove });



    }

    render() {
        const { loading, textmessage, message, messageSuccess, warningModalVisible, successModalVisible } = this.state;
        const { data, image, subject, search } = this.state;

        const uploadButton = (
            <div>
                {
                    this.state.loading ?
                        <Icon type='loading' />
                        : <span className="fa fa-plus-circle" style={{ fontSize: 35, color: '#ffffff' }}></span>
                }
            </div>
        );

        const { imageUrl } = this.state;
        const styleMarginBottom = { marginBottom: 16 };
        const routes = [
            {
                path: '/contactus',
                breadcrumbName: 'Contact Us',
            },
        ];

        const props = {
            name: "avatar",
            beforeUpload: this.beforeUpload,
            fileList: this.state.fileList,
            onRemove: this.onRemove,

        }

        return (
            <div className="animated fadeIn" >
                <PageHeader breadcrumb={{ routes }} >
                    <Row gutter={16} type="flex" justify="end" style={{ textAlign: 'right' }}>
                        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                            <Button type="primary" style={{ marginRight: 8 }} onClick={this.showModal}>
                                <span className="fa fa-plus-circle"></span> &nbsp;&nbsp;
                                New
                        </Button>
                        </Col>
                    </Row>
                </PageHeader>

                <div className="content">
                    <Card>
                        <TableContactUs search={search} setStateByProps={this.setStateByProps} />
                    </Card>
                </div>

                <Modal
                    title={<div>Contact Us</div>}
                    visible={this.state.visible}
                    onOk={this.handleOk}
                    onCancel={() => {}}
                    width={760}
                    zIndex={1020}
                    footer={[
                        <Row gutter={16} type="flex" justify="end">
                            <Col xs={24} sm={24} md={24} lg={24} xl={12}>
                            </Col>
                            <Col xs={24} sm={24} md={24} lg={24} xl={12}>
                                <div style={{ textAlign: "right", marginRight: 24 }}>
                                    <Button key="submit" style={{ marginRight: 8 }}
                                        onClick={() => { this.setState({ action: "discard" }), this.warningModal(true, "Do you want to cancel change?", "") }}>
                                        <span className="fa fa-trash-o"></span> &nbsp;&nbsp;
                                       Cancel
                                    </Button>
                                    <Button key="submit" type="primary" style={{ marginRight: 8 }}
                                        disabled={textmessage && subject ? false : true}
                                        loading={loading}
                                        onClick={() => { this.setState({ action: "save" }), this.warningModal(true, "Do you want to send this contect?", "Send contect successfully.") }}>
                                        <span className="fa fa-paper-plane-o"></span> &nbsp;&nbsp;
                                       Submit
                        </Button>
                                </div>

                            </Col>
                        </Row>

                    ]}
                >
                    <div>
                        <Row gutter={16} type="flex" justify="end" style={styleMarginBottom}>
                            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                <Select placeholder="Select Subject" onChange={(e) => this.setStateByProps("subject", e)} value={subject}>
                                    <Option value="Report system usage problems">Report system usage problems</Option>
                                    <Option value="Buy credits">Buy credits</Option>
                                    <Option value="Other">Other</Option>
                                </Select>
                            </Col>
                        </Row>

                        <Row gutter={16} type="flex" justify="end" style={styleMarginBottom}>
                            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                <TextArea
                                    value={textmessage}
                                    onChange={(e) => this.setStateByProps("textmessage", e.target.value)}
                                    placeholder="Fill out additional information / choose a package"
                                    autoSize={{ minRows: 5, maxRows: 10 }}
                                />
                            </Col>
                        </Row>

                        <Row gutter={16} type="flex" justify="end" style={styleMarginBottom}>
                            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                <Upload{...props}>
                                    <Button>
                                        <Icon type="upload" /> Upload
                                    </Button>
                                </Upload>
                            </Col>
                        </Row>

                    </div>
                </Modal>

                <ModalWarning loading={loading}
                    warningModalVisible={warningModalVisible}
                    warningModal={this.warningModal}
                    successModal={this.successModal}
                    modalcountDown={this.modalcountDown}
                    message={message}
                    messageSuccess={messageSuccess}
                />

                <ModalSuccess loading={loading}
                    successModalVisible={successModalVisible}
                    successModal={this.successModal}
                    messageSuccess={messageSuccess}
                />

            </div >
        );
    }
}



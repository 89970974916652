/* eslint-disable */
import React, { Component } from 'react';
import { Link } from "react-router-dom";
import {
    Form, Input, DatePicker, TimePicker, Select, Cascader, InputNumber, Icon, Row, Col,
    Button, Tooltip, Checkbox, Spin, PageHeader, Card, Menu, Dropdown, Modal, Collapse
} from 'antd';
import moment from 'moment';
import 'moment-timezone';
import { apiClient } from '../../../api';
import { root } from '../../../config';
import { token, getUrlVars, validateEmail, CheckTokenExp, ReplaceUrlTaga } from '../../../config';
import Card3 from "../../../components/Wop/Card/Card3";

import ModalSuccess from '../../../components/Wop/Modal/ModalSuccess';
import ModalWarning from '../../../components/Wop/Modal/ModalWarning';

import SelectReportType from '../../../components/Wop/Select/SelectReportType';

import Detailreportnew from './Detailreportnew';
import SummeryReport from './SummeryReportnew';

moment.tz.setDefault("Asia/Bangkok");

const { RangePicker, } = DatePicker;
const dateTimeFormat = 'DD/MM/YYYY HH:mm:ss';
const { Panel } = Collapse;

export default class
    extends Component {
    constructor(props) {
        super(props);

        this.state = {
            warningModalVisible: false,
            successModalVisible: false,
            errorModalVisible: false,
            loading: false,
            loadingtestftp: false,
            message: "",
            messageSuccess: "",
            messageerror: "",
            reportType: 1,
            pagename: "",
            recurring: undefined,
            cb_recurring: false,
            cb_alert: false,
            campaign: [],
            users: [],
            status: [],
            sender: [],
            account: [],
            otp: [],
            filter: [],


            statuslist: [],
            reporttype_list: [],

            timestart: "",
            timeend: "",

            reportrecurrencetype: 1,
            report_Template: 0,
            reportname: "",
            recuring_type: 0,
            onetimereport_type: 0,
            startdate: "",
            enddate: "",
            startdisplaydate: "",
            enddisplaydate: "",

            recuring_flag: false,
            recuring_type: 0,
            recuring_value: 0,
            recuring_reportrange: "",
            recuring_reportvalue: "",

            alert_flag: false,
            emailError: false,
            email: [],

            ftp_flag: false,
            ftp_id: undefined,
            ftp_protocal: 1,
            ftp_host: "",
            ftp_port: "",
            ftp_username: "",
            ftp_password: "",
            ftp_path: '/',
            ftp_list: [],
            ftp_teststatus: 0,

            ftpraedonly: true,

        };


        this.warningModal = this.warningModal.bind(this);
        this.successModal = this.successModal.bind(this);
        this.modalcountDown = this.modalcountDown.bind(this);
        this.setStateByProps = this.setStateByProps.bind(this);
        this.covertformatdate = this.covertformatdate.bind(this);
        this.setreportrange = this.setreportrange.bind(this);
        this.getdataornewftp = this.getdataornewftp.bind(this);
        this.getDataFtp = this.getDataFtp.bind(this)


        this.getDisabledStartHours = this.getDisabledStartHours.bind(this);
        this.getDisabledEndHours = this.getDisabledEndHours.bind(this);
        this.getDisabledStartMinutes = this.getDisabledStartMinutes.bind(this);
        this.getDisabledEndMinutes = this.getDisabledEndMinutes.bind(this);
        this.disabledEndDate = this.disabledEndDate.bind(this);
        this.getData = this.getData.bind(this);
        this.getdataornewftp = this.getdataornewftp.bind(this);
        this.cleardata = this.cleardata.bind(this);
        this.cleardataftp = this.cleardataftp.bind(this);
        this.testftp = this.testftp.bind(this);
        this.setStateDateTime = this.setStateDateTime.bind(this);
    }

    componentDidMount() {
        /*  this.getData() */
        ReplaceUrlTaga();
    }


    cleardata() {
        this.setState({
            reportname: "",
            account: [],
            users: [],
            status: [],
            campaign: [],
            email: [],
            sender: [],
            alert_flag: false,
            ftp_flag: false,
            /* ftp_id: "", */
            ftp_protocal: 1,
            ftp_host: "",
            ftp_port: "",
            ftp_username: "",
            ftp_password: "",
            /* ftp_path: "/", */
            ftpraedonly: true,

        });


    }

    cleardataftp() {
        this.setState({
            ftp_id: undefined,
            ftp_protocal: 1,
            ftp_host: "",
            ftp_port: "",
            ftp_username: "",
            ftp_password: "",
            /* ftp_path: "/", */
            ftpraedonly: true,
        });


    }


    getData() {
        if (getUrlVars()["id"]) {
            this.setState({ loading: true });
            apiClient.get('api/report/Getdatareport/' + getUrlVars()["id"]
                , {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-type': 'application/json; charset=UTF-8'
                    }
                })
                .then(data => {
                    var json = data.data;

                    this.setStateByProps("report_Template", json.rpt_type)
                    if (json.rpt_type > 3) {
                        this.setStateByProps("reportType", 2)
                    }
                    this.setStateByProps("account", json.filter_ACCOUNTS_ID.map((i) => Number(i)))
                    this.setStateByProps("reportname", json.rpt_name)
                    this.setStateByProps("users", json.filter_USR_ID)
                    this.setStateByProps("campaign", json.filter_CAMP_ID)
                    this.setStateByProps("status", json.filter_STATUS_CODE)
                    this.setStateByProps("sender", json.filter_SEND_NAME)

                    this.setStateByProps("report_Template", json.rpt_type)
                    this.setStateByProps("ftp_flag", json.ftp_flag)
                    this.setStateByProps("alert_flag", json.alert_flag)
                    this.setStateByProps("ftp_id", json.ftp_id)
                    if (json.onetimereport_type != 0) {
                        this.setStateByProps("reportrecurrencetype", 1)
                        this.setStateByProps("onetimereport_type", json.onetimereport_type)
                    } else {

                        this.setStateByProps("reportrecurrencetype", 2)

                        this.setStateByProps("recuring_value", json.recuring_value == 0 ? null : json.recuring_value)
                        this.setStateByProps("recuring_type", json.recuring_type)

                    }
                    this.setStateByProps("filter", eval(json.filter))

                    if (this.state.onetimereport_type == 8) {
                        for (let i = 0; i <= this.state.filter.length; i++) {

                            if (this.state.filter[i].rpt_key == 'SMS_SCHEDULE') {


                                var timelist = this.state.filter[i].rpt_display_value.split(" ")

                                this.setStateByProps("startdate", timelist[0])
                                this.setStateByProps("timestart", timelist[1])
                                this.setStateByProps("enddate", timelist[3])
                                this.setStateByProps("timeend", timelist[4])

                            }

                        }

                    }
                    this.setState({ loading: false });
                })
                .catch((error) => {

                    this.setState({ loading: false });
                })

        }
    }

    setStateByProps(parameters, value) {
        value ? value : "";

        if (parameters == "reportname") {
            var pattern = new RegExp(/^[^\\/:\*\?"<>\|]+$/);
            if (pattern.test(value)) {
                this.setState({ [parameters]: value });
            } else {
                if (value == "") {
                    this.setState({ [parameters]: "" });
                }
            }
        }
        else {
            if (parameters == "account") {
                this.setState({ users: [] });
            }

            if (parameters == "status") {
                if (value && value.length && value.includes("all")) {
                    if (value.length === this.state.statuslist.length + 1) {
                        this.setState({ [parameters]: [] });
                    } else {
                        this.setState({ [parameters]: this.state.statuslist.map((item) => item.ID.toString()) }, () => { });
                    }
                }
                else {
                    this.setState({ [parameters]: value });
                }
            } else if (parameters == "email") {
                this.setState({ [parameters]: value });

                value.forEach(element => {
                    this.setState({ emailError: validateEmail(element) });// a@cc.com b@cc.com split(" ")
                });
            }
            else if (parameters == "ftp_path") {
                if (value.length < 1) {

                    this.setState({ [parameters]: '/' }, () => { });
                } else if (value.substring(0, 1) != '/') {
                    this.setState({ [parameters]: '/' }, () => { });
                }
                else {
                    this.setState({ [parameters]: value });
                }
            }

            else {
                this.setState({ [parameters]: value }, () => { });
            }

            if (parameters == "startdate") {
                this.setState({ startdate: moment(value).format('YYYY/MM/DD'), startdisplaydate: moment(value).format('DD/MM/YYYY') }, () => { });
            }
            if (parameters == "enddate") {
                this.setState({ enddate: moment(value).format('YYYY/MM/DD'), enddisplaydate: moment(value).format('DD/MM/YYYY') }, () => { });
            }

            if (parameters == "timestart") {
                this.setState({ timestart: moment(value).format('HH:mm:00') }, () => { });
                this.setState({ enddate: "", timeend: "" });
            }

            if (parameters == "timeend") {
                this.setState({ timeend: moment(value).format('HH:mm:00') }, () => { });
            }

            if (parameters === "onetimereport_type" && value === 8) {
                this.setState({
                    startdate: "",
                    enddate: "",
                    startdisplaydate: "",
                    enddisplaydate: ""
                });
            }
        }



    }

    setStateDateTime(name, value) {
        if (name === "startdate") {
            this.setState({ startdate: value === null ? "" : moment(value).format('YYYY/MM/DD'), startdisplaydate: moment(value).format('DD/MM/YYYY'), timestart: "", enddate: "", timeend: "" })
        } else if (name === "enddate") {
            this.setState({ enddate: value === null ? "" : moment(value).format('YYYY/MM/DD'), enddisplaydate: moment(value).format('DD/MM/YYYY'), timeend: "" })
        } else if (name === "timestart") {
            this.setState({ timestart: value === null ? "" : moment(value).format('HH:mm:00'), enddate: "", timeend: "" })
        } else if (name === "timeend") {
            this.setState({ timeend: value === null ? "" : moment(value).format('HH:mm:00') })
        }
    }

    warningModal(value, message, messageSuccess) {
        this.setState({ warningModalVisible: value, message, messageSuccess });

        let modalcontent = document.querySelectorAll('.ant-modal-contentant-modal-content-hide');
        for (var i = 0; i < modalcontent.length; i++) {
            modalcontent[i].className = "ant-modal-content";
        }
    }

    successModal(value) {
        if (this.state.action == "create") {
            this.save();
            this.setState({ action: "" });
        }

        if (this.state.action == "discard") {
            this.props.history.push('/report/');
            value = false;
            this.setState({ action: "" });
        }


        let modalcontent = document.querySelectorAll('.ant-modal-contentant-modal-content-hide');
        for (var i = 0; i < modalcontent.length; i++) {
            modalcontent[i].className = "ant-modal-content";
        }
    }

    modalcountDown() {
        this.setState(state => {
            let secondsToGo = 2;
            if (state.successModalVisible) {
                setTimeout(() => {
                    this.setState({ successModalVisible: false });
                    this.props.history.push('/report/');
                }, secondsToGo * 1000);
            }
            if (state.errorModalVisible) {
                let secondsToGoend = 5;
                setTimeout(() => {
                    this.setState({ errorModalVisible: false });
                }, secondsToGoend * 1000);
            }
        });
    }

    save() {
        if (!CheckTokenExp()) {
            this.setState({ loading: true });

            let datestart = this.state.onetimereport_type == 8 ? moment(this.state.startdate).format('YYYY-MM-DD') + " " + moment(this.state.timestart, 'HH:mm:00').format('HH:mm:00').toString() : this.state.startdate.toString();
            let dateend = this.state.onetimereport_type == 8 ? moment(this.state.enddate).format('YYYY-MM-DD') + " " + moment(this.state.timeend, 'HH:mm:00').format('HH:mm:59').toString() : this.state.enddate.toString();
            let datestartdisplay = this.state.onetimereport_type == 8 ? this.state.startdisplaydate + " " + moment(this.state.timestart, 'HH:mm:00').format('HH:mm').toString() : this.state.startdisplaydate.toString();
            let dateenddisplay = this.state.onetimereport_type == 8 ? this.state.enddisplaydate + " " + moment(this.state.timeend, 'HH:mm:00').format('HH:mm').toString() : this.state.enddisplaydate.toString();

            let req = JSON.stringify({
                rpt_type: this.state.report_Template,
                rpt_name: this.state.reportname.trim(),
                onetimereport_type: this.state.onetimereport_type,

                filter_CAMP_ID: this.state.campaign,
                filter_STATUS_CODE: this.state.status,
                filter_ACCOUNTS_ID: this.state.account,
                filter_USR_ID: this.state.users,
                filter_SEND_NAME: this.state.sender,
                filter_SMS_STARTSCHEDULE: datestart,
                filter_SMS_ENDSCHEDULE: dateend,
                filter_SMS_STARTDISPLAYSCHEDULE: datestartdisplay,
                filter_SMS_ENDDISPLAYSCHEDULE: dateenddisplay,
                filter_OTP: this.state.otp,

                alert_flag: this.state.alert_flag,
                alert_email: this.state.email,

                ftp_flag: this.state.ftp_flag,
                ftp_id: this.state.ftp_id == 'New' ? 0 : this.state.ftp_id,
                ftp_export_type: 0,
                ftp_protocal: this.state.ftp_protocal,
                ftp_host: this.state.ftp_host,
                ftp_port: this.state.ftp_port,
                ftp_username: this.state.ftp_username,
                ftp_password: this.state.ftp_password,
                ftp_path: this.state.ftp_path,

                recuring_flag: this.state.recuring_flag,
                recuring_type: this.state.recuring_type,
                recuring_reportrange: this.state.recuring_reportrange,
                recuring_value: this.state.recuring_value,

            });

            apiClient.post('api/report/addreportnew', req
                , {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-type': 'application/json; charset=UTF-8'
                    }
                })
                .then(data => {
                    var json = data.data;
                    if (data.data.Description == 'Create report success.') {
                        this.setState({
                            loading: false,
                            successModalVisible: true
                        });
                        this.modalcountDown();
                    } else {
                        this.setState({
                            loading: false,
                            errorModalVisible: true,
                            messageerror: data.data.Description
                        });
                        this.modalcountDown();
                    }

                })
                .catch(error => {
                    this.setState({
                        loading: false,
                        successModalVisible: false,
                        errorModalVisible: true,
                        messageerror: 'Create report error please contact the administrator.'

                    });
                    this.modalcountDown();
                });
        } else {
            window.location.href = "logout";
        }
    }

    testftp() {
        if (!CheckTokenExp()) {
            this.state.ftp_list.length > 0 ? "" : this.setState({ loadingtestftp: true });
            let req = JSON.stringify({


                "host": this.state.ftp_host,
                "port": this.state.ftp_port,
                "username": this.state.ftp_username,
                "password": this.state.ftp_password,
                "remotepath": this.state.ftp_path,
                "protocal": this.state.ftp_protocal.toString(),

            });
            apiClient.post('api/report/Testconectionftp', req
                , {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-type': 'application/json; charset=UTF-8'
                    }
                })
                .then(data => {
                    var json = data.data;
                    if (data.data == true) {
                        this.setState({ ftp_teststatus: 1 }, () => { });
                    } else {
                        this.setState({ ftp_teststatus: 2 }, () => { });
                    }

                    this.setState({ loadingtestftp: false });
                })
                .catch(error => {
                    this.setState({ loadingtestftp: false });
                });
        } else {
            window.location.href = "logout";
        }
    }


    getDisabledStartHours() {
        var hours = [];

        if (moment(this.state.startdate).format("DD/MM/YYYY") == moment().format("DD/MM/YYYY")) {
            for (var i = 0; i < 24; i++) {
                if (i > moment().hour()) {
                    hours.push(i);
                }
            }
        }
        return hours;
    }

    getDisabledEndHours() {
        var hours = [];
        if (moment(this.state.enddate).format("DD/MM/YYYY") == moment().format("DD/MM/YYYY")) {
            for (var i = 0; i < 24; i++) {
                if (i > moment().hour()) {
                    hours.push(i);
                }
                if (i < moment(this.state.timestart).format("HH") && moment(this.state.startdate).format("DD/MM/YYYY") == moment(this.state.enddate).format("DD/MM/YYYY")) {
                    hours.push(i);
                }
            }
        }

        if (moment(this.state.startdate).format("DD/MM/YYYY") == moment(this.state.enddate).format("DD/MM/YYYY")) {
            for (var i = 0; i < 24; i++) {
                if (i < moment(this.state.timestart).format("HH")) {
                    hours.push(i);
                }
            }
        }
        return hours;
    }

    getDisabledStartMinutes() {
        var minute = [];

        if (!this.state.timestart) {
            for (var i = 0; i < 60; i++) {
                minute.push(i);
            }
        }

        if (moment(this.state.startdate).format("DD/MM/YYYY") == moment().format("DD/MM/YYYY") &&
            moment(this.state.timestart).format("HH") == moment().format("HH")
        ) {
            for (var i = 0; i < 60; i++) {
                if (i > moment().minute()) {
                    minute.push(i);
                }

            }
        }
        return minute;
    }

    getDisabledEndMinutes() {
        var minute = [];

        if (!this.state.timeend) {
            for (var i = 0; i < 60; i++) {
                minute.push(i);
            }
        }

        if (moment(this.state.enddate).format("DD/MM/YYYY") == moment().format("DD/MM/YYYY") && moment(this.state.timeend).format("HH") == moment().format("HH")) {
            for (var i = 0; i < 60; i++) {
                if (i > moment().minute()) {
                    minute.push(i);
                }
                /* if (i < moment(this.state.timestart).format("mm")) {
                    console.table( i,moment(this.state.timestart).format("mm"))
                    console.table("2")
                    minute.push(i);
                } */
            }
        }

        if (moment(this.state.startdate).format("DD/MM/YYYY") == moment(this.state.enddate).format("DD/MM/YYYY") && moment(this.state.timestart).format("HH") == moment(this.state.timeend).format("HH")) {
            // console.table(moment(this.state.startdate).format("DD/MM/YYYY") ,"==", moment(this.state.enddate).format("DD/MM/YYYY"))
            // console.table(moment(this.state.timestart).format("mm"))
            for (var i = 0; i < 60; i++) {
                if (i < moment(this.state.timestart).format("mm") && i < moment().minute()) {
                    minute.push(i);
                }
                /*  if (i < moment().minute()) {
                     console.table("3")
                     minute.push(i);
                 } */


            }
        }
        return minute;
    }

    disabledEndDate(current) {
        return current && (current <= this.state.startdate || current > moment());
    }

    covertformatdate(start, end) {
        this.setState({
            startdate: moment(start).format('YYYY-MM-DD HH:mm:00'),
            enddate: moment(end).format('YYYY-MM-DD HH:mm:59'),

            startdisplaydate: moment(start).format('DD/MM/YYYY HH:mm'),
            enddisplaydate: moment(end).format('DD/MM/YYYY HH:mm'),


        }, () => {
        });
    }


    setreportrange(typereport, valuereport) {
        switch (typereport) {
            case 1:
                this.setState({ recuring_reportrange: "Yesterday (00:00 - 23:59)" }, () => { });
                break;
            case 2:
                this.setState({ recuring_reportrange: "Previous 7 days (" + valuereport + ")" }, () => { });
                break;
            case 3:
                this.setState({ recuring_reportrange: "Previous month (Last day of month e.g.31)" }, () => { });
                break;
            case 4:
                this.setState({ recuring_reportrange: "Every previous " + valuereport + " day" }, () => { });
                break;
        }

    }


    getdataornewftp(value) {
        if (value != null) {
            if (value != "New") {
                this.getDataFtp(value)
                this.setState({
                    ftp_teststatus: 0,
                    ftp_id: value,
                    ftpraedonly: true
                }, () => { })
            } else {
                this.setState({
                    /* ftp_id: 0,  */
                    ftp_teststatus: 0,
                    ftp_protocal: 1,
                    ftp_host: "",
                    ftp_port: "",
                    ftp_username: "",
                    ftp_password: "",
                    ftp_path: "/",
                    ftpraedonly: false
                }, () => { })
            }
        }

    }
    getDataFtp(id) {
        if (!CheckTokenExp()) {
            this.state.ftp_list.length > 0 ? "" : this.setState({ loading: true });

            apiClient.get('api/report/GetFtpdataformid/' + id
                , {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-type': 'application/json; charset=UTF-8'
                    }
                })
                .then(data => {
                    var json = data.data;
                    this.setState({
                        ftp_id: id,
                        ftp_protocal: json.FTP_PROTOCAL,
                        ftp_host: json.FTP_HOSTNAME,
                        ftp_port: json.FTP_PORT,
                        ftp_username: json.FTP_USERNAME,
                        ftp_password: json.FTP_PASSWORD,
                        ftp_path: json.FTP_PATH,
                    }, () => { })
                    this.setState({ loading: false });
                })
                .catch(error => {
                    this.setState({ loading: false });
                });
        } else {
            window.location.href = "logout";
        }
    }



    render() {
        const { message, messageSuccess, warningModalVisible, successModalVisible, loading } = this.state;
        const { reportType, pagename, recurring, campaign, users, status, sender, account, email, emailError, recurring_range, report_Template, onetimereport_type, ftp_teststatus, loadingtestftp,
            reporttype_list, timestart, timeend, enddate, startdate, reportname, cb_alert, recuring_flag, recuring_type, alert_flag, recuring_value, otp, datestartdisplay, enddisplaydate,
            ftp_flag, ftp_id, ftp_protocal, ftp_host, ftp_port, ftp_username, ftp_password, ftp_path, ftpraedonly, ftp_list, reportrecurrencetype, startdisplaydate } = this.state;

        const styleMarginBottom = { marginBottom: 16 };
        var styleheadinput = {
            fontWeight: 700,
            fontSize: 27
        };
        var styletitleinput = {
            fontWeight: 700,
            marginBottom: -10
        };
        var stylechanneltypeinput = {
            fontWeight: 700,
            paddingLeft: 20
        };
        const routes = [
            {
                path: '/report/',
                breadcrumbName: 'Report',
            },
            {
                breadcrumbName: 'Create',
            },
        ];
        return (
            <div className="animated fadeIn reportpage">

                <PageHeader breadcrumb={{ routes }} >
                </PageHeader>

                <div className="content">
                    <Spin spinning={loading}>
                        <Card className="card-content" style={{ minHeight: 200, paddingLeft: 14, paddingRight: 14 }} >
                            <Row gutter={8, 8} type="flex" justify="start" >
                                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                    <label className=" control-label require" style={styleheadinput}>Report type </label>
                                </Col>
                            </Row>
                            <Row gutter={16, 24} style={styleMarginBottom}>
                                <Col xs={24} sm={24} md={24} lg={24} xl={12}>
                                    <Card
                                        onClick={(e) => { this.setState({ reportType: 1, reportrecurrencetype: 1, startdate: "", enddate: "", timestart: "", timeend: "" }) }}
                                        className={this.state.reportType == 1 ? 'Cardfixfocus' : 'Cardfix'}
                                    >
                                        <Row>
                                            <Row type="flex" justify="start" >
                                                <Col xs={4} sm={2} md={2} lg={2} xl={2}>
                                                    <img src={require('../../../img/vendors/icon_40px/icon/DetailReport.png')} style={{}} />
                                                </Col>
                                                <Col xs={20} sm={22} md={22} lg={22} xl={22}>
                                                    <label className=" control-label require" style={styleheadinput}> Detailed Report </label>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col xs={24} sm={24} md={24} lg={24} xl={24} >
                                                    <label style={{ textAlign: 'left' }}>{'Detailed information for each number,date/time,message,status,reason and etc.'}</label>
                                                </Col>
                                            </Row>
                                        </Row>
                                    </Card>
                                </Col>
                                <Col xs={24} sm={24} md={24} lg={24} xl={12}>
                                    <Card
                                        onClick={(e) => { this.setState({ reportType: 2, reportrecurrencetype: 1, startdate: "", enddate: "", timestart: "", timeend: "" }) }}
                                        className={this.state.reportType == 2 ? 'Cardfixfocus' : 'Cardfix'}
                                    >
                                        <Row type="flex" justify="start" >
                                            <Col xs={4} sm={2} md={2} lg={2} xl={2}>
                                                <img src={require('../../../img/vendors/icon_40px/icon/SummaryReport.png')} style={{}} />
                                            </Col>
                                            <Col xs={20} sm={22} md={22} lg={22} xl={22}>
                                                <label className=" control-label require" style={styleheadinput}> Summary Report</label>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                                <label style={{ textAlign: 'left' }}>{'Summarized data for you messages,campaign,delivery rate,and so much more!'}</label>
                                            </Col>
                                        </Row>
                                    </Card>
                                </Col>
                            </Row>

                            {reportType == 1 || !reportType ?
                                <Detailreportnew setStateByProps={this.setStateByProps} setStateDateTime={this.setStateDateTime}
                                    getDisabledStartHours={this.getDisabledStartHours} getDisabledStartMinutes={this.getDisabledStartMinutes} clearvalue={this.clearvalue} cleardataftp={this.cleardataftp}
                                    disabledEndDate={this.disabledEndDate} getDisabledEndMinutes={this.getDisabledEndMinutes} covertformatdate={this.covertformatdate} cleardata={this.cleardata} testftp={this.testftp}
                                    warningModal={this.warningModal} getDisabledEndHours={this.getDisabledEndHours} setreportrange={this.setreportrange} getdataornewftp={this.getdataornewftp}
                                    campaign={campaign} status={status} cb_alert={cb_alert} recuring_flag={recuring_flag} alert_flag={alert_flag} otp={otp} loading={loading}
                                    recurring_range={recurring_range} report_Template={report_Template} onetimereport_type={onetimereport_type}
                                    account={account} sender={sender} users={users} startdate={startdate} loadingtestftp={loadingtestftp}
                                    timestart={timestart} enddate={enddate} timeend={timeend} email={email} emailError={emailError} datestartdisplay={datestartdisplay} enddisplaydate={enddisplaydate}
                                    recurring={recurring} recuring_type={recuring_type} recuring_value={recuring_value} reportType={reportType} reportname={reportname}
                                    ftp_flag={ftp_flag} ftp_id={ftp_id} ftp_protocal={ftp_protocal} ftp_host={ftp_host} ftp_port={ftp_port} ftp_list={ftp_list} ftp_teststatus={ftp_teststatus}
                                    ftp_username={ftp_username} ftp_password={ftp_password} ftp_path={ftp_path} ftpraedonly={ftpraedonly} reportrecurrencetype={reportrecurrencetype} />
                                :
                                <SummeryReport setStateByProps={this.setStateByProps} setStateDateTime={this.setStateDateTime}
                                    getDisabledStartHours={this.getDisabledStartHours} getDisabledStartMinutes={this.getDisabledStartMinutes} clearvalue={this.clearvalue} cleardataftp={this.cleardataftp}
                                    disabledEndDate={this.disabledEndDate} getDisabledEndMinutes={this.getDisabledEndMinutes} covertformatdate={this.covertformatdate} cleardata={this.cleardata} testftp={this.testftp}
                                    warningModal={this.warningModal} getDisabledEndHours={this.getDisabledEndHours} setreportrange={this.setreportrange} getdataornewftp={this.getdataornewftp}
                                    campaign={campaign} status={status} cb_alert={cb_alert} recuring_flag={recuring_flag} alert_flag={alert_flag} otp={otp} loading={loading}
                                    recurring_range={recurring_range} report_Template={report_Template} onetimereport_type={onetimereport_type}
                                    account={account} sender={sender} users={users} startdate={startdate} startdisplaydate={startdisplaydate}
                                    timestart={timestart} enddate={enddate} timeend={timeend} email={email} emailError={emailError} loadingtestftp={loadingtestftp}
                                    recurring={recurring} recuring_type={recuring_type} recuring_value={recuring_value} reportType={reportType} reportname={reportname}
                                    ftp_flag={ftp_flag} ftp_id={ftp_id} ftp_protocal={ftp_protocal} ftp_host={ftp_host} ftp_port={ftp_port} ftp_list={ftp_list} ftp_teststatus={ftp_teststatus}
                                    ftp_username={ftp_username} ftp_password={ftp_password} ftp_path={ftp_path} ftpraedonly={ftpraedonly} reportrecurrencetype={reportrecurrencetype} />

                            }



                        </Card>
                    </Spin>
                </div>

                <ModalWarning loading={loading}
                    warningModalVisible={warningModalVisible}
                    warningModal={this.warningModal}
                    successModal={this.successModal}
                    modalcountDown={this.modalcountDown}
                    message={message}
                    messageSuccess={messageSuccess}
                />

                <ModalSuccess loading={loading}
                    successModalVisible={successModalVisible}
                    successModal={this.successModal}
                    messageSuccess={messageSuccess}
                />


                <Modal
                    zIndex={1021}
                    wrapClassName="popup-error"
                    closeIcon={false}
                    visible={this.state.errorModalVisible}
                    footer={null}
                    width={360}
                >
                    <Row gutter={8} type="flex" justify="center">
                        <Col xs={24} sm={24} md={24} lg={24} xl={24} className="img-center">
                            <img src={require("../../../img/vendors/icon/times-circle-o.png")} width="65px" />
                        </Col>
                    </Row>

                    <Row gutter={8} type="flex" justify="center">
                        <Col xs={24} sm={24} md={24} lg={24} xl={24} className="img-label">
                            <h4>ERROR.</h4>
                            <label>{this.state.messageerror}</label>
                        </Col>
                    </Row>
                </Modal>

            </div >
        );
    }
}



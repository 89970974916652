
/* eslint-disable */
import React, { Component } from 'react';
import { PageHeader, Button, Descriptions, Select, Row, Col, DatePicker, Icon, Form, Input, Card, Tooltip, Progress, Spin } from 'antd';
import moment from 'moment';
import 'moment-timezone';
import NumericLabel from 'react-pretty-numbers';
import SelectAccount from '../../components/Wop/Select/SelectAccount';
import Card1 from '../../components/Wop/Card/Card1';
import Card2 from '../../components/Wop/Card/Card2';
import MultiColorProgressBar from '../../components/Wop/MultiColorProgressBar';
import LineDashboard from '../../components/Wop/Chart/LineDashboard';
import { token, CheckTokenExp } from '../../config';
import { apiClient } from '../../api';
import ModalServerError from '../../components/Wop/Modal/ModalServerError';

moment.tz.setDefault("Asia/Bangkok");

const { Option } = Select;
const { MonthPicker, RangePicker, WeekPicker, } = DatePicker;
const dateFormat = 'DD/MM/YYYY';

export default class Dashboard extends Component {
    constructor(props) {
        super(props);

        this.state = {
            channel: "",
            account: [],
            dateRange: [moment(moment().subtract(6, 'd'), dateFormat), moment(moment().endOf('day'), dateFormat)],
            dateRangeLine: [moment(moment().subtract(6, 'd'), dateFormat), moment(moment().endOf('day'), dateFormat)],
            usersLine: [],
            loading: false,
            select: [],
            delivered: 0,
            reject: 0,
            pending: 0,
            expired: 0,
            undelivered: 0,
            requestType: 1,
            dashboardProgressBar: [],
            delivertRate: 0,
            totalMessages: 0,
            totalCost: 0,
            creditcost: 0,
            search: {
                StartDate: moment(moment().subtract(6, 'd'), dateFormat),
                EndDate: moment(moment().endOf('day'), dateFormat),
                account: []
            },
            screenwidth: 0,
            modalVisible: false,
        };

        this.setStateByProps = this.setStateByProps.bind(this);
        this.getData_card = this.getData_card.bind(this);
        this.onSearch = this.onSearch.bind(this);
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
        this.disabledDate = this.disabledDate.bind(this);
    }

    componentDidMount() {
        this.getData_card();
        this.updateWindowDimensions();
        window.addEventListener("resize", this.updateWindowDimensions.bind(this));
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.updateWindowDimensions.bind(this));
    }

    updateWindowDimensions(value) {
        setTimeout(() => {
            var root = document.getElementById("root");
            if (root) {
                var e = document.getElementById("main");
                if (e) {
                    var scroll = root.offsetHeight > window.innerHeight;
                    this.setState({ screenwidth: scroll ? e.offsetWidth - 124 : e.offsetWidth - 126 })
                }
            }
        }, 500);
    }

    getData_card() {
        if (!CheckTokenExp()) {
            this.setState({ loading: true });

            let req = JSON.stringify({
                requestType: this.state.requestType,
                StartDate: this.state.dateRange[0] ? this.state.dateRange[0].format(dateFormat) : moment(moment().subtract(6, 'd'), dateFormat).format(dateFormat),
                EndDate: this.state.dateRange[1] ? this.state.dateRange[1].format(dateFormat) : moment(moment().endOf('day'), dateFormat).format(dateFormat),
                accounts: this.state.account.length > 0 ? this.state.account : null
            });

            apiClient.post('api/Dashboard/get_sms_status', req
                , {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-type': 'application/json; charset=UTF-8'
                    }
                })
                .then(data => {
                    var json = data.data;
                    let arr = [];
                    let objcom = [];
                    let credit = 0;
                    if (json != null) {
                        if (json.length > 0) {

                            var delivered = json.filter(o => o.smsname === 'Done' || o.smsname === 'Complete').reduce((prev, cur) => {
                                return prev + cur.count;
                            }, 0);

                            var deliveredcredit = json.filter(o => o.smsname === 'Done' || o.smsname === 'Complete').reduce((prev, cur) => {
                                return prev + cur.credit;
                            }, 0);


                            var reject = json.filter(o => o.smsname === 'Invalid_number' ||
                                o.smsname === 'Credit_not_enough' || o.smsname === 'Blacklist' ||
                                o.smsname === 'Duplicate' || o.smsname === 'Cancel' || o.smsname === 'Pause').reduce((prev, cur) => {
                                    return prev + cur.count;
                                }, 0);

                            //var reject = json.filter(o => o.smsname === 'Credit_not_enough' || o.smsname === 'Blacklist' ||
                            //    o.smsname === 'Duplicate' || o.smsname === 'Invalid_number' || o.smsname === 'Cancel' ||
                            //    o.smsname === 'Error' || o.smsname === 'Pause').reduce((prev, cur) => {
                            //        return prev + cur.count;
                            //    }, 0);

                            var pending = json.filter(o => o.smsname === 'Pending' || o.smsname === 'Process').reduce((prev, cur) => {
                                return prev + cur.count;
                            }, 0);

                            var expired = json.filter(o => o.smsname === 'Expire').reduce((prev, cur) => {
                                return prev + cur.count;
                            }, 0);

                            var undelivered = json.filter(o => o.smsname === 'Error').reduce((prev, cur) => {
                                return prev + cur.count;
                            }, 0);

                            var total = delivered + reject + pending + expired + undelivered;

                            var percentDelivered = total ? (delivered / total) * 100 : 0;
                            var percentReject = total ? (reject / total) * 100 : 0;
                            var percentPending = total ? (pending / total) * 100 : 0;
                            var percentExpired = total ? (expired / total) * 100 : 0;
                            var percentUndelivered = total ? ((undelivered) / total) * 100 : 0;

                            const dashboardProgressBar = [
                                {
                                    name: 'Delivered',
                                    value: percentDelivered,
                                    color: '#1bbc9b'
                                },
                                {
                                    name: 'Reject',
                                    value: percentReject,
                                    color: '#e44b3b'
                                },
                                {
                                    name: 'Pending',
                                    value: percentPending,
                                    color: '#edc510'
                                },
                                {
                                    name: 'Expired',
                                    value: percentExpired,
                                    color: '#9a57b4'
                                },
                                {
                                    name: 'Undelivered',
                                    value: percentUndelivered,
                                    color: '#95a5a5'
                                }
                            ];

                            var delivertRate = percentDelivered.toFixed(2);
                            var totalMessages = total;

                            var totalCost = deliveredcredit;

                            this.setState({ delivertRate, totalMessages, totalCost });

                            this.setState({ delivered, reject, pending, expired, undelivered });

                            this.setState({ dashboardProgressBar });

                            this.setState(state => {
                                return {
                                    dateRangeLine: state.dateRange,
                                }
                            });
                        }
                    }

                    this.setState({
                        sms_status_list: json,
                        delivery: arr,
                        credit: credit,
                    });
                    this.setState({ loading: false });
                })
                .catch(error => {
                    this.setState({ modalVisible: true });
                    this.setState({ loading: false });
                });
        } else {
            window.location.href = "logout";
        }

    }

    setStateByProps(parameters, value) {
       // value ? value : "";
        this.setState({ [parameters]: value });
    }

    onSearch() {
        this.setState(state => {
            return {
                search: {
                    requestType: state.requestType == 1 ? 2 : 1,
                    StartDate: state.dateRange[0] ? state.dateRange[0] : null,
                    EndDate: state.dateRange[1] ? state.dateRange[1] : null,
                    account: state.account.length > 0 ? state.account : []
                }
            }
        });
        this.updateWindowDimensions();
    }

    disabledDate(current) {
        return current && current < moment(new Date()).add(-1, 'year');
    }

    render() {
        const { dateRange, dateRangeLine, account, usersLine, delivered, reject, pending,
            expired, undelivered, dashboardProgressBar, delivertRate, totalMessages, totalCost,
            loading, search, screenwidth, modalVisible
        } = this.state;

        var optionnumber = {
            'justification': 'L',
            'locales': 'en-US',
            'percentage': false,
            'precision': 0,
            'wholenumber': null,
            'commafy': true,
            'cssClass': ['number-card1'],
            'shortFormat': true,
            'shortFormatMinValue': 10000,
            'shortFormatPrecision': 1,
            'title': true
        };

        var optionnumberTotal = {
            'justification': 'L',
            'locales': 'en-US',
            'percentage': false,
            'precision': 0,
            'wholenumber': null,
            'commafy': true,
            'cssClass': ['number-card1', 'number-card1-total'],
            'shortFormat': true,
            'shortFormatMinValue': 10000,
            'shortFormatPrecision': 1,
            'title': true
        };

        var optionnumberCost = {
            'justification': 'L',
            'locales': 'en-US',
            'percentage': false,
            'precision': 0,
            'wholenumber': null,
            'commafy': true,
            'shortFormat': true,
            'shortFormatMinValue': 10000000,
            'shortFormatPrecision': 1,
            'title': true,
            'cssClass': ['number-card1', 'number-card1-cost'],
        };
        const routes = [
            {
                path: 'dashboard',
                breadcrumbName: 'Dashboard',
            },
        ];
        var styleinput_account = {
            width: (screenwidth / 2),
            marginRight: 8,
            marginBottom: 8,
            minWidth: 200,
        };
        var styleinput_date = {
            width: (screenwidth / 2),
            marginRight: 8,
            marginBottom: 8,
            minWidth: 200,
        };

        return (
            <div id="content">
                <ModalServerError modalVisible={modalVisible} />
                <Spin spinning={loading}>
                    <PageHeader breadcrumb={{ routes }}>
                    </PageHeader>

                    <div className="content">
                        <Card>
                            <Row gutter={8} className="row-nowrap" id="main">
                                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                    <SelectAccount setStateByProps={this.setStateByProps} select={account} style={styleinput_account} />
                                    <RangePicker
                                        placeholder={["Start Date", "End Date"]}
                                        style={styleinput_date}
                                        defaultValue={dateRange}
                                        disabledDate={this.disabledDate}
                                        ranges={{
                                            Today: [moment().startOf('day'), moment().endOf('day')],
                                            'Last 7 Days': [moment().subtract(6, 'd'), moment()],
                                            'Last Week': [moment().subtract(1, 'weeks').startOf('week'), moment().subtract(1, 'weeks').endOf('week')],
                                            'Last 15 Days': [moment().subtract(14, 'd'), moment()],
                                            'Last 30 Days': [moment().subtract(29, 'd'), moment()],
                                            'This Month': [moment().startOf('month'), moment().endOf('month')]
                                        }}
                                        onChange={(value) => this.setStateByProps("dateRange", value)}
                                        value={dateRange}
                                        format={dateFormat} />

                                    <Button icon="search"
                                          onClick={() => {
                                            this.onSearch();
                                            this.setState({
                                                requestType: 2
                                            }, () => {
                                                this.getData_card();
                                            });
                                        }}>
                                        Search
                                                    </Button>

                                </Col>
                            </Row>

                            <div className="chart-wrapper" style={{ height: 300, marginTop: 8 }}>
                                <LineDashboard search={search} />
                            </div>

                            <Row gutter={8} className="row-nowrap" id="main" style={{ marginTop: 35 }}>


                                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <label className=" control-label require" style={{ marginBottom: 0 }}>Delivered</label>
                                    <div className="multicolor-bar" style={{ marginTop: 0 }}>
                                        <div className="bars">
                                            <div className="bar" style={{ backgroundColor: '#1bbc9b', 'width': '100%' }}>

                                            </div>
                                        </div>
                                    </div>
                                </Col>
                                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <label className=" control-label require" style={{ marginBottom: 0 }}>Failed</label>
                                    <div className="multicolor-bar" style={{ marginTop: 0 }}>
                                        <div className="bars">
                                            <div className="bar" style={{ backgroundColor: '#95a5a5', 'width': '100%' }}>

                                            </div>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </Card>

                        <Row gutter={8}>
                            <Col xs={24} sm={24} md={24} lg={12} xl={8}>
                                <Card1 title={<NumericLabel params={optionnumberTotal}>{totalMessages}</NumericLabel>} detail={'Total Submitted'} />
                            </Col>
                            <Col xs={24} sm={24} md={24} lg={12} xl={8}>
                                <Card1 title={delivertRate + '%'} detail={'Delivery Rate'} />
                            </Col>
                            <Col xs={24} sm={24} md={24} lg={12} xl={8}>
                                <Card1 
                                    title={
                                    <>
                                        <NumericLabel params={optionnumberCost}>{totalCost}</NumericLabel>
                                        &nbsp;{totalCost > 1 ? "CREDITS" : "CREDIT"}
                                    </>
                                    } 
                                    detail="Total Cost" 
                                />
                            </Col>
                        </Row>

                        <Row gutter={8} type="flex" justify="space-between">
                            <Col xs={24} sm={24} md={24} lg={12} xl={4} className="col-card-5">
                                <Card2 img={require('../../img/vendors/Delivered.png')} title={<NumericLabel params={optionnumber}>{delivered}</NumericLabel>} detail={'Delivered'} />
                            </Col>
                            <Col xs={24} sm={24} md={24} lg={12} xl={4} className="col-card-5">
                                <Card2 img={require('../../img/vendors/Reject.png')} title={<NumericLabel params={optionnumber}>{reject}</NumericLabel>} detail={'Reject'} />
                            </Col>
                            <Col xs={24} sm={24} md={24} lg={12} xl={4} className="col-card-5">
                                <Card2 img={require('../../img/vendors/Pending.png')} title={<NumericLabel params={optionnumber}>{pending}</NumericLabel>} detail={'Pending'} />
                            </Col>
                            <Col xs={24} sm={24} md={24} lg={12} xl={4} className="col-card-5">
                                <Card2 img={require('../../img/vendors/Expired.png')} title={<NumericLabel params={optionnumber}>{expired}</NumericLabel>} detail={'Expired'} />
                            </Col>
                            <Col xs={24} sm={24} md={24} lg={12} xl={4} className="col-card-5">
                                <Card2 img={require('../../img/vendors/Undelivered.png')} title={<NumericLabel params={optionnumber}>{undelivered}</NumericLabel>} detail={'Undelivered'} />
                            </Col>

                        </Row>

                        <Card>
                            <div style={{ fontWeight: 900 }}>Delivery rate by status</div>
                            <Row gutter={8}>
                                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                    <MultiColorProgressBar readings={dashboardProgressBar} />
                                </Col>
                            </Row>
                        </Card>
                    </div>

                </Spin>
            </div>
        );
    }
}

/* eslint-disable */
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { PageHeader, Button, Descriptions, Select, Row, Col, DatePicker, Icon, Form, Input, Card, Tooltip, Progress, Table, Divider, Tag, Modal } from 'antd';
import SelectUser from '../../../components/Wop/Select/SelectUser';
import Tabletemplate from '../../../components/Wop/Table/TableTemplate';
import { token, CheckTokenExp,ReplaceUrlTaga } from '../../../config';
import moment from 'moment';
import 'moment-timezone';
import ModalSuccess from '../../../components/Wop/Modal/ModalSuccess';
import ModalWarning from '../../../components/Wop/Modal/ModalWarning';
import { apiClient } from '../../../api';
import{root} from '../../../config';
import ModalServerError from '../../../components/Wop/Modal/ModalServerError';

moment.tz.setDefault("Asia/Bangkok");

const dateFormat = 'DD/MM/YYYY';
const { MonthPicker, RangePicker, WeekPicker, } = DatePicker;

export default class List extends Component {
    constructor(props) {
        super(props);

        this.state = {
            warningModalVisible: false,
            successModalVisible: false,
            loading: false,
            message: "",
            messageSuccess: "",
            name: "",
            users: [],
            search: {
                name: "",
                users: [],
                dateRange: [null, null]
            },
            page: "",
            templateItem: [],
            action: "",
            dateRange: [null, null],
            screenwidth: 0,
            modalVisible: false,
        };

        this.warningModal = this.warningModal.bind(this);
        this.successModal = this.successModal.bind(this);
        this.modalcountDown = this.modalcountDown.bind(this);
        this.setStateByProps = this.setStateByProps.bind(this);
        this.onSearch = this.onSearch.bind(this);
        this.exportfileexcel = this.exportfileexcel.bind(this);
        this.onDeleteItem = this.onDeleteItem.bind(this);
        this.handleDuplicate = this.handleDuplicate.bind(this);
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    }

    componentDidMount() {
        this.updateWindowDimensions();
        window.addEventListener("resize", this.updateWindowDimensions.bind(this));
        ReplaceUrlTaga();
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.updateWindowDimensions.bind(this));
    }

    updateWindowDimensions() {
        setTimeout(() => {
            var root = document.getElementById("root");
            if (root) {
                var e = document.getElementById("main");
                if (e) {
                    var scroll = root.offsetHeight > window.innerHeight;
                    this.setState({ screenwidth: scroll ? e.offsetWidth - 132 : e.offsetWidth - 132 })
                }
            }
        }, 500);
    }

    warningModal(value, message, messageSuccess) {
        this.setState({ warningModalVisible: value, message, messageSuccess });
    }

    successModal(value) {
        if (this.state.action == "export") {
            this.setState({ action: "" });
            this.exportfileexcel(this.state.templateItem[0].key, this.state.templateItem[0].name);
        }
        if (this.state.action == "duplicate") {
            this.setState({ action: "" });
            this.handleDuplicate(this.state.templateItem[0].key)
        }
        if (this.state.action == "delete") {
            this.setState({ action: "" });
            this.onDeleteItem(this.state.templateItem);
        }

        this.setState({ successModalVisible: value });

        let modalcontent = document.querySelectorAll('.ant-modal-contentant-modal-content-hide');
        for (var i = 0; i < modalcontent.length; i++) {
            modalcontent[i].className = "ant-modal-content";
        }
    }

    modalcountDown() {
        let secondsToGo = 2;
        const timer = setInterval(() => {
        }, 1000);
        setTimeout(() => {
            clearInterval(timer);
            this.setState({ successModalVisible: false });
        }, secondsToGo * 1000);
    }

    onSearch() {
        this.setState(state => {
            return {
                search: {
                    name: state.name,
                    users: state.users,
                    dateRange: state.dateRange
                }
            }
        });
        this.updateWindowDimensions();
    }

    setStateByProps(parameters, value) {
        value ? value : "";
        this.setState({ [parameters]: value });

    }

    exportfileexcel(id, name) {
        if (!CheckTokenExp()) {
            apiClient.get('api/template/export/' + id
                , {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-type': 'application/json; charset=UTF-8'
                    },
                    responseType: 'blob',
                })
                .then(data => {
                    var json = data.data;
                    const url = window.URL.createObjectURL(new Blob([json]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.download = name + ".xlsx";
                    document.body.appendChild(link);
                    link.click();
                    link.remove();
                })
                .catch(error => {

                });
        } else {
            window.location.href = "logout";
        }
        
    }

    onDeleteItem(value) {
        if (!CheckTokenExp()) {
            this.setState({ loading: true });

            let req = JSON.stringify({
                key: value.map((items) => items.key)
            });

            apiClient.post('api/template/delete', req
                , {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-type': 'application/json; charset=UTF-8'
                    }
                })
                .then(data => {
                    var json = data.data;
                    this.setState({ startdata: 1, enddata: 10 });
                    this.onSearch();
                    this.setState({ loading: false });
                })
                .catch(error => {
                    this.setState({ modalVisible: true });
                    this.setState({ loading: false });
                });
        } else {
            window.location.href = "logout";
        }
    }

    handleDuplicate(value) {
        if (!CheckTokenExp()) {
            this.setState({ loading: true });

            let req = JSON.stringify({
                key: value,
            });

            apiClient.post('api/template/duplicate', req
                , {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-type': 'application/json; charset=UTF-8'
                    }
                })
                .then(data => {
                    var json = data.data;

                    this.onSearch();

                    this.setState({ loading: false });
                })
                .catch(error => {
                    this.setState({ loading: false });
                });
        } else {
            window.location.href = "logout";
        }
        
    }

    render() {
        const { loading, message, messageSuccess, 
            warningModalVisible, successModalVisible, 
            users, search, templateItem, action, dateRange, 
            screenwidth, modalVisible } = this.state;
        const routes = [
            {
                path: 'communication',
                breadcrumbName: 'Communication',
            },
            {
                path: 'template/list',
                breadcrumbName: 'Template List',
            },
        ];
        var styleinput_name = {
            width: (screenwidth / 3),
            marginRight: 8,
            marginBottom: 8,
            minWidth: 200
        };
        var styleinput_user = {
            width: (screenwidth / 3),
            marginRight: 8,
            marginBottom: 8,
            minWidth: 200
        };
        var styleinput_date = {
            width: (screenwidth / 3),
            marginRight: 8,
            marginBottom: 8,
            minWidth: 200
        };

        return (
            <div className="animated fadeIn">
                <PageHeader breadcrumb={{ routes }} >
                </PageHeader>

                <div className="content">
                    <Card>
                        <Row gutter={8} type="flex" justify="space-between" >
                            <Col xs={12}>
                                <Button key="submit" style={{ marginRight: 8 }}
                                    disabled={templateItem.length > 0 ? false : true}
                                    onClick={() => { this.setState({ action: 'delete' }), this.warningModal(true, "Do you want to delete template?", "Please wait.") }}>
                                    <span className="fa fa-trash-o"></span> &nbsp;&nbsp;
                                   Delete
                                </Button>
                            </Col>
                            <Col xs={12} >
                                <div style={{ textAlign: "right" }}>
                                    <Link to={'/communication/template/new/'} >
                                        <Button type="primary">
                                            <span className="fa fa-plus-circle"></span> &nbsp;&nbsp;
                                            New
                        </Button>
                                    </Link>
                                </div>
                            </Col>
                        </Row>

                        <Row gutter={8} className="row-nowrap" id="main">
                            <Col xs={24}>
                                <Input placeholder="Template name" style={styleinput_name} onChange={(e) => this.setStateByProps("name", e.target.value)} />

                                <SelectUser select={users} setStateByProps={this.setStateByProps} style={styleinput_user} />

                                <RangePicker
                                    placeholder={["Start Date", "End Date"]}
                                    style={styleinput_date}
                                    defaultValue={dateRange}
                                    ranges={{
                                        Today: [moment().startOf('day'), moment().endOf('day')],
                                        'Last 7 Days': [moment().subtract(6, 'd'), moment()],
                                        'Last Week': [moment().subtract(1, 'weeks').startOf('week'), moment().subtract(1, 'weeks').endOf('week')],
                                        'Last 15 Days': [moment().subtract(14, 'd'), moment()],
                                        'Last 30 Days': [moment().subtract(29, 'd'), moment()],
                                        'This Month': [moment().startOf('month'), moment().endOf('month')]
                                    }}
                                    onChange={(value) => this.setStateByProps("dateRange", value)}
                                    value={dateRange}
                                    format={dateFormat} />

                                <Button icon="search" onClick={this.onSearch}>
                                    Search
                                </Button>
                            </Col>
                        </Row>

                        <Row gutter={8} type="flex" justify="center">
                            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                <Tabletemplate
                                    search={search}
                                    templateItem={templateItem}
                                    action={action}
                                    warningModal={this.warningModal}
                                    setStateByProps={this.setStateByProps}
                                    updateWindowDimensions={this.updateWindowDimensions}
                                />

                            </Col>
                        </Row>



                    </Card>

                </div>

                <ModalWarning loading={loading}
                    warningModalVisible={warningModalVisible}
                    warningModal={this.warningModal}
                    successModal={this.successModal}
                    modalcountDown={this.modalcountDown}
                    message={message}
                    messageSuccess={messageSuccess}
                />

                <ModalSuccess loading={loading}
                    successModalVisible={successModalVisible}
                    successModal={this.successModal}
                    messageSuccess={messageSuccess}
                />

                <ModalServerError modalVisible={modalVisible}/>

            </div>
        );
    }
}



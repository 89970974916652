/* eslint-disable */
import React, { Component } from 'react';
import moment from 'moment';
import 'moment-timezone';
import {
    PageHeader, Button, Descriptions, Select, Row, Col, DatePicker, Icon, Form, Input, Card,
    Tooltip, Progress, Table, Divider, Tag, Modal, Checkbox, Spin, Radio
} from 'antd';
import { apiClient } from '../../../api';
import { root } from '../../../config';
import { token, validateEmail, CheckTokenExp } from '../../../config';
import ModalAddUser from '../../../components/Wop/Modal/ModalAddUser';
import TableUsers from '../../../components/Wop/Table/TableUsers';
import ModalSuccess from '../../../components/Wop/Modal/ModalSuccess';
import ModalWarning from '../../../components/Wop/Modal/ModalWarning';
import ModalError from "../../../components/Wop/Modal/ModalError";
import ModalWarningCustom from "../../../components/Wop/Modal/ModalWarningCustom";

moment.tz.setDefault("Asia/Bangkok");

const dateFormat = 'DD/MM/YYYY';
const { MonthPicker, RangePicker, WeekPicker, } = DatePicker;

export default class Users extends Component {
    constructor(props) {
        super(props);

        this.state = {
            warningModalVisible: false,
            successModalVisible: false,
            errorModalVisible: false,
            loading: false,
            message: "",
            messageSuccess: "",
            messageError:"",
            screenwidth: 0,
            name: "",
            search: {
                name: "",
                dateRange: [null, null]
            },
            dateRange: [null, null],
            MadalAddUservisible: false,
            id: null,
            addusername: "",
            addpassword: "",
            addstatusacc: 1,
            tocreate: false,
            creactsubeaccount: false,
            admin: false,
            createuser: false,
            owner: false,
            password: "",
            repassword: "",
            addrepassword: "",
            uemail: "",
            emailError: false,
            uemailError: false,
            ip: "",
            clientInfo: "",
            warningModalOpen: false,
        };

        this.warningModal = this.warningModal.bind(this);
        this.successModal = this.successModal.bind(this);
        this.errorModal = this.errorModal.bind(this);
        this.modalcountDown = this.modalcountDown.bind(this);
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
        this.setStateByProps = this.setStateByProps.bind(this);
        this.onSearch = this.onSearch.bind(this);
        this.showModal = this.showModal.bind(this);
        this.handleOk = this.handleOk.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
        this.userSave = this.userSave.bind(this);
        this.forgotpwd = this.forgotpwd.bind(this);
        this.GetUserDetail = this.GetUserDetail.bind(this);
        this.userCreate = this.userCreate.bind(this);
        this.onSubmitWarning = this.onSubmitWarning.bind(this);
        this.warningModalCustom = this.warningModalCustom.bind(this);
    }

    componentDidMount() {
        this.updateWindowDimensions();
        window.addEventListener("resize", this.updateWindowDimensions.bind(this));
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.updateWindowDimensions.bind(this));
    }

    updateWindowDimensions() {
        setTimeout(() => {
            var root = document.getElementById("root");
            if (root) {
                var e = document.getElementById("main");
                if (e) {
                    var scroll = root.offsetHeight > window.innerHeight;
                    this.setState({ screenwidth: scroll ? e.offsetWidth - 124 : e.offsetWidth - 124 })
                }
            }
        }, 500);
    }

    errorModal(value) {
        this.setState({ 
            errorModalVisible: value ,
            errorMessage: "",
        });
      }

    setStateByProps(parameters, value) {
        value ? value : "";

        if (parameters == "username") {
            if (value.match(/^[A-Za-z0-9 /./+/*/@/\\_-]+$/i) || value == "") {
                this.setState({ [parameters]: value });
            }
        } else {
            this.setState({ [parameters]: value });

            if (parameters == "email") {
                this.setState({ emailError: validateEmail(value) });
            } else if (parameters == "uemail") {
                this.setState({ uemailError: validateEmail(value) });
            }
        }
        this.setState({ errorMessage: "" });
    }

    warningModal(value, message, messageSuccess) {
        this.setState({ warningModalVisible: value, message, messageSuccess });
    }

    successModal(value) {
        if (this.state.action == "usercreate") {
            this.setState({ action: "" });
            this.userCreate();
            value = false;
        }
        if (this.state.action == "saveuser") {
            this.setState({ action: "" });
            this.userSave();
        }
        if (this.state.action == "discard") {
            this.setState({ action: "" });
            value = false;
            this.props.history.push('/accountmanagement')
        }
        if (this.state.action == "fgp") {
            this.setState({ action: "" });
            this.forgotpwd();
            value = false;
        }
        this.setState({ successModalVisible: value });

        let modalcontent = document.querySelectorAll('.ant-modal-contentant-modal-content-hide');
        for (var i = 0; i < modalcontent.length; i++) {
            modalcontent[i].className = "ant-modal-content";
        }
    }

    modalcountDown() {
        let secondsToGo = 2;
        const timer = setInterval(() => {
        }, 1000);
        setTimeout(() => {
            clearInterval(timer);
            if(this.state.successModalVisible){ // เมื่อตกเคส successModal เปิดค่อยปิด
                this.setState({ successModalVisible: false });
                this.setState({ errorModalVisible: false });
            }
        }, secondsToGo * 1000);
    }

    onSearch() {
        this.setState(state => {
            return {
                search: {
                    name: state.name,
                    dateRange: state.dateRange
                }
            }
        });
        this.updateWindowDimensions();
    }

    showModal(e) {
        this.setState({
            visible: true,
            userId: e,
        });
    };

    handleOk() {
        this.setState({
            MadalAddUservisible: false, usercreate: "", addrepassword: "", addpassword: "", uemail: ""
        });
    };

    handleCancel() {
        this.setState({
            visible: false,
        });
    };

    GetUserDetail(id) {
        if (!CheckTokenExp()) {
            this.setState({ loading: true });

            let req = JSON.stringify({
                id: id
            });

            apiClient.post('api/usersmanage/UserDetail', req
                , {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-type': 'application/json; charset=UTF-8'
                    }
                })
                .then(data => {
                    var json = data.data;
                    this.setState({
                        addstatusacc: json.status,
                        addusername: json.username,
                        tocreate: json.permission,
                        uemail: json.email,
                        uemailError: validateEmail(json.email),
                    });
                    this.setState({ loading: false });
                })
                .catch(error => {
                    this.setState({ loading: false });
                });
        } else {
            window.location.href = "logout";
        }

    }

    userSave() {
        if (!CheckTokenExp()) {
            this.setState({ loading: true });

            let req = JSON.stringify({
                userid: this.state.id,
                password: this.state.addpassword,
                status: this.state.addstatusacc,
                permission: this.state.tocreate === 1 ? true : this.state.tocreate === 0 ? false : this.state.tocreate,
                email: this.state.uemail
            });

            apiClient.post('api/usersmanage/UserUpdate', req
                , {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-type': 'application/json; charset=UTF-8'
                    }
                })
                .then(data => {
                    var json = data.data;
                    this.onSearch();
                    this.handleOk();
                    this.setState({ addusername: "", addpassword: "", repassword: "", addstatusacc: 1, tocreate: false, usercreate: "", uemail: "" });
                    this.setState({ loading: false });
                })
                .catch(error => {
                    this.setState({ loading: false });
                });
        } else {
            window.location.href = "logout";
        }

    }

    forgotpwd() {
        if (!CheckTokenExp()) {
            this.setState({ loading: true });

            let req = JSON.stringify({
                username: this.state.addusername,
                userId: `${this.state.id}`,
                email: this.state.uemail,
                ip: this.state.ip,
                device: this.state.clientInfo
            });

            apiClient.post('api/usersmanage/forgetpassword', req
                , {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-type': 'application/json; charset=UTF-8'
                    }
                })
                .then(data => {

                    var json = data.data;
                    if (json == true) {

                        this.setState({
                            successModalVisible: true,
                            messageSuccess: "Email forgot password sent successfully"
                        });
                        setTimeout(() => {
                            this.setState({ successModalVisible: false });
                        }, 3000);
                    } else {
                        this.setState({
                            errorModalVisible: true,
                            messageError: "Email forgot password sent fail",
                        });
                    }
                    this.setState({ loading: false });
                })
                .catch(error => {
                    this.setState({ loading: false });
                });
        } else {
            window.location.href = "logout";
        }

    }

    userCreate() {
        if (!CheckTokenExp()) {
            this.setState({ loading: true });

            let req = JSON.stringify({
                username: this.state.addusername,
                password: this.state.addpassword,
                status: this.state.addstatusacc,
                permission: this.state.tocreate === 1 ? true : this.state.tocreate === 0 ? false : this.state.tocreate,
                email: this.state.uemail
            });

            apiClient.post('api/usersmanage/UserCreate', req
                , {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-type': 'application/json; charset=UTF-8'
                    }
                })
                .then(data => {
                    var json = data.data;
                    this.ModalShow(json.Code == "200" ? true : false , json.Description);
                    if(json.Code == "200"){
                        this.onSearch();
                        this.handleOk();
                        this.setState({ addusername: "", addpassword: "", repassword: "", addstatusacc: 1, tocreate: false, usercreate: "", uemail: "" ,loading: false });
                    }
                    
                    
                })
                .catch(error => {
                    this.setState({ loading: false });
                });
        } else {
            window.location.href = "logout";
        }
    }

    ModalShow(type, message){
        if(type){ // success = true error = false
            this.setState({ 
                loading: false ,
                successModalVisible:true ,
                errorModalVisible:false,
            });
        }else{
            this.setState({ 
                loading: false ,
                successModalVisible:false ,
                errorModalVisible:true,
                messageError: message
            });
        }
    }

    warningModalCustom(value) {
        this.setState({ warningModalOpen: value });
    }

    onSubmitWarning() {
        console.log("onSubmitWarning");
        if (this.state.action === "fgp") {
            this.setState({ action: "" });
            this.forgotpwd();
        }
    }
    
    render() {
        const { loading, message, messageSuccess, warningModalVisible, successModalVisible, users, search, templateItem,errorModalVisible,
            action, dateRange, screenwidth, screenwidth2, accname, email, statusacc, username, paytype, MadalAddUservisible,messageError,
            id, addusername, addpassword, repassword, password, addstatusacc, tocreate, creactsubeaccount, addrepassword, uemail,
            emailError, uemailError, warningModalOpen } = this.state;
        const { admin, createuser, owner } = this.props;
        return (
            <div className="content" id="main">
                <Row gutter={8} className="row-nowrap" id="main">
                    <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                        <Input placeholder="Search by username" onChange={(e) => this.setStateByProps("name", e.target.value)} style={{ marginBottom: 10 }} />
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                        <RangePicker
                            placeholder={["Start Date", "End Date"]}
                            defaultValue={dateRange}
                            ranges={{
                                Today: [moment().startOf('day'), moment().endOf('day')],
                                'Last 7 Days': [moment().subtract(6, 'd'), moment()],
                                'Last Week': [moment().subtract(1, 'weeks').startOf('week'), moment().subtract(1, 'weeks').endOf('week')],
                                'Last 15 Days': [moment().subtract(14, 'd'), moment()],
                                'Last 30 Days': [moment().subtract(29, 'd'), moment()],
                                'This Month': [moment().startOf('month'), moment().endOf('month')]
                            }}
                            onChange={(value) => this.setStateByProps("dateRange", value)}
                            style={{ marginBottom: 10 }}
                            value={dateRange}
                            format={dateFormat} />
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                        <Button style={{ marginBottom: 10 }} icon="search" onClick={this.onSearch}>
                            Search
                        </Button>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={6} xl={6} style={{ textAlign: "right" }}>
                        <Button type="primary" onClick={() => {
                            this.setStateByProps("MadalAddUservisible", true),
                                this.setState({ id: null, addusername: "", addpassword: "", addstatusacc: 1, tocreate: true })
                        }}>
                            <span className="fa fa-plus-circle"></span> &nbsp;&nbsp;
                            Create User
                        </Button>
                    </Col>
                </Row>

                <Row gutter={8} type="flex" justify="center">
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                        <TableUsers
                            loading={loading}
                            search={search}
                            templateItem={templateItem}
                            action={action}
                            warningModal={this.warningModal}
                            setStateByProps={this.setStateByProps}
                            GetUserDetail={this.GetUserDetail}
                            owner={owner}
                            admin={admin}
                            createuser={createuser}
                            updateWindowDimensions={this.updateWindowDimensions}
                        />
                    </Col>
                </Row>

                <ModalAddUser
                    setStateByProps={this.setStateByProps}
                    visible={MadalAddUservisible}
                    handleOk={this.handleOk}
                    id={id}
                    username={addusername}
                    password={addpassword}
                    repassword={addrepassword}
                    statusacc={addstatusacc}
                    tocreate={tocreate}
                    loading={loading}
                    warningModal={this.warningModal}
                    owner={owner}
                    admin={admin}
                    createuser={createuser}
                    email={uemail}
                    emailError={uemailError}
                />

                <ModalWarning loading={loading}
                    warningModalVisible={warningModalVisible}
                    warningModal={this.warningModal}
                    successModal={this.successModal}
                    modalcountDown={this.modalcountDown}
                    message={message}
                    messageSuccess={messageSuccess}
                />

                <ModalSuccess loading={loading}
                    successModalVisible={successModalVisible}
                    successModal={this.successModal}
                    modalcountDown={this.modalcountDown}
                    messageSuccess={messageSuccess}
                />
                <ModalError
                    loading={loading}
                    errorModalVisible={errorModalVisible}
                    errorModal={this.errorModal}
                    modalcountDown={this.modalcountDown}
                    messageError={messageError}
                />

                <ModalWarningCustom
                    loading={loading}
                    warningModalOpen={warningModalOpen}
                    warningModal={this.warningModalCustom}
                    onSubmit={this.onSubmitWarning}
                    message={message}
                />
            </div>

        );
    }
}

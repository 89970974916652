import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Table, Divider, Tag, Icon, Button, Row, Col, Popover, Menu, Card, Affix, Empty } from 'antd';
import { apiClient } from '../../../api';
import { root } from '../../../config';
import { token, formatNumber, CheckTokenExp } from '../../../config';
import moment from 'moment';
import 'moment-timezone';

moment.tz.setDefault("Asia/Bangkok");

const dateFormat = 'DD/MM/YYYY';
const dateFormatDateTime = 'DD/MM/YYYY HH:mm';

export default class TableCreditMovementLog extends Component {

    constructor(props) {
        super(props);

        this.state = {
            columns: [
                {
                    title: 'Action',
                    dataIndex: 'toAccount',
                    key: 'toAccount',
                    width: 150,
                    render: (text, item) => (
                        <div className="table-title" title={text}>
                            <Row gutter={8} type="flex" justify="space-between">
                                <Col xs={24}>
                                    {
                                        item.credit > 0 ?
                                            <Tag className="ant-creditmove-add" > {"ADDED TO"}</ Tag>
                                            :
                                            <Tag className="ant-creditmove-remove" > {"REMOVED FROM"}</ Tag>
                                    }
                                </Col>
                                <Col xs={24}>
                                    <p style={{ marginBottom: "0.2rem" }}>{text}</p>
                                </Col>
                            </Row>
                        </div>
                    )
                },
                {
                    title: 'Amount',
                    dataIndex: 'credit',
                    key: 'credit',
                    width: 150,
                    render: (text, item) => (
                        <div className="table-title" title={text}>
                            <Row gutter={8} type="flex" justify="space-between">
                                <Col xs={24}>
                                    <Tag className="ant-creditmove-amount"> {text ? formatNumber(text) : 0}{" CREDITS"}</ Tag>
                                </Col>
                                <Col xs={24}>
                                    <p style={{ marginBottom: "0.2rem" }}>Balance: {formatNumber(item.balanceAccTo)}</p>
                                </Col>
                            </Row>
                        </div>
                    )
                },
                {
                    title: 'Description',
                    dataIndex: 'remark',
                    key: 'remark',
                    width: 200,
                },
                {
                    title: 'Date',
                    dataIndex: 'createdate',
                    key: 'createdate',
                    width: 100,
                    render: text => (moment(text, "D/M/YYYY H:m").format(dateFormatDateTime))
                },
            ],
            pagesize: 10,
            currentpage: 1,
            startrow: 1,
            endrow: 10,
            totalpage: 0,
            clickId: null,
            data: [],
            loading: false,
            loadingTable: false,
        };

        this.getData = this.getData.bind(this);
        this.onChangePage = this.onChangePage.bind(this);

    }

    componentDidUpdate(prevProps) {
        if (this.props.search !== prevProps.search) {
            this.getData();
        }
    }

    componentDidMount() {
        this.getData();
    }

    getData() {
        if (!CheckTokenExp()) {
            this.setState({ loading: true });

            const { search } = this.props;

            this.setState(state => {

                let req = JSON.stringify({
                    accountname: search.accname,
                    date: [search.dateRange[0] ? search.dateRange[0].format(dateFormat) : null, search.dateRange[1] ? search.dateRange[1].format(dateFormat) : null],
                    startrow: state.startrow,
                    endrow: state.endrow,
                });

                apiClient.post('api/CreditMovement/CreditMovementLog', req
                    , {
                        headers: {
                            'Authorization': `Bearer ${token}`,
                            'Content-type': 'application/json; charset=UTF-8'
                        }
                    })
                    .then(data => {
                        var json = data.data;
                        this.setState(state => {
                            return {
                                data: json.length > 0 ? json : [],
                                totalpage: json.length > 0 ? json[0].totalpage : 0,
                                currentpage: state.startrow == 1 ? 1 : state.currentpage,
                                startrow: 1,
                                endrow: state.pagesize,
                            };
                        }, () => { this.props.updateWindowDimensions() });

                        this.setState({ loading: false });
                    })
                    .catch(error => {
                        this.setState({
                            data: [],
                            totalpage: 0,
                            currentpage: 1,
                            startrow: 1,
                            endrow: 10
                        });
                        this.setState({ loading: false });
                    });
            });
        } else {
            window.location.href = "logout";
        }

    }

    onChangePage(currentPage, pageSize) {
        // var endrow = currentPage * pageSize;
        // var startrow = endrow - pageSize + 1;
        var endrow = currentPage * pageSize;
        var startrow = ((currentPage - 1) * pageSize) + 1;

        this.setState(state => {
            return {
                loading: true,
                currentpage: currentPage,
                // currentpage: state.pagesize == pageSize ? currentPage : 1,
                startrow: startrow,
                endrow: endrow,
            };
        }, () => this.getData());

    }

    render() {
        const { columns, loading, data, totalpage } = this.state;
        const { setStateByProps, footer, templateItem } = this.props;
        return (
            <div>

                <Table
                    loading={loading}
                    pagination={{
                        defaultPageSize: 10,
                        pageSizeOptions: ['10', '20', '50', '100'],
                        showSizeChanger: true,
                        total: totalpage,
                        showTotal: (total, range) => `${range[0]}-${range[1]} of ${totalpage} items`,
                        onShowSizeChange: this.onChangePage,
                        onChange: this.onChangePage,
                        current: this.state.currentpage
                    }
                    }
                    columns={columns}
                    dataSource={data}
                    className="table-wop"
                    footer={() => footer}
                    tableLayout="fixed"
                />

            </div>
        );
    }
}



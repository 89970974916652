/* eslint-disable */
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { PageHeader, Button, Descriptions, Select, Row, Col, DatePicker, Icon, Form, Input, Card, Tooltip, Progress, Table, Divider, Tag, Modal, Checkbox, Spin } from 'antd';
import SelectStatusAccount from '../../../components/Wop/Select/SelectStatusAccount';
import SelectPayType from '../../../components/Wop/Select/SelectPayType';
import ModalAddUser from '../../../components/Wop/Modal/ModalAddUser';
import TableUsers from '../../../components/Wop/Table/TableUsers';
import { apiClient } from '../../../api';
import{root} from '../../../config';

import { token, getUrlVars, validateEmail, CheckTokenExp } from '../../../config';
import moment from 'moment';
import 'moment-timezone';
import ModalSuccess from '../../../components/Wop/Modal/ModalSuccess';
import ModalWarning from '../../../components/Wop/Modal/ModalWarning';

moment.tz.setDefault("Asia/Bangkok");

const dateFormat = 'DD/MM/YYYY';
const { MonthPicker, RangePicker, WeekPicker, } = DatePicker;

export default class AccountsDetail extends Component {
    constructor(props) {
        super(props);

        this.state = {
            warningModalVisible: false,
            successModalVisible: false,
            loading: false,
            message: "",
            messageSuccess: "",
            name: "",
            users: [],
            search: {
                name: "",
                users: [],
                dateRange: [null, null]
            },
            page: "",
            templateItem: [],
            action: "",
            dateRange: [null, null],
            screenwidth: 0,
            screenwidth2: 0,
            MadalAddUservisible: false,
            id: null,
            addusername: "",
            addpassword: "",
            addstatusacc: 1,
            tocreate: false,
            creactsubeaccount: false,
            admin: false,
            createuser: false,
            owner: false,
            password: "",
            repassword: "",
            addrepassword: "",
            uemail: "",
            emailError: false,
            uemailError: false,
        };

        this.warningModal = this.warningModal.bind(this);
        this.successModal = this.successModal.bind(this);
        this.modalcountDown = this.modalcountDown.bind(this);
        this.setStateByProps = this.setStateByProps.bind(this);
        this.onSearch = this.onSearch.bind(this);
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
        this.getAccountDetailData = this.getAccountDetailData.bind(this);
        this.handleOk = this.handleOk.bind(this);
        this.GetUserDetail = this.GetUserDetail.bind(this);
        this.accountUpdate = this.accountUpdate.bind(this);
        this.userCreate = this.userCreate.bind(this);
        this.userSave = this.userSave.bind(this);
        this.Getpermission = this.Getpermission.bind(this);

    }

    componentDidMount() {
        this.Getpermission();
        this.getAccountDetailData();
        this.updateWindowDimensions();
        window.addEventListener("resize", this.updateWindowDimensions.bind(this));
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.updateWindowDimensions.bind(this));
    }

    updateWindowDimensions() {
        setTimeout(() => {
            var root = document.getElementById("root");
            if (root) {
                var e = document.getElementById("main");
                if (e) {
                    var scroll = root.offsetHeight > window.innerHeight;
                    this.setState({ screenwidth: scroll ? e.offsetWidth - 28 : e.offsetWidth - 28 });
                    this.setState({ screenwidth2: scroll ? e.offsetWidth - 124 : e.offsetWidth - 124 });

                }
            }
        }, 500);
    }

    warningModal(value, message, messageSuccess) {
        this.setState({ warningModalVisible: value, message, messageSuccess });
    }

    successModal(value) {
        if (this.state.action == "accountcreate") {
            this.setState({ action: "" });
            this.accountUpdate();
        }
        if (this.state.action == "usercreate") {
            this.setState({ action: "" });
            this.userCreate();
        }
        if (this.state.action == "saveuser") {
            this.setState({ action: "" });
            this.userSave();
        }
        if (this.state.action == "discard") {
            this.setState({ action: "" });
            value = false;
            this.props.history.push('/accountmanagement')
        }
        this.setState({ successModalVisible: value });

        let modalcontent = document.querySelectorAll('.ant-modal-contentant-modal-content-hide');
        for (var i = 0; i < modalcontent.length; i++) {
            modalcontent[i].className = "ant-modal-content";
        }
    }

    modalcountDown() {
        let secondsToGo = 2;
        const timer = setInterval(() => {
        }, 1000);
        setTimeout(() => {
            clearInterval(timer);
            this.setState({ successModalVisible: false });
        }, secondsToGo * 1000);


    }

    onSearch() {
        this.setState(state => {
            return {
                search: {
                    name: state.name,
                    users: state.users,
                    dateRange: state.dateRange
                }
            }
        });
        this.updateWindowDimensions();
    }

    setStateByProps(parameters, value) {
        value ? value : "";

        if (parameters == "addusername" && value === "") {
            if (value.match(/^[A-Za-z0-9 /./+/*/@/\\_-]+$/i)) {
                this.setState({ [parameters]: value });
            }
        }
        else {
            this.setState({ [parameters]: value });

            if (parameters == "email") {
                this.setState({ emailError: validateEmail(value) });
            } else if (parameters == "uemail") {
                this.setState({ uemailError: validateEmail(value) });
            }
        }
    }

    getAccountDetailData() {
        if (!CheckTokenExp()) {
            this.setState({ loading: true });

            this.setState(state => {
                let req = JSON.stringify({
                    accountId: getUrlVars()["id"],
                });

                apiClient.post('api/usersmanage/AccountDetail', req
                    , {
                        headers: {
                            'Authorization': `Bearer ${token}`,
                            'Content-type': 'application/json; charset=UTF-8'
                        }
                    })
                    .then(data => {
                        var json = data.data;
                        if (json) {
                            this.setState(state => {
                                return {
                                    accname: json.name,
                                    email: json.email,
                                    statusacc: json.status,
                                    username: json.username,
                                    currentpage: state.currentpage,
                                    paytype: json.pay_type,
                                    creactsubeaccount: json.creactsubeaccount,
                                    emailError: validateEmail(json.email)
                                };
                            });
                        }
                        this.setState({ loading: false });
                    })
                    .catch(error => {
                        this.setState({ loading: false });
                    });
            });
        } else {
            window.location.href = "logout";
        }
        
    }

    handleOk() {
        this.setState({
            MadalAddUservisible: false, usercreate: "", addrepassword: "", addpassword: "", uemail: ""
        });
    };

    GetUserDetail(id) {
        if (!CheckTokenExp()) {
            this.setState({ loading: true });

            let req = JSON.stringify({
                accountId: getUrlVars()["id"],
                id: id
            });

            apiClient.post('api/usersmanage/UserDetail', req
                , {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-type': 'application/json; charset=UTF-8'
                    }
                })
                .then(data => {
                    var json = data.data;

                    this.setState({
                        addstatusacc: json.status,
                        addusername: json.username,
                        tocreate: json.permission,
                        uemail: json.email,
                        uemailError: validateEmail(json.email)
                    });

                    this.setState({ loading: false });
                })
                .catch(error => {
                    this.setState({ loading: false });
                });
        } else {
            window.location.href = "logout";
        }
        
    }

    accountUpdate() {
        if (!CheckTokenExp()) {
            this.setState({ loading: true });

            let req = JSON.stringify({
                accountId: getUrlVars()["id"],
                name: this.state.accname,
                email: this.state.email,
                username: this.state.username,
                password: this.state.password,
                paytype: this.state.paytype,
                status: this.state.statusacc,
                creactsubeaccount: this.state.creactsubeaccount == 0?false:(this.state.creactsubeaccount==1?true:this.state.creactsubeaccount) ,
            });

            apiClient.post('api/usersmanage/AccountUpdate', req
                , {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-type': 'application/json; charset=UTF-8'
                    }
                })
                .then(data => {
                    var json = data.data;

                    this.setState({ loading: false });
                })
                .catch(error => {
                    this.setState({ loading: false });
                });
        } else {
            window.location.href = "logout";
        }
        
    }

    userCreate() {
        if (!CheckTokenExp()) {
            this.setState({ loading: true });

            let req = JSON.stringify({
                accountId: getUrlVars()["id"],
                username: this.state.addusername,
                password: this.state.addpassword,
                status: this.state.addstatusacc,
                permission:  this.state.tocreate==0?false:(this.state.tocreate==1?true:this.state.tocreate),
            });

            apiClient.post('api/usersmanage/UserCreate', req
                , {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-type': 'application/json; charset=UTF-8'
                    }
                })
                .then(data => {
                    var json = data.data;

                    this.onSearch();
                    this.handleOk();

                    this.setState({ addusername: "", addpassword: "", repassword: "", addstatusacc: 1, tocreate: false, usercreate: "" });

                    this.setState({ loading: false });
                })
                .catch(error => {
                    this.setState({ loading: false });
                });
        } else {
            window.location.href = "logout";
        }

    }

    userSave() {
        if (!CheckTokenExp()) {
            this.setState({ loading: true });

            let req = JSON.stringify({
                userid: this.state.id,
                password: this.state.addpassword,
                status: this.state.addstatusacc,
                permission:  this.state.tocreate==0?false:(this.state.tocreate==1?true:this.state.tocreate),
                email: this.state.uemail
            });

            apiClient.post('api/usersmanage/UserUpdate', req
                , {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-type': 'application/json; charset=UTF-8'
                    }
                })
                .then(data => {
                    var json = data.data;

                    this.onSearch();
                    this.handleOk();

                    this.setState({ addusername: "", addpassword: "", repassword: "", addstatusacc: 1, tocreate: false, usercreate: "" });

                    this.setState({ loading: false });
                })
                .catch(error => {
                    this.setState({ loading: false });
                });
        } else {
            window.location.href = "logout";
        }

    }

    Getpermission() {
        if (!CheckTokenExp()) {
            this.setState({ loading: true });

            let req = JSON.stringify({
                accountId: getUrlVars()["id"]
            });

            apiClient.post('api/usersmanage/Getpermission', req
                , {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-type': 'application/json; charset=UTF-8'
                    }
                })
                .then(data => {
                    var json = data.data;

                    this.setState({
                        admin: json.admin,
                        createuser: json.createuser,
                        owner: json.owner,
                    });

                    this.setState({ loading: false });
                })
                .catch(error => {
                    this.setState({
                        admin: false,
                        createuser: false,
                        owner: false,
                    });
                    this.setState({ loading: false });
                });
        } else {
            window.location.href = "logout";
        }
        
    }

    render() {
        const { loading, message, messageSuccess, warningModalVisible, successModalVisible, users, search, templateItem,
            action, dateRange, screenwidth, screenwidth2, accname, email, statusacc, username, paytype, MadalAddUservisible,
            id, addusername, addpassword, repassword, password, addstatusacc, tocreate, creactsubeaccount, admin, createuser,
            owner, addrepassword, uemail, emailError, uemailError } = this.state;
        const routes = [
            {
                path: 'accountmanagement',
                breadcrumbName: 'Account Management',
            },
            {
                path: 'accountmanagement/userList',
                breadcrumbName: 'Account Detail',
            },
        ];
        var styleinput = {
            width: (screenwidth / 4),
            marginRight: 8,
            marginBottom: 8,
            minWidth: 200
        };
        var stylelabel = {
            width: (screenwidth / 4),
            marginRight: 8,
            minWidth: 200,
            fontWeight: 700
        };
        var styleinput2 = {
            width: ((screenwidth / 3) - 216),
            marginRight: 8,
            marginBottom: 8,
            minWidth: 200
        };
        var styleinput_name = {
            width: (screenwidth2 / 2),
            marginRight: 8,
            marginBottom: 8,
            minWidth: 200
        };
        var styleinput_date = {
            width: (screenwidth2 / 2),
            marginRight: 8,
            marginBottom: 8,
            minWidth: 200
        };
        return (
            <div className="animated fadeIn">
                <PageHeader breadcrumb={{ routes }}>
                </PageHeader>

                <div className="content">
                    <Card>
                        <Spin spinning={loading}>
                            <Row gutter={8}>
                                <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} className="row-nowrap">
                                    <label className=" control-label require" style={stylelabel}> Account name</label>
                                    <label className=" control-label require" style={stylelabel}> Email<label className="red" style={{ fontWeight: 600 }}>*  &nbsp;{!emailError && email != "" ? "Invalid email format" : ""}</label></label>
                                    <label className=" control-label require" style={stylelabel}> Payment type</label>
                                    <label className=" control-label require" style={stylelabel}> Status</label>
                                </Col>
                            </Row>

                            <Row gutter={8} id="main">
                                <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} className="row-nowrap">

                                    <Input name="name" placeholder="Name" value={accname} style={styleinput}
                                        onChange={(e) => {
                                            admin ? this.setStateByProps("accname", e.currentTarget.value) : null
                                        }} />

                                    <Input name="email" placeholder="Email" value={email} style={styleinput}
                                        onChange={(e) => {
                                            admin ? this.setStateByProps("email", e.currentTarget.value) : null
                                        }} />

                                    <SelectPayType select={paytype} setStateByProps={admin ? owner ? null : this.setStateByProps : null} style={styleinput} disabled />

                                    <SelectStatusAccount select={statusacc} setStateByProps={admin ? this.setStateByProps : null} style={styleinput} />
                                </Col>
                            </Row>


                            <Row gutter={8}>
                                <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} className="row-nowrap">
                                    <label className=" control-label require" style={stylelabel}> Username</label>
                                    <label className=" control-label require" style={stylelabel}> Password</label>
                                    <label className=" control-label require" style={{ fontWeight: 700 }}> Re-type password</label>
                                    <label className="red" style={{ marginLeft: 8, display: (password.length > 5 && password === repassword) ? 'none' : 'initial' }}>{
                                        password ?
                                            password.length > 5 ?
                                                password === repassword ? ""
                                                    : "Passwords do not match"
                                                : "The password must be at least 6 characters long."
                                            : ""
                                    }</label>
                                </Col>
                            </Row>

                            <Row gutter={8} id="main">
                                <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} className="row-nowrap">

                                    <Input name="Username" placeholder="Username" value={username} style={styleinput} disabled />

                                    <Input.Password name="Password" placeholder="Password" value={password} style={styleinput}
                                        onChange={(e) => {
                                            admin ? this.setStateByProps("password", e.currentTarget.value) : null
                                        }} />

                                    <Input.Password placeholder="Re-type Password" style={styleinput} value={repassword} onChange={(e) => { admin ? this.setStateByProps("repassword", e.target.value) : null }} />

                                    <Checkbox checked={creactsubeaccount} onChange={(e) => { admin ? owner ? null : this.setStateByProps("creactsubeaccount", e.target.checked) : null }}>Create sub account</Checkbox>

                                    <span style={{ float: 'right' }}>
                                        {
                                            admin ?
                                                <Button type="primary" style={{ marginRight: 8 }}
                                                    disabled={(password === repassword) && accname && email && username && emailError && (password || repassword ? password.length > 5 ? password === repassword ? true : false : false : true) ? false : true}
                                                    onClick={() => { this.warningModal(true, "Do you want to save this account?", "Save account successfully."), this.setStateByProps('action', 'accountcreate') }}>
                                                    <span className="fa fa-plus-circle"></span> &nbsp;&nbsp;
                                                Save
                                    </Button>
                                                : null
                                        }
                                        {
                                            admin ?

                                                <Button key="submit"
                                                    onClick={() => { this.warningModal(true, "Do you want to discard change?", "Discarded change successfully."), this.setState({ action: "discard" }) }}>
                                                    <span className="fa fa-trash-o"></span> &nbsp;&nbsp;
                                   Discard
                                </Button>
                                                : null
                                        }
                                    </span>
                                </Col>
                            </Row>

                        </Spin>
                    </Card>
                    <Card>

                        {
                            owner && (admin || createuser) ?
                                <Row gutter={8} type="flex" justify="space-between" >
                                    <Col xs={12}>
                                    </Col>
                                    <Col xs={12} >
                                        <div style={{ textAlign: "right" }}>
                                            <Button type="primary" onClick={() => {
                                                this.setStateByProps("MadalAddUservisible", true),
                                                    this.setState({ id: null, addusername: "", addpassword: "", addstatusacc: 1, tocreate: false })
                                            }}>
                                                <span className="fa fa-plus-circle"></span> &nbsp;&nbsp;
                                            New
                                                </Button>
                                        </div>
                                    </Col>
                                </Row>
                                : null

                        }

                        <Row gutter={8} className="row-nowrap" id="main">
                            <Col xs={24}>
                                <Input placeholder="User name" style={styleinput_name} onChange={(e) => this.setStateByProps("name", e.target.value)} />

                                <RangePicker
                                    placeholder={["Start Date", "End Date"]}
                                    style={styleinput_date}
                                    defaultValue={dateRange}
                                    ranges={{
                                        Today: [moment().startOf('day'), moment().endOf('day')],
                                        'Last 7 Days': [moment().subtract(6, 'd'), moment()],
                                        'Last Week': [moment().subtract(1, 'weeks').startOf('week'), moment().subtract(1, 'weeks').endOf('week')],
                                        'Last 15 Days': [moment().subtract(14, 'd'), moment()],
                                        'Last 30 Days': [moment().subtract(29, 'd'), moment()],
                                        'This Month': [moment().startOf('month'), moment().endOf('month')]
                                    }}
                                    onChange={(value) => this.setStateByProps("dateRange", value)}
                                    value={dateRange}
                                    format={dateFormat} />

                                <Button icon="search" onClick={this.onSearch}>
                                    Search
                                </Button>
                            </Col>
                        </Row>

                        <Row gutter={8} type="flex" justify="center">
                            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                <TableUsers
                                    loading={loading}
                                    search={search}
                                    templateItem={templateItem}
                                    action={action}
                                    warningModal={this.warningModal}
                                    setStateByProps={this.setStateByProps}
                                    id={getUrlVars()["id"]}
                                    GetUserDetail={this.GetUserDetail}
                                    owner={owner}
                                    admin={admin}
                                    createuser={createuser}
                                    updateWindowDimensions={this.updateWindowDimensions}
                                />

                            </Col>
                        </Row>



                    </Card>

                </div>

                <ModalAddUser
                    setStateByProps={this.setStateByProps}
                    visible={MadalAddUservisible}
                    handleOk={this.handleOk}
                    id={id}
                    username={addusername}
                    password={addpassword}
                    repassword={addrepassword}
                    statusacc={addstatusacc}
                    tocreate={tocreate}
                    loading={loading}
                    warningModal={this.warningModal}
                    owner={owner}
                    admin={admin}
                    email={uemail}
                    createuser={createuser}
                    emailError={uemailError}
                />
                <ModalWarning loading={loading}
                    warningModalVisible={warningModalVisible}
                    warningModal={this.warningModal}
                    successModal={this.successModal}
                    modalcountDown={this.modalcountDown}
                    message={message}
                    messageSuccess={messageSuccess}
                />

                <ModalSuccess loading={loading}
                    successModalVisible={successModalVisible}
                    successModal={this.successModal}
                    messageSuccess={messageSuccess}
                />

            </div >
        );
    }
}



import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import 'moment-timezone';
import { Table, Divider, Tag, Icon, Button, Row, Col, Modal, Input } from 'antd';
import { apiClient } from '../../../api';
import{root} from '../../../config';
import { token, validateEmail, CheckTokenExp } from '../../../config';

moment.tz.setDefault("Asia/Bangkok");

const { TextArea } = Input;

export default class TableBacklist extends Component {

    constructor(props) {
        super(props);

        this.state = {
            columns: [
                {
                    title: 'Name',
                    dataIndex: 'BLK_NAME',
                    key: 'BLK_NAME',
                    width: 200,
                    render: text => (text ? <div className="table-title" title={text}>{text}</div> : "No name")
                },
                {
                    title: 'Destination',
                    dataIndex: 'BLK_DES',
                    key: 'BLK_DES',
                    width: 150,
                },
                {
                    title: 'Channel',
                    dataIndex: 'BLK_DES',
                    key: 'BLK_DES',
                    width: 100,
                    render: (text, item) => (
                        <div>
                            {validateEmail(item.BLK_DES) ?
                                <span className="fa fa-envelope-o" style={{ paddingRight: 10, fontSize: 16 }} />
                                :
                                <span className="fa fa-mobile" style={{ paddingRight: 10, fontSize: 16 }} />
                            }
                        </div>
                    )
                },
                {
                    title: 'Description',
                    dataIndex: 'BLK_DESC',
                    key: 'BLK_DESC',
                    width: 200,
                    render: text => (<div className="table-title" title={text}>{text}</div>)
                },
                {
                    title: 'Create Date',
                    dataIndex: 'BLK_CREATE_DATE',
                    key: 'BLK_CREATE_DATE',
                    width: 150,
                },
            ],
            Blacklistslist: [],
            pagesize: 10,
            currentpage: 1,
            minpage: 1,
            maxpage: 10,
            enddata: 10,
            totalpage: 0,
        };

        this.onChangePage = this.onChangePage.bind(this);
        this.getData = this.getData.bind(this);


    }

    componentDidUpdate(prevProps) {
        if (this.props.search !== prevProps.search) {
            this.getData();
        }
    }

    componentDidMount() {
        this.getData();
    }

    getData() {
        if (!CheckTokenExp()) {
            this.setState({ loading: true });

            const { search } = this.props;

            this.setState(state => {

                let req = JSON.stringify({
                    "START_DATE": search.dateRange[0] ? moment(search.dateRange[0]).format("DD/MM/YYYY") : null,
                    "END_DATE": search.dateRange[1] ? moment(search.dateRange[1]).format("DD/MM/YYYY") : null,
                    "SEARCH_BLACKLIST": search.name,
                    "DEST": search.dest,
                    "MINPAGE": state.minpage,
                    "PAGESIZE": state.maxpage
                });

                apiClient.post('api/Blacklist/BLACKLIST_LIST', req
                    , {
                        headers: {
                            'Authorization': `Bearer ${token}`,
                            'Content-type': 'application/json; charset=UTF-8'
                        }
                    })
                    .then(data => {
                        this.setState(state => {
                            var json = data.data;
                            return {
                                Blacklistslist: json.black_list,
                                totalpage: json ? json.totalpage : 0,
                                currentpage: state.minpage == 1 ? 1 : state.currentpage,
                                minpage: 1
                            };
                        }, () => { this.props.updateWindowDimensions() });

                        this.setState({ loading: false });
                    })
                    .catch(error => {
                        this.setState({
                            data: [],
                            totalpage: 0,
                            currentpage: 1,
                            minpage: 1
                        });
                        this.setState({ loading: false });
                    });
            });
        } else {
            window.location.href = "logout";
        }
        
    }

    onChangePage(currentPage, pageSize) {
        var currentpage = this.state.pagesize == pageSize ? currentPage : 1;
        var maxpage = currentpage * pageSize;
        var minpage = maxpage - pageSize + 1;

        this.setState({
            loading: true,
            currentpage: currentpage,
            pagesize: pageSize,
            minpage: minpage,
            maxpage: maxpage
        });
        this.getData(minpage);
    }

    render() {
        const { columns, Blacklistslist, loading } = this.state;
        const rowSelection = {
            columnWidth: 30,
            onChange: (selectedRowKeys, selectedRows) => {
                this.props.setStateByProps("key", selectedRowKeys)
            },
        };
        return (
            <div>

                <Table
                    loading={loading}
                    rowKey={key => key.BLK_ID}
                    pagination={{
                        defaultPageSize: "10",
                        pageSizeOptions: ['10', '20', '50', '100'],
                        showSizeChanger: true,
                        total: this.state.totalpage,
                        showTotal: (total, range) => `${range[0]}-${range[1]} of ${this.state.totalpage} items`,
                        onShowSizeChange: this.onChangePage,
                        onChange: this.onChangePage,
                        current: this.state.currentpage
                    }}
                    rowSelection={rowSelection}
                    columns={columns}
                    dataSource={Blacklistslist}
                    className="table-wop"
                    tableLayout="fixed"
                />




            </div>

        );
    }
}



/* eslint-disable */
import React, { Component } from "react";
import { Redirect } from 'react-router-dom';
import { Form, Input, DatePicker, TimePicker, Select, Cascader, InputNumber, Icon, Row, Col, Button, Tooltip, Checkbox, Spin, Modal } from 'antd';
import moment from 'moment';
import 'moment-timezone';
import { apiAnalyze, apiClient } from '../../../../api';
import { token, getUrlVars, formatNumber, getAccId, CheckTokenExp, ReplaceUrlTaga, UserInfo, getLinkbacklist } from '../../../../config';
import InputTo from '../../../../components/Wop/Input/InputTo';
import SelectSender from '../../../../components/Wop/Select/SelectSender';
import SelectTemplate from '../../../../components/Wop/Select/SelectTemplate';
import ButtonGroupBroadcast from '../../../../components/Wop/Button/ButtonGroupBroadcast';
import ButtonUpload from '../../../../components/Wop/Button/ButtonUpload';
import MultipleTrigger from '../../../../components/Wop/Mentions/MultipleTrigger';
import SelectParameters from '../../../../components/Wop/Select/SelectParameters';
import { toText, fromText, templateText, broadcastNameText } from '../../../../components/Wop/TooltipText/Text';
import ModalServerError from "../../../../components/Wop/Modal/ModalServerError";

moment.tz.setDefault("Asia/Bangkok");

const { Option } = Select;
const dateFormat = 'DD/MM/YYYY';
const timeFormat = 'HH:mm';

export default class BroadcastByTemplate extends Component {
    constructor(props) {
        super(props);
        this.indexcontent = this.indexcontent.bind(this);
        this.countMessage = this.countMessage.bind(this);

        this.uploadfile_showDrawer = this.uploadfile_showDrawer.bind(this);
        this.uploadfile_onClose = this.uploadfile_onClose.bind(this);
        this.analyze_showDrawer = this.analyze_showDrawer.bind(this);
        this.analyze_onClose = this.analyze_onClose.bind(this);
        this.onClickPreview = this.onClickPreview.bind(this);
        this.onChangeCheckbox = this.onChangeCheckbox.bind(this);
        this.onDraft = this.onDraft.bind(this);
        this.onDiscard = this.onDiscard.bind(this);
        this.handleChangevariable = this.handleChangevariable.bind(this);

        this.setStateByProps = this.setStateByProps.bind(this);
        this.shortedurl = this.shortedurl.bind(this);
        this.GetdataDraft = this.GetdataDraft.bind(this);
        this.GetPeopleList = this.GetPeopleList.bind(this);
        this.GetTagList = this.GetTagList.bind(this);
        this.GetColumnsEcm = this.GetColumnsEcm.bind(this);
        this.checkinputnull = this.checkinputnull.bind(this);
        this.SaveCampaign = this.SaveCampaign.bind(this);
        this.AnalyzeFunc = this.AnalyzeFunc.bind(this);

        this.analyze_onSend = this.analyze_onSend.bind(this);
        this.handleTemplate = this.handleTemplate.bind(this);
        this.disabledDate = this.disabledDate.bind(this);
        this.getDisabledHours = this.getDisabledHours.bind(this);
        this.getDisabledMinutes = this.getDisabledMinutes.bind(this);

        this.AnalyzeFunc2 = this.AnalyzeFunc2.bind(this);
        this.downloadFile = this.downloadFile.bind(this);

        this.child = React.createRef();
        this.state = {
            indexcontent: 0,
            content: "",
            content_old: "",
            MessageCount: 1,
            CharacterCount: 160,
            contentlength: 0,
            variable: ["firstName", "lastName", "address",
                "city", "country", "gender", "birthDate"],
            uploadfile_visible: false,
            analyze_visible: false,
            preview: false,
            clearstate: false,
            broadcastName: "",
            to: [],
            fileList: [],
            file_size: 0,
            sender: undefined,
            schedule_commu_date: "",
            schedule_commu_time: "",
            cb_shortedurl: false,
            createTemplate: false,
            PeopleLists: [],
            TagsLists: [],
            SMS_DRAFT: false,
            selectedFileList: [],
            senderlist: [],
            total_credit: [],
            Template: undefined,
            btnsend_loading: false,
            filedraft: [],
            fileOldList: [],
            errorMessage: "",
            anl_to: "",
            anl_shorturlflag: false,
            anl_message: "",
            anl_total: 0,
            anl_credit: 0,
            anl_invalid: 0,
            anl_blacklist: 0,
            anl_duplicate: 0,
            anl_shortest: 0,
            anl_longest: 0,
            anl_resultCode: "",
            anl_description: "",
            anl_errorMessage: "",
            smstoCount: 0,
            smsMaxStable: 1000,
            errorSmsto: "",
            Linkbacklist: getLinkbacklist(),
            modalVisible: false,
        };
    }
    componentDidMount() {
        this.GetdataDraft();
        this.GetPeopleList()
        this.GetTagList();
        ReplaceUrlTaga();
    }

    indexcontent(e) {
        var start = this.state.content.substring(0, e.target.selectionEnd);
        var c = (start.split("{").length - 1) * 2;
        this.setState({ indexcontent: e.target.selectionEnd + c });
    }

    countMessage(content) {
        //1 Message / Used with 5 remaining 165
        var contentlength = content == undefined ? 0 : JSON.stringify(content.trim()).length == 0 ? 0 : (JSON.stringify(content.trim()).length - 2);
        var msgCount = 0;
        var messremaining = 160;
        if (contentlength) {
            if (!content.match(/^([a-zA-Z0-9\|!#$%&/()*^=?»«@£§€{}.-;'"<>_[\]\\,\-\+ \n\r])+$/i)) {
                msgCount = contentlength <= 70 ? 1 : contentlength <= 134 ? 2 : parseInt(Math.ceil(parseFloat(contentlength) / 67));
                messremaining = contentlength <= 70 ? 70 - contentlength : contentlength <= 134 ? 134 - contentlength : (134 + (67 * (msgCount - 2))) - contentlength;
            } else {
                msgCount = contentlength <= 160 ? 1 : contentlength <= 306 ? 2 : parseInt(Math.ceil(parseFloat(contentlength) / 153));
                messremaining = contentlength <= 160 ? 160 - contentlength : contentlength <= 306 ? 306 - contentlength : (306 + (153 * (msgCount - 2))) - contentlength;
            }
        }
        this.setState({
            content: content,
            content_require: false,
        });
        this.setState({ MessageCount: msgCount });
        this.setState({ contentlength: contentlength });
        this.setState({ CharacterCount: messremaining });

    }

    uploadfile_showDrawer() {
        this.setState({
            uploadfile_visible: true,
        });
    }

    uploadfile_onClose() {
        this.setState({
            uploadfile_visible: false,
        });

    }

    analyze_showDrawer() {
        this.AnalyzeFunc();
        this.setState({ analyze_visible: true });
    }

    analyze_onClose() {
        this.setState({
            analyze_visible: false,
        });

    }

    analyze_onSend() {
        if (this.state.schedule_commu_date && this.state.schedule_commu_time) {   
            var scheduledate = moment(this.state.schedule_commu_date).format("DD/MM/YYYY") + " " + moment(this.state.schedule_commu_time).format("HH:mm");
            if(scheduledate <= moment().format("DD/MM/YYYY HH:mm") &&  moment().format("DD/MM/YYYY") == moment(this.state.schedule_commu_date).format("DD/MM/YYYY")){
             Modal.error({
                 className: 'popup-error',
                 title: 'Error',
                 onOk: () => Modal.destroyAll(),
                 content: "Invalid schedule date, Please change schedule date to proceed the next step",
                 icon: <Icon type="close-circle" style={{ fontSize: 36 }} />,
                 zIndex: 1300
             });
            }else{
                this.setState({
                    loading: true,
                    clearstate: true,
                    SMS_DRAFT: false,
                    to: [...this.state.to],
                    content: this.state.content,
                    sender: this.state.sender,
                    SEND_TYPE: (this.state.selectedFileList.length > 0 ? 2 : 1),
                }, function () {
                    this.SaveCampaign();
                });
            }

        } else {
            this.setState({
                loading: false,
                clearstate: true,
                SMS_DRAFT: false,
                to: [...this.state.to],
                content: this.state.content,
                sender: this.state.sender,
                SEND_TYPE: (this.state.selectedFileList.length > 0 ? 2 : 1),
            }, function () {
                this.SaveCampaign();
            });
        }
        this.setState({
            analyze_visible: false,
        });
    }
    
    onDraft() {
        this.setState({ loading: true });
        var isnull = this.checkinputnull();
        if (!isnull) {
            this.setState({
                SMS_DRAFT: true,
                to: [...this.state.to],
                content: this.state.content,
                sender: this.state.sender,
                SEND_TYPE: (this.state.selectedFileList.length > 0 ? 2 : 1),
            }, function () {
                this.SaveCampaign();
            });


        } else {
            this.setState({ loading: false });
        }
    }

    SaveCampaign() {
        if (!CheckTokenExp()) {
            const { fileList, fileOldList } = this.state;
            const formData = new FormData();

            fileList.forEach(file => {
                // if (file.type == "oldfile") {
                //     oldfile.push({ FILE_ID: file.uid, ECM_UID: file.ecm, FILE_NAME: file.name });
                // } else {
                formData.append("files[]", file);
                // }
            });

            var oldfile = new Array();
            fileOldList.forEach(file => {
                if (file.type == "oldfile") {
                    oldfile.push({ FILE_ID: file.uid, ECM_UID: file.ecm, FILE_NAME: file.name });
                }
            });

            let req = JSON.stringify({
                "CAMPAIGN_ID": this.state.CAMP_ID,
                "SMS_FROM": this.state.sender,
                "SMS_TO": this.state.to,
                "SMS_TEXT": this.state.content.trim(),
                "CAMPAIGN_NAME": this.state.broadcastName,
                "MESSAGE": this.state.broadcastName,
                "SCHEDULE_DATE": this.state.schedule_commu_date && this.state.schedule_commu_time ? moment(this.state.schedule_commu_date).format("DD/MM/YYYY") + " " + moment(this.state.schedule_commu_time).format("HH:mm") : null,
                "SEND_TYPE": 4,
                "SPEED_AMOUNT": 0,
                "SPEED_UNIT": 0,
                "SOURCE_FILE": this.state.filename,
                "DESTINATION_FILE": this.state.result,
                "SMS_DRAFT": this.state.SMS_DRAFT,
                "FILES": oldfile,
                "SENDER": this.state.sender,
                "TEMPLATE_ID": this.state.Template,
                "shortedurl": this.state.cb_shortedurl,
                "ANL_TO": this.state.anl_to,
                "ANL_SHORTURLFLAG": this.state.anl_shorturlflag,
                "ANL_MESSAGE": this.state.anl_message,
                "ANL_TOTAL": this.state.anl_total,
                "ANL_CREDIT": this.state.anl_credit,
                "ANL_BLACKLIST": this.state.anl_blacklist,
                "ANL_DUPLICATE": this.state.anl_duplicate,
                "ANL_INVALID": this.state.anl_invalid,
                "ANL_SHORTEST": this.state.anl_shortest,
                "ANL_LONGEST": this.state.anl_longest,
                "ANL_DESCRIPTION": this.state.anl_description,
                "ANL_RESULTCODE": this.state.anl_resultCode,
                "ANL_ERRORMESSAGE": this.state.anl_errorMessage,
            }).replace(/\\r/g, '\r').replace(/\\n/g, '\n');
            let user_info = JSON.stringify(UserInfo());
            formData.append('req', req);
            formData.append('user_info', user_info);

            apiClient.post('api/Campaign/Save', formData
                , {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-type': 'application/json; charset=UTF-8'
                    }
                })
                .then(data => {
                    var json = data.data;
                    if (json.code != 200) {
                        this.child.current.modalResult(false, json.message);
                        this.setClearState(false);
                        return false;
                    }
                    this.setClearState(false);
                    this.child.current.modalResult(true);
                    //<Redirect to='/broadcast/' />
                })
                .catch(error => {
                    this.child.current.modalResult(false, "เกิดข้อผิดพลาด กรุณาติดต่อ admin ");
                    this.setClearState(false);
                    return false;
                });
        } else {
            window.location.href = "logout";
        }
    }

    setClearState(bool) {
        this.setState({
            clearstate: bool
        });
    }

    checkinputnull() {
        var campaignname_require = this.state.broadcastName ? false : true
        var to_require = this.state.to.length > 0 ? false : true
        var sender_require = this.state.sender ? false : true
        var content_require = this.state.content ? false : true

        this.setState({ campaignname_require, to_require, sender_require, content_require });

        if (campaignname_require || to_require || sender_require || content_require) {
            this.setState({ isnull: true })
            return true;
        } else {
            this.setState({ isnull: false })
            return false;
        }

    }

    onDiscard() {
        this.setState({ clearstate: true });
        this.props.clearType();

        //window.location.reload();
    }

    onChangeCheckbox(e) {
        this.setState({ [e.target.name]: e.target.checked });
        this.shortedurl(e.target.checked);
    }

    onClickPreview(value) {
        if (!CheckTokenExp()) {
            var to_require = this.state.to.length > 0 ? false : true
            var sender_require = this.state.sender ? false : true
            var content_require = this.state.content ? false : true

            if (!to_require && !sender_require && !content_require) {
                this.setState({
                    preview: value
                });
            }

            const { fileList } = this.state;
            const formData = new FormData();

            var oldfile = new Array();
            fileList.forEach(file => {
                if (file.type == "oldfile") {
                    oldfile.push({ FILE_ID: file.uid, ECM_UID: file.ecm, FILE_NAME: file.name });
                } else {
                    formData.append("files[]", file);
                }
            });

            let req = JSON.stringify({
                "OriginContent": this.state.cb_shortedurl == true ? this.state.content_old : this.state.content,
                "shorturl": this.state.cb_shortedurl,
                "FILES": oldfile,
            });
            formData.append('req', req);

            apiClient.post('api/Campaign/Preview', formData
                , {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-type': 'application/json; charset=UTF-8'
                    }
                })
                .then(data => {
                    var json = data.data;

                    this.setState({ content_old: json ? json : this.state.content });

                })
                .catch(error => {

                });

            setTimeout(() => {
                let modalcontent = document.querySelectorAll('.ant-modal-content');
                for (var i = 0; i < modalcontent.length; i++) {
                    modalcontent[i].className += " ant-modal-content-hide";
                }
            }, 10);
        } else {
            window.location.href = "logout";
        }

    }

    handleChangevariable(e) {
        var start = this.state.content.substring(0, this.state.indexcontent);
        var end = this.state.content.substring(this.state.indexcontent);
        this.setState({ content: start + "{" + e + "}" + end },
            () => {
                this.countMessage(this.state.content);
                this.setState({ content_old: this.state.content });
                this.shortedurl(this.state.cb_shortedurl);
                this.setState({ indexcontent: this.state.indexcontent + e.length + 2 });
            });
    }

    setStateByProps(parameters, value) {
        this.setState({ [parameters]: value });
        if (parameters == "Template") this.handleTemplate(value);
        if (parameters == "to") {
            if (value.toString().length <= this.state.smsMaxStable) {
                this.setState({
                    errorSmsto: "",
                });
            }
            this.setState({
                smstoCount: value.toString().length
            });
        }

        if (parameters == "errorLength") {
            this.setState({
                errorSmsto: value,
            });
        }

        if (parameters == "schedule_commu_time") {
            if(value < moment().add(5, 'minutes') && value != null && moment().format("DD/MM/YYYY") == moment(this.state.schedule_commu_date).format("DD/MM/YYYY")){
                this.setState({
                    schedule_commu_time: moment().add(5, 'minutes'),
                });
            }else{
                this.setState({
                    schedule_commu_time: value
                });
            }
        }
    }

    shortedurl(check) {
        if (!CheckTokenExp()) {
            if (check == true) {
                let req = JSON.stringify({
                    messageid: "",
                    message: this.state.content
                });

                apiClient.post('api/function/converturl', req
                    , {
                        headers: {
                            'Authorization': `Bearer ${token}`,
                            'Content-type': 'application/json; charset=UTF-8'
                        }
                    })
                    .then(data => {
                        var json = data.data;

                        this.setState({ content_old: json });

                    })
                    .catch(error => {

                    });
            }
        } else {
            window.location.href = "logout";
        }

    }

    GetdataDraft() {
        if (!CheckTokenExp()) {
            var id = getUrlVars()["id"];
            if (id) {
                this.setState({ loading: true });

                apiClient.get('api/Campaign/CampaignInfo/' + id
                    , {
                        headers: {
                            'Authorization': `Bearer ${token}`,
                            'Content-type': 'application/json; charset=UTF-8'
                        }
                    })
                    .then(data => {
                        var json = data.data;

                        var SCHEDULE = json.SCHEDULE.split(" ");
                        var oldFiles = json.FILES.map((item) => {
                            this.GetColumnsEcm(item.ECM_UID);
                            this.downloadFile(item.ECM_UID, item.FILE_NAME).then((res) => {
                                if (res) {
                                    this.setState({
                                        fileList: json.FILES.length > 0 ? this.state.filedraft : [],
                                    });
                                }
                            }).catch((err) => { })
                            return {
                                uid: item.FILE_ID,
                                name: item.FILE_NAME,
                                status: 'done',
                                url: item.ECM_UID,
                                type: "oldfile",
                                ecm: item.ECM_UID
                            }
                        });

                        if (json.SMS_TO) {
                            json.SMS_TO.map((item) => {
                                item.search("person_") == 0 ? this.GetPeopleList(item) : item.search("tag_") == 0 ? this.GetTagList(item) : "";
                            });
                            this.setState({ smstoCount: json.SMS_TO.toString().length });
                        }

                        this.setState(state => {
                            return {
                                CAMP_ID: json.CAMP_ID,
                                broadcastName: json.CAMP_NAME,
                                content: json.SMS_CONTENT,
                                content_old: json.SMS_CONTENT,
                                sender: json.SMS_FROM,
                                newTemplate: json.CREATE_TEMPLATE,
                                cb_shortedurl: json.SHORT_URL,
                                to: json.SMS_TO,
                                // fileList: json.FILES.length > 0 ? file : [],
                                fileOldList: json.FILES.length > 0 ? oldFiles : [],
                                TEMPLATE_ID: json.TEMPLATE_ID,
                                Template: json.TEMPLATE_ID,
                                schedule_commu_date: SCHEDULE[0] == "" ? null : moment(SCHEDULE[0].toString(), "DD-MM-YYYY"),
                                schedule_commu_time: SCHEDULE[0] == "" ? null : moment(SCHEDULE[1].toString(), "HH:mm"),
                                draft_spinning: false,
                                indexcontent: json.SMS_CONTENT.length,
                            }
                        }), this.shortedurl(this.state.cb_shortedurl), this.countMessage(json.SMS_CONTENT);

                        setTimeout(() => {
                            this.GetPeopleList();
                            this.GetTagList();
                        }, 200);

                        this.setState({ loading: false });
                    })
                    .catch(error => {
                        this.setState({ loading: false });
                    });

            }
        } else {
            window.location.href = "logout";
        }

    }

    GetPeopleList(value) {
        if (!CheckTokenExp()) {
            this.state.PeopleLists.length > 0 ? "" : this.setState({ loading: true });
            var search = value ? value : "null";
            const encodedString = new Buffer(search).toString('base64');

            apiClient.get('api/Campaign/PeopleLists/' + encodedString
                , {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-type': 'application/json; charset=UTF-8'
                    }
                })
                .then(data => {
                    if (data.status === 200 && data.data) {
                        if (search.search("person_") == 0 || search != null) {
                            var list = [...data.data, ...this.state.PeopleLists];
                            const dataList = Array.from(new Set(list.map(a => a.ID)))
                                .map(id => {
                                    return list.find(a => a.ID === id)
                                });
                            this.setState({ PeopleLists: dataList });
                        } else {
                            this.setState({ PeopleLists: data.data });
                        }
                    } else {
                        this.setState({ modalVisible: true });
                        this.setState({ PeopleLists: [] });
                    }
                    this.setState({ loading: false });
                })
                .catch(error => {
                    this.setState({ modalVisible: true });
                    this.setState({ loading: false });
                });
        } else {
            window.location.href = "logout";
        }

    }

    GetTagList(value) {
        if (!CheckTokenExp()) {
            this.state.TagsLists.length > 0 ? "" : this.setState({ loading: true });
            var search = value ? value : "null";

            const encodedString = new Buffer(search).toString('base64');
            apiClient.get('api/Campaign/TagsLists/' + encodedString
                , {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-type': 'application/json; charset=UTF-8'
                    }
                })
                .then(data => {
                    var json = data.data;
                    if (typeof json == "object") {
                        if (search.search("tag_") == 0 || search != null) {
                            var d = [...json, ...this.state.TagsLists];
                            const data = Array.from(new Set(d.map(a => a.ID)))
                                .map(id => {
                                    return d.find(a => a.ID === id)
                                });
                            this.setState({ TagsLists: data });
                            this.setState({ loading: false });
                        } else {
                            this.setState({ TagsLists: json ? json : [] });
                            this.setState({ loading: false });
                        }
                    }
                })
                .catch(error => {
                    this.setState({ loading: false });
                });
        } else {
            window.location.href = "logout";
        }

    }

    GetColumnsEcm(id) {
        this.setState({ loading: true });

        apiClient.get('api/GetColumns/ecm/' + id
            , {
                headers: {
                    'Content-type': 'application/json; charset=UTF-8'
                }
            })
            .then(data => {
                var json = data.data;

                let variable = [...new Set(this.state.variable)];
                this.setState({ variable: [] });
                const arr = json.columns.filter((item) => !variable.includes(item));
                var value = this.state.to.filter((item) => !item.includes("tag_") == true).filter((item) => !item.includes("person_") == true);
                this.setState(state => ({
                    variable: [...state.variable, ...arr],
                    to: value,
                    loading: false,
                    file_size: state.file_size + json.size,
                }));
            })
            .catch(error => {
                this.setState({ loading: false });
            });
    }

    AnalyzeFunc2() {
        const { fileList, filedraft } = this.state;
        const formData = new FormData();

        // fileList.forEach(file => {
        //     if (!file.ecm) {
        //         formData.append("Files[]", new Blob([file], { type: file.type }));
        //     }
        // });

        // filedraft.forEach(file => {
        //     formData.append("Files[]", new Blob([file], { type: file.type }));
        // });

        fileList.map(file => {
            // เปลี่ยน logic การดึงไฟล์เข้ามา ใน process draft(edit)
            if (!file.ecm) {
                formData.append("Files[]", new Blob([file], { type: file.type }));
            }
            // else {
            //     console.log(2)
            //     filedraft.map(f => {
            //         if (f.name == file.name) {
            //             formData.append("Files[]", new Blob([f], { type: f.type }));
            //         }
            //     })
            // }
        })

        formData.append("accountId", getAccId());
        formData.append("message", this.state.content);
        formData.append("shortUrlFlag", this.state.cb_shortedurl);
        formData.append("TO", this.state.to);

        apiClient.post('api/Analyze', formData
            , {
                headers: {

                }
            })
            .then(data => {
                var json = data.data;
                if (json.resultCode !== "000") {
                    this.setState({
                        errorMessage: json.resultCode == "500" ? "Analyze Error" : json.description,
                        btnsend_loading: false,
                        loading: false
                    });
                    return false;
                }

                var total_credit = [
                    {
                        key: '1',
                        name: 'Shortest',
                        characters: formatNumber(json.shortest),
                    },
                    {
                        key: '2',
                        name: 'Longest',
                        characters: formatNumber(json.longest),
                    },
                ];

                var total_recipients = [
                    {
                        key: '1',
                        status: 'Unique',
                        recipients: json.total,
                    },
                    {
                        key: '2',
                        status: 'Duplicate',
                        recipients: json.duplicate,
                    },
                    {
                        key: '3',
                        status: 'Blacklist',
                        recipients: json.blackList,
                    },
                    {
                        key: '4',
                        status: 'Invalid',
                        recipients: json.invalid,
                    },
                ];

                var analyzeTable = [
                    {
                        key: '1',
                        name: this.state.sender,
                        schedule: this.state.schedule_commu_date ? moment(moment(moment(this.state.schedule_commu_date).format("DD/MM/YYYY") + " " + moment(this.state.schedule_commu_time).format("HH:mm"), "DD/MM/YYYY HH:mm")).format('DD/MM/YYYY HH:mm')
                            : moment().format("DD/MM/YYYY HH:mm")
                    }
                ];

                this.setState({
                    total_credit: total_credit,
                    sum_credit: formatNumber(json.credit),
                    SUM_RECIPTENTS: formatNumber(json.total),
                    total_recipients: total_recipients,
                    analyzeTable: analyzeTable,
                    sum_message: json.total,

                    anl_to: this.state.to,
                    anl_shorturlflag: this.state.cb_shortedurl,
                    anl_message: this.state.content,
                    anl_total: json.total,
                    anl_credit: json.credit,
                    anl_invalid: json.invalid,
                    anl_blacklist: json.blackList,
                    anl_duplicate: json.duplicate,
                    anl_shortest: json.shortest,
                    anl_longest: json.longest,
                    anl_resultCode: json.resultCode,
                    anl_description: json.description,
                    anl_errorMessage: json.description,

                    Analyz: false,
                    show_summary: true,
                    loading: false,
                    btnsend_loading: false,
                    errorMessage: ""
                });

                // if (json.resultCode !== "000") {
                //     this.setState({ errorMessage: json.description, loading: false, btnsend_loading: false });
                // }
            })
            .catch(error => {
                this.setState({ btnsend_loading: false });
                this.setState({ loading: false });
            });
    }

    AnalyzeFunc() {
        var isnull = this.checkinputnull();
        if (!isnull) {
            this.setState({ btnsend_loading: true, loading: true });
            //uploadfile formdata
            this.AnalyzeFunc2();
        }
    }

    handleTemplate(TempId) {
        if (!CheckTokenExp()) {
            if (TempId > 0) {

                apiClient.get('api/Campaign/TemplateInfo/' + TempId
                    , {
                        headers: {
                            'Authorization': `Bearer ${token}`,
                            'Content-type': 'application/json; charset=UTF-8'
                        }
                    })
                    .then(data => {
                        var json = data.data;
                        this.setState({
                            sender: json.SMS_FROM,
                            temp_from: json.SMS_FROM,
                            content: json.SMS_CONTENT,
                            content_old: json.SMS_CONTENT,
                            temp_content: json.SMS_CONTENT,
                            Template: TempId,
                            temp_Id: TempId,
                            cb_shortedurl: json.SHORT_URL
                        }, function () {
                            this.countMessage(json.SMS_CONTENT);
                            this.shortedurl(this.state.cb_shortedurl);
                        });
                    })
                    .catch(error => {
                        this.setState({ loading: false });
                    });
            }
        } else {
            window.location.href = "logout";
        }

    }

    disabledDate(current) {
        return current && current < moment(new Date()).add(-1, 'days');
    }

    getDisabledHours() {
        var hours = [];
        if (moment(this.state.schedule_commu_date).format("DD/MM/YYYY") == moment().format("DD/MM/YYYY")) {
            for (var i = 0; i < moment().hour(); i++) {
                hours.push(i);
            }
        }
        return hours;
    }

    getDisabledMinutes() {
        var minute = [];
        if (!this.state.schedule_commu_time) {
            for (var i = 0; i < 60; i++) {
                minute.push(i);
            }
        }

        if (moment(this.state.schedule_commu_date).format("DD/MM/YYYY") == moment().format("DD/MM/YYYY") &&
            moment(this.state.schedule_commu_time).format("HH") == moment().format("HH")
        ) {
            for (var i = 0; i <moment().add(5, 'minutes').format('mm'); i++) {
                minute.push(i);
            }
        }
        return minute;
    }

    downloadFile(item, name) {
        if (!CheckTokenExp()) {
            return new Promise((resolve, reject) => {
                apiClient.get('api/file/download/' + item
                    , {
                        headers: {
                            'Authorization': `Bearer ${token}`,
                            'Content-type': 'application/json; charset=UTF-8'
                        },
                        responseType: 'blob',
                    })
                    .then(data => {
                        var json = data.data;

                        const file = new File([json], name, { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" })
                        this.setState(state => { return { filedraft: [...state.filedraft, file] } });
                        console.log("working ..")
                        resolve(true)
                    })
                    .catch(error => {
                        console.log("error downloadfile ECM")
                        reject()
                    });
            }).catch((e) => {
                console.log("errrrr ..")
                reject()
            })
        } else {
            window.location.href = "logout";
        }

    }

    render() {
        const marginbottomrow = { marginBottom: 16 };
        const { uploadfile_visible, analyze_visible, preview, variable, to,
            fileList, file_size, sender, content_old, schedule_commu_date, schedule_commu_time, cb_shortedurl,
            broadcastName, createTemplate, PeopleLists, TagsLists, loading, total_credit, sum_credit, SUM_RECIPTENTS,
            total_recipients, analyzeTable, Template, content, btnsend_loading, 
            errorMessage, errorSmsto, smstoCount, smsMaxStable, Linkbacklist, modalVisible } = this.state;
        return (
            <div>
                <ModalServerError modalVisible={modalVisible} />
                <Spin spinning={this.state.clearstate}>
                    <Row gutter={16} style={marginbottomrow}>
                        <Col xs={24} sm={24} md={24} lg={24} xl={1}>
                            &nbsp;
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={24} xl={16}>
                            <Row gutter={16}>
                                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                    <label className=" control-label require" style={{ fontWeight: 700 }}>Broadcast Name
                                    </label>
                                    <label className="red">*</label>
                                    &nbsp; &nbsp;
                                    <Tooltip placement="rightTop" title={broadcastNameText}>
                                        <span className="fa fa-info-circle wop-gray-low size14"></span>
                                    </Tooltip>

                                    <Input placeholder="Enter new broadcast name" value={broadcastName} onChange={(e) => this.setStateByProps("broadcastName", e.target.value)} />
                                </Col>
                                <Col xs={24} sm={24} md={24} lg={24} xl={6}>

                                </Col>
                            </Row>
                        </Col>
                    </Row>

                    <Row gutter={16} style={marginbottomrow}>
                        <Col xs={24} sm={24} md={24} lg={24} xl={1}>
                            &nbsp;
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={24} xl={16}>
                            <label className=" control-label require" style={{ fontWeight: 700 }}>Template  </label>
                            <label className="red">*</label>
                            &nbsp;&nbsp;
                            <Tooltip placement="rightTop" title={templateText}>
                                <span className="fa fa-info-circle wop-gray-low size14"></span>
                            </Tooltip>
                            <SelectTemplate setStateByProps={this.setStateByProps} select={Template} />
                        </Col>
                    </Row>

                    <Row gutter={16} style={marginbottomrow}>
                        <Col xs={24} sm={24} md={24} lg={24} xl={1}>
                            &nbsp;
                        </Col>
                        <Col xs={20} sm={20} md={20} lg={20} xl={16}>
                            <Row gutter={16}>
                                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                    <label className=" control-label require" style={{ fontWeight: 700 }}>To
                                    </label>
                                    <label className="red">*</label>
                                    &nbsp; &nbsp;
                                    <Tooltip placement="rightTop" title={toText}>
                                        <span className="fa fa-info-circle wop-gray-low size14"></span>
                                    </Tooltip>
                                    <div style={{ float: 'right' }}>
                                        {
                                            errorSmsto == ""
                                                ?
                                                <label > {smstoCount} / {smsMaxStable}</label>
                                                :
                                                <label className="red"> {smstoCount} / {smsMaxStable} {errorSmsto}</label>
                                        }
                                    </div>
                                    <InputTo
                                        loading={loading}
                                        to={to}
                                        fileList={fileList}
                                        file_size={file_size}
                                        PeopleLists={PeopleLists}
                                        TagsLists={TagsLists}
                                        setStateByProps={this.setStateByProps}
                                        GetTagList={this.GetTagList}
                                        GetPeopleList={this.GetPeopleList}
                                        maxLength={smsMaxStable}
                                    />
                                </Col>
                            </Row>
                        </Col>
                        <Col xs={4} sm={4} md={4} lg={4} xl={7}>
                            <Row gutter={16}>
                                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                    <Row gutter={16}>
                                        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                            <label className=" control-label require" style={{ fontWeight: 700 }}> &nbsp;&nbsp;</label>
                                        </Col>
                                        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                            <ButtonUpload uploadfile_visible={uploadfile_visible}
                                                uploadfile_showDrawer={this.uploadfile_showDrawer}
                                                uploadfile_onClose={this.uploadfile_onClose}
                                                setStateByProps={this.setStateByProps}
                                                to={to}
                                                fileList={fileList}
                                                file_size={file_size}
                                                filemax={10}
                                            />
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                    </Row>

                    <Row gutter={16} style={marginbottomrow}>
                        <Col xs={24} sm={24} md={24} lg={24} xl={1}>
                            &nbsp;
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={24} xl={16}>
                            <label className=" control-label require" style={{ fontWeight: 700 }}>From
                            </label>
                            <label className="red">*</label>
                            &nbsp; &nbsp;
                            <Tooltip placement="rightTop" title={fromText}>
                                <span className="fa fa-info-circle wop-gray-low size14"></span>
                            </Tooltip>
                            <SelectSender setStateByProps={this.setStateByProps} select={sender} />
                        </Col>
                    </Row>

                    <Row gutter={24} style={marginbottomrow}>
                        <Col xs={24} sm={24} md={24} lg={24} xl={1}>
                            &nbsp;
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={24} xl={16}>
                            <label className=" control-label require" style={{ fontWeight: 700 }}>Content</label>
                            <label className="red">*</label>
                            <div style={{ float: "right", }}>
                                <label style={{ color: '#bfbfbf' }}> {this.state.MessageCount} {this.state.MessageCount > 1 ? "Credits" : "Credit"}, {this.state.contentlength}/{this.state.CharacterCount} characters</label>
                            </div>
                            <MultipleTrigger
                                data={this.state.variable} contents={this.state.content}
                                onmouseup={this.indexcontent}
                                useonchange={true}
                                onchange={(contents) => {
                                    this.setState({
                                        content: contents,
                                        content_old: contents,
                                        indexcontent: this.state.indexcontent + 1
                                    }), this.countMessage(contents)
                                }} />
                            {Linkbacklist == "True" ?
                                <Checkbox checked={true} disabled={true} style={{ color: '#bfbfbf', marginTop: 10, marginBottom: 10 }}>Receivers will be able to unsubscribe messages from this account </Checkbox> : null
                            }
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={24} xl={4} xxl={3}>
                            <Row gutter={24} style={marginbottomrow}>
                                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                    <label className=" control-label require" style={{ fontWeight: 700 }}> &nbsp; &nbsp; &nbsp; </label>
                                </Col>
                                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                    <Checkbox name="cb_shortedurl" checked={cb_shortedurl} onChange={this.onChangeCheckbox} style={{ fontWeight: 700 }}>Shorten URL</Checkbox>
                                </Col>
                            </Row>
                        </Col>
                    </Row>

                    <Row gutter={16}>
                        <Col xs={24} sm={24} md={24} lg={24} xl={1}>
                            &nbsp;
                        </Col>
                        <Col xs={20} sm={20} md={20} lg={20} xl={16}>
                            <Row gutter={16}>
                                <Col xs={24} sm={24} md={24} lg={24} xl={8} >
                                    <label className=" control-label require" style={{ fontWeight: 700 }}>Schedule <span>(Optional)</span></label>
                                </Col>
                                <Col xs={24} sm={24} md={24} lg={24} xl={8} >
                                    <DatePicker placeholder="Select Date" disabledDate={this.disabledDate} format={dateFormat} value={schedule_commu_date ? schedule_commu_date : null} onChange={(e) => { this.setStateByProps("schedule_commu_date", e), this.setStateByProps("schedule_commu_time", null) }} />
                                </Col>
                                <Col xs={24} sm={24} md={24} lg={24} xl={8}  >
                                    <TimePicker disabledHours={this.getDisabledHours} disabledMinutes={this.getDisabledMinutes}
                                        format={timeFormat} value={schedule_commu_time ? schedule_commu_time : null}
                                        onChange={(e) => this.setStateByProps("schedule_commu_time", e)}
                                        disabled={this.state.schedule_commu_date ? false : true}
                                        placeholder="Select Time"
                                    />
                                </Col>
                                <Col xs={24} sm={24} md={24} lg={24} xl={8} >

                                </Col>
                            </Row>
                        </Col>
                        <Col xs={4} sm={4} md={4} lg={4} xl={7}>
                            <label className=" control-label require" style={{ fontWeight: 700 }}> Timezone GMT+7</label>
                        </Col>
                    </Row>

                    <Row gutter={16} style={marginbottomrow}>
                        <Col xs={24} sm={24} md={24} lg={24} xl={1}>
                            &nbsp;
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={24} xl={16}>
                            <Row gutter={16}>
                                <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: "right" }}>
                                    <ButtonGroupBroadcast analyze_visible={analyze_visible}
                                        btnsend_loading={btnsend_loading}
                                        mode={"bc"}
                                        analyze_showDrawer={this.analyze_showDrawer}
                                        analyze_onClose={this.analyze_onClose}
                                        analyze_onSend={this.analyze_onSend}
                                        onClickPreview={this.onClickPreview}
                                        preview={preview}
                                        onDraft={this.onDraft}
                                        onDiscard={this.onDiscard}
                                        sender={sender}
                                        content={content_old}
                                        schedule_commu_date={schedule_commu_date}
                                        schedule_commu_time={schedule_commu_time}
                                        total_credit={total_credit}
                                        sum_credit={sum_credit}
                                        total_recipients={total_recipients}
                                        SUM_RECIPTENTS={SUM_RECIPTENTS}
                                        analyzeTable={analyzeTable}
                                        disabled={broadcastName && to.length > 0 && sender && content.trim() && Template && errorSmsto == "" ? false : true}
                                        disabledPreview={broadcastName && to.length > 0 && sender && content.trim() && Template ? false : true}
                                        errorMessage={errorMessage}
                                        ref={this.child}
                                    />
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Spin>
            </div>
        );
    }
}

